import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

export interface MoreData {
  eventType?: ActionLoggerEventType;

  [key: string]: any;
}

export enum ActionLoggerFromType {
  imageTool = 'image-tool'
}

export enum ActionLoggerEventType {
  mouse = 'mouse',
  keyboard = 'keyboard',
}

// TODO
// 사용자 행위만
// 사용자 액션으로 변경된 내용만
// 아이템 구분시 구분 가능하도록(id)
// 동일한액션타입 이벤트 타입구분(eventType)
// 동일한액션타입 출처구분(from)
class ActionLogger {
  private subjectSource = new Subject<any>();
  private subjectDebounceSource = new Subject<any>();
  private logs: any[] = [];

  constructor() {
    this.logging$.pipe(
      tap(v => this.logs.push(v)),
      tap(v => console.log(v.type, JSON.stringify(v, null, 2)))
    ).subscribe();
  }

  public get logging$(): Observable<any> {
    return merge(this.subjectSource.asObservable(), this.subjectDebounceSource.asObservable().pipe(debounceTime(100)));
  }

  private get defaultLog(): { eventTime: number; eventType: ActionLoggerEventType } {
    return { eventTime: Date.now(), eventType: ActionLoggerEventType.mouse };
  }

  public log(type: string, moreData: MoreData = {}): void {
    this.subjectSource.next(Object.assign(this.defaultLog, { type, ...moreData }));
  }

  public logDebounce(type: string, moreData: MoreData = {}): void {
    this.subjectDebounceSource.next(Object.assign(this.defaultLog, { type, ...moreData }));
  }

  public getLogs(): any[] {
    return [...this.logs];
  }

  public resetLogs(): void {
    this.logs = [];
  }
}

export const actionLogger = new ActionLogger();
