import { DataType } from '@aimmo/annotator-model';
import { invert } from 'lodash-es';
import { ItemType } from './image-tool-enum';
import { LoadingState } from './image-tool-model';

export const DEFAULT_LOADING_STATE: LoadingState = {
  isLoadingImage: false,
  isLoadingInstance: false
};
export const FIRST_POINT_COLOR = '#a8dc56';
export const BBOX_GRID_DEFAULT_COLOR = '#ffffff';
export const INVISIBLE_POINT_COLOR = '#ff00f7';
export const DEFAULT_IMAGE_POINT_SIZE = 4;
export const DEFAULT_POINT_LABEL_DISPLAYED = true;

export enum BBOX_GRID_DIVIDE_COUNT {
  divide4 = 4,
  divide8 = 8,
  divide16 = 16
}

export const BBOX_GRID_DEFAULT_DIVIDE_COUNT = BBOX_GRID_DIVIDE_COUNT.divide4;
export const BBOX_GRID_DEFAULT_WIDTH = 2;
export const LABEL_ATTRIBUTE_KEY = 'label';
export const SUPPORT_COPIES = Object.freeze([
  ItemType.Keypoint,
  ItemType.KeypointOD,
  ItemType.Bbox,
  ItemType.Polygon,
  ItemType.Polyline,
  ItemType.SemanticPolygon,
  ItemType.OrientedBoxV2,
  ItemType.Cuboid,
  ItemType.flatCuboid
]);
export const DISTANCE_FROM_CIRCLE = 7;
export const DARKEN_COLOR_AMOUNT = -20;

export const SUPPORT_CROPS = Object.freeze([
  ItemType.Polygon,
  ItemType.Bbox,
  ItemType.SemanticPolygon,
  ItemType.Polyline,
  ItemType.PolylinePoint,
  ItemType.Keypoint,
  ItemType.OrientedBoxV2,
  ItemType.Cuboid,
  ItemType.flatCuboid
]);

export const DataTypeToItemTypeMap = {
  [DataType.Polygon]: ItemType.Polygon,
  [DataType.Polygon3d]: ItemType.Polygon3d,
  [DataType.SemanticPolygon]: ItemType.SemanticPolygon,
  [DataType.Polyline]: ItemType.Polyline,
  [DataType.Bbox]: ItemType.Bbox,
  [DataType.Keypoint]: ItemType.Keypoint,
  [DataType.KeypointOD]: ItemType.KeypointOD,
  [DataType.ObboxV2]: ItemType.OrientedBoxV2,
  [DataType.Pair]: ItemType.Pair,
  [DataType.Cuboid]: ItemType.Cuboid,
  [DataType.FlatCuboid]: ItemType.flatCuboid,
  [DataType.PolylinePoint]: ItemType.PolylinePoint,
};

export const ItemTypeToDataType = {
  ...invert(DataTypeToItemTypeMap),
  [ItemType.InspectBbox]: DataType.Bbox
};

export const LABEL_DEFAULT_COLOR = '#ffffff';

export const LINE_STROKE_OPACITY = 0.7;
