import { Inject, InjectionToken, Optional } from '@angular/core';
import { EMPTY, merge, Observable, Subject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

interface CommandData {
  id?: string;

  [key: string]: any;
}

interface CommandResult {
  type?: string;
  data?: CommandData;
}

interface ICommand<T, R, R2> extends CommandResult {
  undo: UndoRedoExecute<T, R>;
  redo: UndoRedoExecute<T, R2>;
}

type UndoRedoContext<T> = Map<string, T>;

type UndoRedoExecute<T, R> = (context: UndoRedoContext<T>) => Observable<R>;

export const UNDO_MANAGER_LIMIT = new InjectionToken<number>('undoManagerLimit');
const DEFAULT_LIMIT = 0;

export class UndoManagerObservable<T> {
  private readonly limit: number;
  private readonly context: UndoRedoContext<T> = new Map<string, T>();
  private readonly undoSource = new Subject<void>();
  private readonly redoSource = new Subject<void>();
  private commands: ICommand<T, any, any>[] = [];
  private index = -1;

  public constructor(@Inject(UNDO_MANAGER_LIMIT) @Optional() limit?: number) {
    this.limit = limit ?? DEFAULT_LIMIT;
  }

  public get undoRedoAction$(): Observable<CommandResult> {
    return merge(this.undoAction$, this.redoAction$);
  }

  private get undoAction$(): Observable<CommandResult> {
    return this.undoSource.pipe(
      concatMap(() => {
        if (this.index >= 0) {
          const command = this.commands[this.index];
          const { type, data } = command;
          return command.undo(this.context).pipe(
            tap(() => this.index--),
            map(() => ({ type, data }))
          );
        }
        return EMPTY;
      })
    );
  }

  private get redoAction$(): Observable<CommandResult> {
    return this.redoSource.pipe(
      concatMap(() => {
        if (this.index < this.commands.length - 1) {
          this.index++;
          const command = this.commands[this.index];
          const { type, data } = command;
          return command.redo(this.context).pipe(map(() => ({ type, data })));
        }
        return EMPTY;
      })
    );
  }

  public add<R = any, R2 = any>(command: ICommand<T, R, R2>): UndoManagerObservable<T> {
    this.commands = this.commands.slice(0, this.index + 1);
    this.commands.push(command);
    if (this.limit > 0 && this.commands.length > this.limit) {
      this.commands.shift();
    } else {
      this.index++;
    }
    return this;
  }

  public redo(): void {
    this.redoSource.next();
  }

  public undo(): void {
    this.undoSource.next();
  }

  public hasUndo(): boolean {
    return this.index >= 0;
  }

  public hasRedo(): boolean {
    return this.index < this.commands.length - 1;
  }

  public reset(resetCommandAndContext = true): void {
    this.index = -1;
    if (resetCommandAndContext) {
      this.commands = [];
      this.context.clear();
    }
  }

  public contextSet(id: string, value: any): void {
    this.context.set(id, value);
  }
}
