import { JoinStatus } from '@admin/@core/services/api/users-api/users-api.model';
import { PaginationParams } from 'app/shared/models/pagination.model';
import { MemberType } from 'app/shared/models/user.model';
import { isEqual } from 'lodash-es';

export interface LogoutParam {
  externalAccessToken: string;
}

export interface MemberMe {
  role: MemberRole;
  isMainOwner?: boolean;
}

export enum MemberRole {
  owner = 'owner',
  admin = 'admin',
  manager = 'manager',
  member = 'member'
}

export interface MemberTag {
  color: string;
  name: string;
}

export const MemberPlanType = {
  free_trial: 'free_trial',
  monthly: 'monthly',
  annual: 'annual',
  enterprise: 'enterprise',
  unset: 'unset',
} as const;

export type MemberPlanTypeType = keyof typeof MemberPlanType;

export interface Member {
  avatarThumbnailUrl?: string;
  avatarUrl?: string;
  email: string;
  id?: string;
  joinedAt?: Date;
  joinStatus: JoinStatus;
  name?: string;
  role: MemberRole;
  signedUpAt?: Date;
  tags?: MemberTag[];
  participatedProjectCount?: number;
  isMainOwner?: boolean;
  memberType: MemberType;
  unregisteredAt?: Date;
  phoneNumber?: string;
  isRegisteredAccount?: boolean;
  isRegisteredSsn?: boolean;
  planType?: MemberPlanTypeType;
  is2faEnabled: boolean;
}

export type UpdateMembersPlanType = Pick<Member, 'id' | 'name' | 'planType'>;

export interface ParticipatedProject {
  id?: string;
  name?: string;
  participatedAt?: Date;
}

export enum MemberSort {
  nameAsc = 'name_asc',
  nameDesc = 'name_desc',
  joinedAtAsc = 'joined_at_asc',
  joinedAtDesc = 'joined_at_desc',
  none = 'none'
}

export interface MemberPaginationParams extends PaginationParams {
  nameOrEmail?: string;
  tagNames?: string[];
  membershipRoles?: MemberRole[];
  planTypes?: MemberPlanTypeType[];
  orderBy?: MemberSort;
  memberTypes?: MemberType[];
}

export interface CanParticipateMemberPaginationParams extends PaginationParams {
  projectId: string;
  partOfNameOrEmail?: string;
  tagNames?: string[];
}

const MEMBER_SORTS = [MemberSort.nameAsc, MemberSort.nameDesc, MemberSort.joinedAtAsc, MemberSort.joinedAtDesc];

export function getMemberSort(sort?: string | null): MemberSort | undefined {
  return MEMBER_SORTS.find(memberSort => isEqual(sort, memberSort));
}

const ORGANIZATION_MANAGEABLE_ROLES = [MemberRole.owner, MemberRole.admin];
const PROJECT_MANAGEABLE_ROLES = [MemberRole.owner, MemberRole.admin, MemberRole.manager];
const PROJECT_SETTINGS_MANAGEABLE_ROLES = [MemberRole.owner, MemberRole.admin];

export function isCanManageOrganization(role: MemberRole | undefined): boolean {
  return !!role && ORGANIZATION_MANAGEABLE_ROLES.includes(role);
}

export function isCanManageProject(role: MemberRole | undefined): boolean {
  if (isProjectManagerRole(role)) {
    return true;
  }

  return !!role && PROJECT_MANAGEABLE_ROLES.includes(role);
}

export function isCanManageProjectSettings(role: MemberRole): boolean {
  return PROJECT_SETTINGS_MANAGEABLE_ROLES.includes(role);
}

export function isProjectManagerRole(role: MemberRole | undefined): boolean {
  return [MemberRole.owner, MemberRole.manager].includes(role);
}

export const MEMBER_ROLES = [MemberRole.member, MemberRole.manager, MemberRole.admin, MemberRole.owner] as const;
export const SETTABLE_MEMBER_ROLES = [MemberRole.member, MemberRole.manager, MemberRole.owner] as const;

export function getMemberRole(role?: string): MemberRole | undefined {
  return MEMBER_ROLES.find(memberRole => isEqual(role, memberRole));
}
