import type { PointCloudPosition } from '@bluewhale/ngx-annotator/model';
import { RvDataType } from '@bluewhale/ngx-annotator/model';
import type { ReferenceViewers, ResourceViewTypes } from '@bluewhale/ngx-annotator/tool/point-cloud/model';
import { ResourceViewType } from '@bluewhale/ngx-annotator/tool/point-cloud/model';

export const MIN_SIZE = 0.01;
export const UNIT = 0.02;
export const MIN_ZOOM = 5;
export const MAX_ZOOM = 200;
export const BASE_ZOOM = 50;
export const QUARTER_VIEW_POSITION: PointCloudPosition = [50, 50, 50];
export const TOP_VIEW_POSITION: PointCloudPosition = [0, 50, 0];
export const LEFT_SIDE_VIEW_POSITION: PointCloudPosition = [-50, 0, 0];
export const DEFAULT_POSITION: PointCloudPosition = [0, 0, 0];
export const LABEL_DEFAULT_COLOR_WHITE = '#ffffff';
export const LABEL_DEFAULT_COLOR_BLACK = '#000000';
export const CSS_2D_OBJECT_NAME = 'css-2d-object';


export const DEFAULT_RESOURCE_VIEW_TYPES: ResourceViewTypes = {
  [RvDataType.image]: ResourceViewType?.defaultView,
  [RvDataType.radar]: ResourceViewType?.defaultView,
  [RvDataType.lidar]: ResourceViewType?.defaultView
};
export const DEFAULT_REFERENCE_VIEWERS: ReferenceViewers = {
  [RvDataType.image]: undefined,
  [RvDataType.radar]: undefined,
  [RvDataType.lidar]: undefined
};
export const POINT_CLOUD_SUPPORT_RV_TYPES: RvDataType[] = [
  RvDataType.image,
  RvDataType.radar
];

// 변경시 사용되는 곳들 간에 확인 필요. 추후 개선 필요:https://app.asana.com/0/0/1206675594684367/f
export const getRenderOrder = () => ([0, 1, 2, 3, 4, 5]);

