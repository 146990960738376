import { AnnotationConfig, DatasetType, ProjectDetailData } from '@aimmo/eimmo/models';
import type { ResourceViewType, TaskPoint, ThreeBboxData } from '@bluewhale/ngx-annotator/tool/point-cloud/model';
import { ToolPreference } from 'app/pages/project/settings/tool-preference/tool-preference.model';
import { TRACK_ID_COLORS } from './constants';
import { AnnotatedBaseItem, DisplayLabelOption, Point3dAnnotation, PointCloudMappingImage } from './interfaces';

export enum RvType {
  front = 'front',
  rear = 'rear',
  right = 'right',
  left = 'left',
  frontRight = 'front_right',
  frontLeft = 'front_left',
  rearRight = 'rear_right',
  rearLeft = 'rear_left',
  leftFront = 'left_front',
  leftRear = 'left_rear',
  rightFront = 'right_front',
  rightRear = 'right_rear'
}

export enum RvDataType {
  image = 'image',
  radar = 'radar',
  lidar = 'lidar'
}

export enum RvStructureType {
  dir = 'dir',
  file = 'file'
}

export interface ThreeBboxParam {
  id?: string;
  groupId?: string;
  trackId?: string;
  geometry?: PointCloudGeometry;
  position?: PointCloudPosition;
  rotation?: PointCloudRotation;
  bound?: PointCloudConvexHullBound;
  unit?: number;
  degreeRange?: number;
  color?: string;
  selected?: boolean;
  visible?: boolean;
  displayLabel?: string;
  displayLabelOptions?: DisplayLabelOption[];
  fontSize?: number;
  distanceMode?: boolean;
  resizable?: boolean;
  movable?: boolean;
  isVisualized?: boolean;
}

export enum MiniViewSize {
  default,
  minimization,
  newWindow
}

export enum ReferenceViewerSize {
  largeSize,
  smallSize
}

export interface ReferenceViewerSizes {
  [RvDataType.image]?: ReferenceViewerSize;
  [RvDataType.radar]?: ReferenceViewerSize;
  [RvDataType.lidar]?: ReferenceViewerSize;
}

export const DEFAULT_RV_SIZES: ReferenceViewerSizes = {
  [RvDataType.image]: ReferenceViewerSize.smallSize,
  [RvDataType.radar]: ReferenceViewerSize.smallSize,
  [RvDataType.lidar]: ReferenceViewerSize.smallSize
};

export interface FetchReferenceGtParam {
  frameNumber: number;
  rvType: RvType | undefined;
}

export interface FetchAllReferenceGtParam {
  frameNumber: number;
  rvTypes: RvType[] | undefined;
}

export interface CuboidMinSpec {
  height: number;
  width: number;
  depth: number;
}

export interface CuboidSameSizeSpec {
  heightDiff: number;
  widthDiff: number;
  depthDiff: number;
}

export interface CuboidValidate {
  cuboidMinSpecs: CuboidMinSpec[];
  cuboidSameSizesSpecs: CuboidSameSizeSpec[];
  minPointCount: number;
}

export interface TempValidateInfo {
  [key: string]: CuboidValidate;
}

export interface ManualBoundary {
  minY: number;
  maxY: number;
}

export type PointCloudRadian = number; // -PI < x < PI
export type PointCloudFloat = number; // float range
export type PointCloudGeometryFloat = number; // x <= 0
export type PointCloudGeometry = [depth: PointCloudGeometryFloat, width: PointCloudGeometryFloat, height: PointCloudGeometryFloat];
export type PointCloudPosition = [x: PointCloudFloat, y: PointCloudFloat, z: PointCloudFloat];
export type PointCloudRotation = [rx: PointCloudRadian, ry: PointCloudRadian, rz: PointCloudRadian];
export type PointCloudConvexHullBound = {
  minX: PointCloudFloat,
  maxX: PointCloudFloat,
  minY: PointCloudFloat,
  maxY: PointCloudFloat,
  minZ: PointCloudFloat,
  maxZ: PointCloudFloat
};

export interface PointCloudItem extends AnnotatedBaseItem {
  geometry: PointCloudGeometry;
  position: PointCloudPosition;
  rotation: PointCloudRotation;

  [key: string]: any;
}

export interface PointCloudLinePointItem extends AnnotatedBaseItem {
  point3dAnnotations: Point3dAnnotation[];
}

export interface PointCloudFrameInfo {
  pointUrl: string;
  frame: number;
  frameIndex: number;
  mappingImages?: PointCloudMappingImage[];
  items?: ThreeBboxData[];
}

export interface PointCloudFrame {
  point: TaskPoint;
  mappingImages: PointCloudMappingImage[];
}

export const DEFAULT_DEGREE_RANGE = 25;
export const MIDDLE_DEGREE_RANGE = 40;
export const MAX_DEGREE_RANGE = 180;

export const POINT_CLOUD_DEFAULT_ZOOM = 30;

export interface StartEndRange {
  start: number;
  end: number;
}

export const MAX_INTENSITY_HUE = 360;
export const FULL_HUE_RANGE = 361;
export const DEFAULT_INTENSITY_HUE_RANGE = {
  start: 0,
  end: MAX_INTENSITY_HUE
};
export const DEFAULT_INTENSITY_RANGE = {
  start: 0,
  end: 1
};
export const DEFAULT_POINT_Z_RANGE = {
  start: 0,
  end: 1
};
export const DEFAULT_VELOCITY_RANGE = {
  start: -100,
  end: 100
};
export const DEFAULT_CHANNEL_COLORS = TRACK_ID_COLORS;
export const DEFAULT_CHANNEL_VISIBILITIES = [true, true, true, true, true, true, true];

export const POINTER_EXPOSURE_TIME = 1000;

export interface RVSettingsParam {
  areaOpacity: number;
  brightness: number;
  visibleClassName: boolean | null;
  colorByClass: boolean | null;
  cuboidUpperPlaneOnlyMode: boolean | null;
}

export type RvSettingsParamByRvType = Partial<Record<RvDataType, RVSettingsParam>>;

export interface SyncData {
  rvType: RvDataType;
  rvSettings: RVSettingsParam;
  index: number;
  viewType?: ResourceViewType;
}

export const DEFAULT_RV_SETTINGS: RVSettingsParam = {
  areaOpacity: 50,
  brightness: 0,
  visibleClassName: null,
  colorByClass: true,
  cuboidUpperPlaneOnlyMode: null
};

export const DEFAULT_RV_SETTINGS_BY_RV_TYPE: RvSettingsParamByRvType = {
  [RvDataType.image]: DEFAULT_RV_SETTINGS,
  [RvDataType.radar]: DEFAULT_RV_SETTINGS,
  [RvDataType.lidar]: DEFAULT_RV_SETTINGS
};

export const MIN_RV_OPACITY_RANGE = 0;
export const MAX_RV_OPACITY_RANGE = 100;

export const MIN_RV_BRIGHTNESS_RANGE = -100;
export const MAX_RV_BRIGHTNESS_RANGE = 100;

export enum PointCloudChannel {
  xyzi = 'xyzi',
  xyzv = 'xyzv',
  xyziv = 'xyziv',
  xyzivc = 'xyzivc',
}

export interface IntensityColorParams {
  applyColorsToIntensityRange: boolean; // min, max 로 필터링된 인스턴스에 전체 색상 적용할지 여부
  hueStart: number; // 필터링할 색상 범위 시작값 (0~360)
  hueEnd: number; // 필터링할 색상 범위 끝값 (0~360)
  min: number; // 필터링할 인텐시티 최소값
  max: number; // 필터링할 인텐시티 최대값
}

export interface VelocityColorParams {
  velocityStart: number; // velocity 최소값
  velocityEnd: number; // velocity 최대값
}

export interface ChannelColorParams {
  colors: string[]; // 채널 index 순으로 색상 지정,
  visibility: boolean[]; // 채널 index 순으로 visibility 토글
}

export interface PointZFilterParams {
  min: number; // 필터링할 z 상대적 최소값 0~ 1
  max: number; // 필터링할 z 상대적 최소값 0~ 1
}

export enum CameraRvTypeForFrameless {
  camBack = 'camBack',
  camBackLeft = 'camBackLeft',
  camBackRight = 'camBackRight',
  camFront = 'camFront',
  camFrontLeft = 'camFrontLeft',
  camFrontRight = 'camFrontRight'
}

export enum RadarRvTypeForFrameless {
  radarBackLeft = 'radarBackLeft',
  radarBackRight = 'radarBackRight',
  radarFront = 'radarFront',
  radarFrontLeft = 'radarFrontLeft',
  radarFrontRight = 'radarFrontRight'
}

export interface FramelessViewerData<T = string | number[] | boolean[]> {
  [CameraRvTypeForFrameless.camBack]?: T;
  [CameraRvTypeForFrameless.camBackLeft]?: T;
  [CameraRvTypeForFrameless.camBackRight]?: T;
  [CameraRvTypeForFrameless.camFront]?: T;
  [CameraRvTypeForFrameless.camFrontLeft]?: T;
  [CameraRvTypeForFrameless.camFrontRight]?: T;
  [RadarRvTypeForFrameless.radarBackLeft]?: T;
  [RadarRvTypeForFrameless.radarBackRight]?: T;
  [RadarRvTypeForFrameless.radarFrontRight]?: T;
  [RadarRvTypeForFrameless.radarFront]?: T;
  [RadarRvTypeForFrameless.radarFrontLeft]?: T;
}

export interface FramelessInfo {
  frames: boolean[];
  key?: CameraRvTypeForFrameless | RadarRvTypeForFrameless;
  rvDataType?: RvDataType;
}

export type ProjectToolPreferencesByRvType = Partial<Record<RvDataType, ToolPreference>>;
export type ProjectAnnotationConfigsByRvType = Partial<Record<RvDataType, AnnotationConfig[]>>;

export interface SyncRvProjectDetail {
  rvType: RvDataType;
  detailData: ProjectDetailData;
  toolPreference: ToolPreference;
}

export interface AllSyncRvProjectSettings {
  projectTypes: DatasetType[];
  toolPreferencesByRvType: ProjectToolPreferencesByRvType;
  annotationConfigsByRvType: ProjectAnnotationConfigsByRvType;
}
