import type { BrushMode } from '@bluewhale/ngx-annotator/tool/brush/model';
import { difference, isEmpty } from 'lodash-es';

export enum TextBoxDirection {
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export enum DirectionType {
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export enum AreaSide {
  leftOrTop = 'left',
  rightOrBottom = 'right',
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
}

export type Rect = [number, number, number, number];

// ModeType은 사용될 item을 구분하기 위한 용도. inspect(review, view) 와 MergeView는 사용하는 item과 무관한 툴의 상태 (condition)
// TODO value camel case 혹은 - case 통일하기
export enum ModeType {
  cuboid = 'cuboid',
  cuboid3d = 'cuboid3d',
  flatCuboid = 'cuboid-plain',
  obboxV2 = 'oriented-box-v2',
  bbox = 'bbox',
  polygon = 'polygon',
  polygon3d = 'polygon-3d',
  paint = 'paint',
  bucket = 'bucket',
  erase = 'erase',
  polyline = 'polyline',
  polylinePoint = 'polyline-point',
  polyline3d = 'polyline3d',
  group = 'group',
  extremeBbox = 'extreme-bbox',
  cornerBbox = 'corner-bbox',
  bboxEllipse = 'bbox-ellipse',
  pan = 'pan',
  pointAdd = 'point-add',
  polygonAdd = 'polygon-add',
  pointRemove = 'point-remove',
  polygonRemove = 'polygon-remove',
  splitSeg = 'split-seg',
  lineCut = 'line-cut',
  oneClickLineCut = 'one-click-line-cut',
  keypoint = 'landmark',
  hdOdKeypoint = 'landmark_hd_od',
  semanticPolygon = 'semantic-polygon',
  lasso = 'lasso',
  lassoAdd = 'lasso-add',
  lassoRemove = 'lasso-remove',
  point = 'point',
  select = 'select',
  lassoSelect = 'lassoSelect',
  duplicate = 'duplicate',
  distance = 'distance',
  RITM = 'RITM', // (Reviving Iterative Training with Mask Guidance)
  RITM_N = 'RITMNegative',
  comment = 'comment',
  areaSizeCheck = 'area-size-check',
}

export enum ScaleCondition {
  WhenBig = 'when-big',
  Default = 'default'
}

// essential
// ======================================
export enum SVGClass {
  imageAnnotator = 'image-annotator',
  panMode = 'pan-mode',
  pointer = 'pointer',
  move = 'move',
  selectMode = 'select-mode',
  rotate = 'rotate',
  resize = 'resize',
  grabbing = 'grabbing',
  crossCursor = 'cross-cursor',
  pointAdd = 'point-add',
  pointRemove = 'point-remove',
  item = 'item',
  firstPoint = 'first-point',
  resizer = 'resizer',
  rotator = 'rotator',
  cornerRotator = 'corner-rotator',
  resizerBuffer = 'resizer-buffer',
  rotatorBuffer = 'rotator-buffer',
  cornerRotatorBuffer = 'corner-rotator-buffer',
  duplicate = 'duplicate',
  bboxVertical = 'bbox-vertical',
  bboxVerticalExtra = 'bbox-vertical-extra',
  bboxHorizontal = 'bbox-horizontal',
  bboxHorizontalExtra = 'bbox-horizontal-extra',
  bboxInsidePoint = 'bbox-inside-point',
  bboxInsideMiddlePoint = 'bbox-inside-middle-point',
  centerLineText = 'center-line-text',
  disabledMouse = 'disabled-mouse',
  lassoCreate = 'lasso-create',
  lassoAdd = 'lasso-add',
  lassoRemove = 'lasso-remove',
  comment = 'comment'
}

export enum CanvasNameAttr {
  imageAnnotator = 'image-annotator',
  panMode = 'pan-mode',
  pointer = 'pointer',
  move = 'move',
  selectMode = 'select-mode',
  rotate = 'rotate',
  resize = 'resize',
  grabbing = 'grabbing',
  crossCursor = 'cross-cursor',
  pointAdd = 'point-add',
  polylineBoundary = 'polyline-boundary',
  basicPolyline = 'basic-polyline',
  dottedPolyline = 'dotted-polyline',
  pointRemove = 'point-remove',
  item = 'item',
  firstPoint = 'first-point',
  resizer = 'resizer',
  rotator = 'rotator',
  keypointBoundary = 'keypoint-boundary',
  cornerRotator = 'corner-rotator',
  resizerBuffer = 'resizer-buffer',
  rotatorBuffer = 'rotator-buffer',
  cornerRotatorBuffer = 'corner-rotator-buffer',
  duplicate = 'duplicate',
  bboxVertical = 'bbox-vertical',
  bboxVerticalExtra = 'bbox-vertical-extra',
  bboxHorizontal = 'bbox-horizontal',
  bboxHorizontalExtra = 'bbox-horizontal-extra',
  bboxInsidePoint = 'bbox-inside-point',
  bboxInsideMiddlePoint = 'bbox-inside-middle-point',
  centerLineText = 'center-line-text',
  disabledMouse = 'disabled-mouse',
  lassoCreate = 'lasso-create',
  lassoAdd = 'lasso-add',
  lassoRemove = 'lasso-remove',
  comment = 'comment'
}

export enum AnnotatorItemClass {
  item = 'item',
  optionSelectable = 'option-selectable',
  selectable = 'selectable',
  background = 'background-item',
  referenceGT = 'reference-gt',
  groundTruth = 'ground-truth-item',
  hint = 'hint-item',
  inspectItem = 'inspect-item',
  globalCircle = 'global-circle',
  locked = 'locked',
  buffer = 'buffer',
  gridPolyLine = 'grid-poly-line',
  RITMTemporalItem = 'RITM-temporal-item',
  comment = 'comment',
  commentPath = 'comment-path',
  commentText = 'comment-text',
  visualized = 'visualized'
}

export enum ImageAnnotatorEventType {
  loadImage = 'loadImage',
  errorImage = 'errorImage',
  errorLightingImprovedImage = 'errorLightingImprovedImage',
  addItem = 'addItem',
  addDotBaseItem = 'addDotBaseItem',
  cancelCreateItem = 'cancelCreateItem',
  removeItem = 'removeItem',
  selectItem = 'selectItem',
  unselectItem = 'unselectItem',
  loadMiniMapImage = 'loadMiniMapImage',
  checkValidation = 'checkValidation',
  updateValidation = 'updateValidation',
  changeZoom = 'changeZoom',
  toggleMiniMap = 'toggleMiniMap',
  cutPolyline = 'cutPolyLine',
  changeCoordinate = 'changeCoordinate', // 특정 조건이 될때만 발생
  changeBrightness = 'changeBrightness',
  changeContrast = 'changeContrast',
  resize = 'resize',
  resizeVerticalDivider = 'resizeVerticalDivider',
  moveInsidePoint = 'moveInsidePoint',
  move = 'move',
  pointAdd = 'pointAdd',
  pointRemove = 'pointRemove',
  createCancel = 'createCancel',
  createPointAdd = 'createPointAdd',
  rotate = 'rotate',
  openKeypointSelector = 'openKeypointSelector',
  toggleInvisiblePoint = 'toggleInvisiblePoint',
  click = 'click',
  firstPointMove = 'firstPointMove',
  triggerChangeCenterText = 'triggerChangeCenterText',
  splitItem = 'splitItem',
  mergeItem = 'mergeItem',
  updateExtensionAttribute = 'updateExtensionAttribute', // 속성 자동 반영시 발생(flatCuboid: cuboidPlainFaceOption-faceExtensionKey)
  removeExternalApiMessages = 'removeExternalApiMessages', // 인스턴스 수정 시 API 유효성 메시지 삭제
  polygonAdd = 'polygonAdd',
  polygonRemove = 'polygonRemove',
  splitSeg = 'splitSeg',
  selectPoint = 'selectPoint',
  error = 'error',
  clickComment = 'clickComment',
  moveComment = 'moveComment'
}

export enum ItemType {
  Cuboid = 'cuboid',
  flatCuboid = 'cuboidPlain',
  Bbox = 'bbox',
  OrientedBoxV2 = 'orientedBoxV2',
  Polygon = 'polygon',
  Polygon3d = 'polygon_3d',
  SemanticPolygon = 'semanticPolygon',
  ExtremeBbox = 'extremeBbox',
  CornerBbox = 'cornerBbox',
  Paint = 'paint',
  Polyline = 'polyline',
  PolylinePoint = 'polylinePoint',
  SelectBbox = 'selectBbox',
  SelectSplitBbox = 'selectSplitBbox',
  SelectMergeBbox = 'selectMergeBbox',
  InspectBbox = 'inspectBbox',
  Keypoint = 'landmark',
  KeypointOD = 'landmark_hd_od',
  Pose = 'pose',
  Point = 'point',
  Pair = 'Pair',
  CheckBbox = 'CheckBbox',
}

export enum EventType {
  MouseDown = 'mousedown',
  MouseUp = 'mouseup',
  MouseMove = 'mousemove',
  MouseLeave = 'mouseleave',
  MouseEnter = 'mouseenter',
  ContextMenu = 'contextmenu',
  MouseDoubleClick = 'dblclick'
}

export enum ItemActionType {
  // TODO: 큐보이드 2D creatingInstance 적용 - https://app.asana.com/0/1200161583627220/1205350864611699/f
  createBoxAdd = 'create-box-add',
}

export enum ItemColors {
  Default = '#ffffff'
}

export enum CircleChangeType {
  Recreate = 'recreate',
  Add = 'add',
  Remove = 'remove',
  Update = 'update',
  RemoveAll = 'remove-all'
}

export enum CanvasDrawType {
  line = 'line',
  grayScale = 'grayScale',
  normal = 'normal'
}

export interface StateMode {
  mode: ModeType;
  byDefaultOrClick: boolean;
}

export type CursorMode = ModeType | BrushMode;

export enum KeypointODVersion {
  v1,
  v2
}

const LASSO_SELECT_SHORTCUT = ['n', 's'];

export function convertModeTypeFromKeyCode(e: KeyboardEvent | any): ModeType {
  const { code, shiftKey, altKey, pressedKeys } = e;
  if (isEmpty(difference(LASSO_SELECT_SHORTCUT, pressedKeys))) {
    return ModeType.lassoSelect;
  }
  switch (code) {
    case 'KeyN':
      if (altKey) {
        return ModeType.lassoAdd;
      } else if (shiftKey) {
        return ModeType.lassoRemove;
      }
      return ModeType.lasso;
    case 'KeyB':
      return ModeType.cuboid3d;
    case 'KeyP':
      return ModeType.polygon3d;
    case 'KeyC':
      return ModeType.comment;
    case 'KeyS':
    default:
      return ModeType.select;
  }
}

export function isRITMMode(mode: ModeType): boolean {
  return [ModeType.RITM, ModeType.RITM_N].includes(mode);
}

export function isPolygonAddRemoveMode(mode: ModeType): boolean {
  return [ModeType.polygonAdd, ModeType.polygonRemove].includes(mode);
}

export function isSplitSegMode(mode: ModeType): boolean {
  return mode === ModeType.splitSeg;
}

export const PolyBaseSubMode = [ModeType.pointAdd, ModeType.pointRemove];

export enum PolyBoolOp {
  union = 'union',
  intersect = 'intersect',
  difference = 'difference',
  differenceRev = 'differenceRev',
  xor = 'xor',
}
