import {
  BoundaryDirection,
  BoundCoordinates,
  BoundingRect,
  BoxDirection,
  CLASS_KEY,
  Coordinate,
  ImageSize
} from '@aimmo/annotator-model';
import { LazyPolygonClipping } from '@aimmo/lazy/svg-js';
import { AnnotationAttributeAnswer, SizeValue } from '@bluewhale/ngx-annotator/components/attributes/model';
import type {
  BboxCustomVerticalDivider,
  CuboidAnnotation,
  PointCloudGeometry,
  PointCloudPosition,
  PointCloudRotation,
  StudioAnnotation,
  ValidationOption
} from '@bluewhale/ngx-annotator/model';
import {
  AreaSide,
  CuboidInformation,
  DirectionType,
  FlatCuboidFaceOption,
  PolyBoolOp,
  Rect
} from '@bluewhale/ngx-annotator/tool/image/model';
import { isArrayEqual } from '@bluewhale/ngx-annotator/util';
import {
  getDistanceBetweenTwoPoint,
  RectXYWH,
  roundMultiPolygon,
  transformMultiPolygonToPolygonByConnection
} from '@bluewhale/ngx-annotator/util/image';
import { captureException } from '@sentry/angular-ivy';
import {
  cloneDeep,
  compact,
  first,
  flatten,
  flattenDepth,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isNumber,
  last,
  sortBy,
  sumBy,
  times,
  uniqBy
} from 'lodash-es';
import { getOverlapSize, isInside } from 'overlap-area';
import type { MultiPolygon } from 'polygon-clipping';
import type * as svgjs from 'svg.js';

// 그레이엄 스캔
// @see https://ko.wikipedia.org/wiki/%EA%B7%B8%EB%A0%88%EC%9D%B4%EC%97%84_%EC%8A%A4%EC%BA%94;
export function ccw(a: Coordinate, b: Coordinate, c: Coordinate): number {
  return (b[0] - a[0]) * (c[1] - a[1]) - (c[0] - a[0]) * (b[1] - a[1]);
}

export function getBoundaryBoxForCustomBbox(coordinates: Coordinate[], attributes: AnnotationAttributeAnswer, bboxCustomVerticalDivider: BboxCustomVerticalDivider, isShowAllBboxCustomVerticalDivider: boolean): RectXYWH {
  const { width, height, x, y } = getBoundaryBox(coordinates);
  const {
    activatedKey,
    supportClasses,
    positionKey,
    directionKey,
    sideKey,
    extraPositionKey,
    crossMode,
    verticalExtraPositionKey,
    horizontalExtraPositionKey
  } = bboxCustomVerticalDivider;

  const ignoreSupportClasses = isShowAllBboxCustomVerticalDivider || isEmpty(supportClasses);
  const isActivated = attributes[activatedKey] === 'true' && (ignoreSupportClasses ? true : supportClasses.includes(attributes[CLASS_KEY]));

  if (isActivated) {
    const isVerticalMode = (attributes[directionKey] ?? DirectionType.vertical) === DirectionType.vertical;
    const isLeftOrTopSide = attributes[sideKey] as AreaSide === AreaSide.leftOrTop;
    const verticalPosition = attributes[positionKey] as number;

    if (crossMode) {
      const areaSide = attributes[sideKey] as AreaSide;
      const horizontalPosition = attributes[extraPositionKey] as number ?? 0;
      const verticalExtraPosition = attributes[verticalExtraPositionKey] as number ?? 0;
      const horizontalExtraPosition = attributes[horizontalExtraPositionKey] as number ?? 0;
      const hasVerticalExtraPosition = !!verticalExtraPositionKey;
      const hasHorizontalExtraPosition = !!horizontalExtraPositionKey;
      return getBoundaryBox(getRboxCoordinatesForCrossMode({
        coordinates,
        verticalPosition,
        horizontalPosition,
        areaSide,
        verticalExtraPosition,
        horizontalExtraPosition,
        hasVerticalExtraPosition,
        hasHorizontalExtraPosition
      }));
    }
    if (isVerticalMode) {
      if (isLeftOrTopSide) {
        return { height, width: verticalPosition, x, y };
      } else {
        return { height, width: width - verticalPosition, x, y };
      }
    } else {
      if (isLeftOrTopSide) {
        return { height: verticalPosition, width, x, y };
      } else {
        return { height: height - verticalPosition, width, x, y };
      }
    }
  }
  return { height, width, x, y };
}

interface RboxCoordinatesForCrossModeParam {
  coordinates: Coordinate[];
  verticalPosition: number;
  horizontalPosition: number;
  areaSide: AreaSide;
  verticalExtraPosition?: number;
  horizontalExtraPosition?: number;
  hasVerticalExtraPosition?: boolean;
  hasHorizontalExtraPosition?: boolean;
}

export function getRboxCoordinatesForCrossMode(params: RboxCoordinatesForCrossModeParam): Coordinate[] {
  const {
    coordinates,
    verticalPosition,
    horizontalPosition,
    areaSide,
    verticalExtraPosition,
    horizontalExtraPosition,
    hasVerticalExtraPosition,
    hasHorizontalExtraPosition
  } = params;
  const firstX = coordinates[0][0];
  const firstY = coordinates[0][1];
  const secondX = coordinates[1][0];
  const secondY = coordinates[1][1];
  const thirdX = coordinates[2][0];
  const thirdY = coordinates[2][1];
  switch (areaSide) {
    case AreaSide.topRight:
      return [
        [firstX + verticalPosition, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : firstY],
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : secondX, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : firstY],
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : secondX, secondY + horizontalPosition],
        [firstX + verticalPosition, secondY + horizontalPosition],
      ];
    case AreaSide.bottomLeft:
      return [
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : firstX, firstY + horizontalPosition],
        [firstX + verticalPosition, firstY + horizontalPosition],
        [firstX + verticalPosition, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : thirdY],
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : firstX, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : thirdY],
      ];
    case AreaSide.bottomRight:
      return [
        [firstX + verticalPosition, firstY + horizontalPosition],
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : thirdX, firstY + horizontalPosition],
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : thirdX, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : thirdY],
        [firstX + verticalPosition, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : thirdY],
      ];
    case AreaSide.topLeft:
    default:
      return [
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : firstX, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : firstY],
        [firstX + verticalPosition, hasHorizontalExtraPosition ? firstY + horizontalExtraPosition : firstY],
        [firstX + verticalPosition, firstY + horizontalPosition],
        [hasVerticalExtraPosition ? firstX + verticalExtraPosition : firstX, firstY + horizontalPosition],
      ];
  }
}

export function getRboxCoordinates(coordinates: Coordinate[], position: number, isLeftOrTopSide: boolean, isVerticalMode: boolean): Coordinate[] {
  if (isVerticalMode) {
    return getRboxCoordinatesForVertical(coordinates, position, isLeftOrTopSide);
  } else {
    return getRboxCoordinatesForHorizontal(coordinates, position, isLeftOrTopSide);
  }
}

function getRboxCoordinatesForVertical(coordinates: Coordinate[], position: number, isLeftOrTopSide: boolean): Coordinate[] {
  return isLeftOrTopSide ?
    [
      coordinates[0],
      [coordinates[0][0] + position, coordinates[0][1]],
      [coordinates[3][0] + position, coordinates[3][1]],
      coordinates[3],
    ] :
    [
      [coordinates[0][0] + position, coordinates[0][1]],
      coordinates[1],
      coordinates[2],
      [coordinates[3][0] + position, coordinates[3][1]],
    ];
}

function getRboxCoordinatesForHorizontal(coordinates: Coordinate[], position: number, isLeftOrTopSide: boolean): Coordinate[] {
  return isLeftOrTopSide ?
    [
      coordinates[0],
      coordinates[1],
      [coordinates[2][0], coordinates[1][1] + position],
      [coordinates[3][0], coordinates[0][1] + position],
    ] :
    [
      [coordinates[0][0], coordinates[0][1] + position],
      [coordinates[1][0], coordinates[0][1] + position],
      coordinates[2],
      coordinates[3],
    ];
}

export function getLineCoordinates(coordinates: Coordinate[], position: number, isVerticalMode: boolean): Coordinate[] {
  if (isVerticalMode) {
    return getLineCoordinatesForVertical(coordinates, position);
  } else {
    return getLineCoordinatesForHorizontal(coordinates, position);
  }
}

export function getLineCoordinatesForVertical(coordinates: Coordinate[], position: number): Coordinate[] {
  const { x, y, height } = getBoundaryBox(coordinates);
  return [[x + position, y], [x + position, y + height]];
}

export function getLineCoordinatesForHorizontal(coordinates: Coordinate[], position: number): Coordinate[] {
  const { x, y, width } = getBoundaryBox(coordinates);
  return [[x, y + position], [x + width, y + position]];
}

export function getBoundaryBox(coordinates?: Coordinate[]): RectXYWH {
  const { xDiff, yDiff, minX, minY } = getXyInformation(coordinates);
  const x = minX === Infinity ? 0 : minX;
  const y = minY === Infinity ? 0 : minY;
  const width = xDiff === -Infinity ? 10 : xDiff;
  const height = yDiff === -Infinity ? 10 : yDiff;
  return {
    x,
    y,
    width,
    height
  };
}

export function convertRectToRectXYWH(rect: Rect): RectXYWH {
  const [x, y, width, height] = rect;
  return {
    x,
    y,
    width,
    height
  };
}

export function getBoundaryBoxWithBuffer(coordinates?: Coordinate[]): RectXYWH {
  let { x, y, width, height } = getBoundaryBox(coordinates);
  if (width === 0 && height === 0) {
    const buffer = 50;
    width += buffer;
    x -= buffer / 2;
    x = Math.max(0, x);
    height += buffer;
    y -= buffer / 2;
    y = Math.max(0, y);
  } else if (width === 0 || height / width > 5) {
    const bufferWidth = height * 0.2;
    width += bufferWidth;
    x -= bufferWidth / 2;
    x = Math.max(0, x);
  } else if (height === 0 || width / height > 5) {
    const bufferHeight = width * 0.2;
    height += bufferHeight;
    y -= bufferHeight / 2;
    y = Math.max(0, y);
  }
  return {
    x,
    y,
    width,
    height
  };
}

export function getBoundaryBoxFromBound(coordinates?: BoundCoordinates): RectXYWH {
  const [[top, left], [bottom, right]] = coordinates;
  const x = left;
  const y = top;
  const width = right - left;
  const height = bottom - top;
  return {
    x,
    y,
    width,
    height
  };
}

export function getBoundFromBoundaryBox(coordinates: RectXYWH): BoundCoordinates {
  if (isNil(coordinates)) {
    return [[0, 0], [0, 0]];
  }
  const { x, y, width, height } = coordinates;
  const top = y;
  const left = x;
  const bottom = top + height;
  const right = left + width;
  return [[top, left], [bottom, right]];
}

export function getXyInformation(coordinates?: Coordinate[]): {
  xDiff: number,
  yDiff: number,
  minX: number,
  minY: number,
  maxX: number,
  maxY: number
} {
  const allX = coordinates?.filter(coordinate => !isEmpty(coordinate))?.map(coordinate => coordinate[0]) ?? [];
  const allY = coordinates?.filter(coordinate => !isEmpty(coordinate))?.map(coordinate => coordinate[1]) ?? [];
  const [minX, maxX] = [Math.min(...allX), Math.max(...allX)];
  const [minY, maxY] = [Math.min(...allY), Math.max(...allY)];
  return {
    minX,
    maxX,
    minY,
    maxY,
    xDiff: maxX - minX,
    yDiff: maxY - minY
  };
}

export function rotatePoint(cx: number, cy: number, x: number, y: number, angle: number): Coordinate {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = (cos * (x - cx)) + (sin * (y - cy)) + cx;
  const ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
  return [nx, ny];
}


export function convertBoundingRectToTopLeftBottomRight({
                                                          top,
                                                          left,
                                                          bottom,
                                                          right
                                                        }: BoundingRect): BoundCoordinates {
  return [[top, left], [bottom, right]];
}

export function convertTopLeftBottomRightToBoundingRect(topLefBottomRight: BoundCoordinates): BoundingRect {
  const [[top, left], [bottom, right]] = topLefBottomRight;
  return { top, left, bottom, right };
}

export function convexHull(coordinates: Coordinate[]): Coordinate[] {
  coordinates = compact(coordinates);
  if (isNil(coordinates) || coordinates.length < 2) {
    return [];
  }
  const outlineIndexes = [];
  const coord = cloneDeep(coordinates);

  coord.sort((a, b) => {
    if (a[1] === b[1]) {
      return b[0] - a[0];
    } else {
      return b[1] - a[1];
    }
  });

  coord.sort((a, b) => {
    const result = ccw(coord[0], a, b);
    if (result > 0) {
      return -1;
    } else {
      return 1;
    }
  });
  outlineIndexes.push(0, 1);

  let next = 2;

  while (next < coord.length) {
    while (outlineIndexes.length >= 2) {
      const second = outlineIndexes.pop() as number;
      const firstIndex = last(outlineIndexes) as number;

      if (ccw(coord[firstIndex], coord[second], coord[next]) > 0) {
        outlineIndexes.push(second);
        break;
      }
    }
    outlineIndexes.push(next++);
  }

  return outlineIndexes.map(index => coord[index]);
}

export function getCoordinatesBoxFromCenterCoordinate([x, y]: Coordinate, boxWidth: number, boxHeight: number): Coordinate[] {
  boxWidth /= 2;
  boxHeight /= 2;
  return [
    [x - boxWidth, y - boxHeight],
    [x + boxWidth, y - boxHeight],
    [x + boxWidth, y + boxHeight],
    [x - boxWidth, y + boxHeight]
  ];
}

// @see https://stackoverflow.com/questions/16285134/calculating-polygon-area
export function getPolygonArea(coordinates: Coordinate[]): number {
  const total = coordinates.reduce((acc, [addX, subY], index) => {
    const nextCoordinate = coordinates[index + 1];
    const firstCoordinate = coordinates[0];
    const [subX, addY] = nextCoordinate ?? firstCoordinate;
    acc += addX * addY * 0.5;
    acc -= subX * subY * 0.5;
    return acc;
  }, 0);

  return Math.abs(total);
}

export function isValidCoordinate(coordinate: Coordinate): boolean {
  return isArray(coordinate) && coordinate.length === 2 && isNumber(coordinate[0]) && isNumber(coordinate[1]);
}

export function getBoundaryPoint(
  coordinates: Coordinate[],
  direction: BoundaryDirection,
  offsetX = 0,
  offsetY = 0
): Coordinate | undefined {
  switch (direction) {
    case BoundaryDirection.TopLeft:
      return getMostLeftUpperPoint(coordinates);
    case BoundaryDirection.TopMiddle:
      return getTopMiddlePoint(coordinates, offsetX, offsetY);
    case BoundaryDirection.BottomMiddle:
      return getBottomMiddlePoint(coordinates, offsetX, offsetY);
    default:
      return undefined;
  }
}

export function getMostLeftUpperPoint(coordinates: Coordinate[]): Coordinate | undefined {
  // keypoint 류는 coordinates 중간에 null 이 있는 경우가 있음
  coordinates = coordinates.filter(v => isValidCoordinate(v));
  if (isEmpty(coordinates)) {
    return undefined;
  }

  let mostLeftUpperPoint = first(coordinates) as Coordinate;
  coordinates.forEach(coordinate => {
    const [x, y] = coordinate;
    const [mostLeftX, mostUpperY] = mostLeftUpperPoint;
    if (x < mostLeftX) {
      mostLeftUpperPoint = coordinate;
    } else if (x === mostLeftX && y < mostUpperY) {
      mostLeftUpperPoint = coordinate;
    }
  });

  return mostLeftUpperPoint;
}

function getMiddlePointX(coordinates: Coordinate[]): number {
  const sortedCoordinates = sortBy(coordinates, [0]);
  const mostLeftX = first(sortedCoordinates)[0];
  const mostRightX = last(sortedCoordinates)[0];
  return (mostLeftX + mostRightX) / 2;
}

function getTopMiddlePoint(coordinates: Coordinate[], offsetX: number, offsetY: number): Coordinate | undefined {
  coordinates = coordinates.filter(v => isValidCoordinate(v));
  if (isEmpty(coordinates)) {
    return undefined;
  }

  // TODO: 상한선 체크 후 필요하면 정의.
  const mostTopY = Math.min(...coordinates.map(([_, y]) => y));
  return [getMiddlePointX(coordinates) + offsetX, mostTopY + offsetY];
}

function getBottomMiddlePoint(coordinates: Coordinate[], offsetX: number, offsetY: number): Coordinate | undefined {
  coordinates = coordinates.filter(v => isValidCoordinate(v));
  if (isEmpty(coordinates)) {
    return undefined;
  }

  // TODO: 하한선 체크 후 필요하면 정의.
  const mostBottomY = Math.max(...coordinates.map(([_, y]) => y));
  return [getMiddlePointX(coordinates) + offsetX, mostBottomY + offsetY];
}

export function rotatePointWithCenterPoint(point: Coordinate, degree: number, centerPoint: Coordinate): Coordinate {
  const radian = degreeToRadian(degree % 360);
  const [pointX, pointY] = point;
  const [cx, cy] = centerPoint;
  let newPoint = [pointX - cx, pointY - cy];
  newPoint = [
    newPoint[0] * Math.cos(radian) - newPoint[1] * Math.sin(radian),
    newPoint[0] * Math.sin(radian) + newPoint[1] * Math.cos(radian)
  ];
  return [newPoint[0] + cx, newPoint[1] + cy];
}

export function rotateCoordinates(coords: Coordinate[], degree: number): Coordinate[] {
  const { x, y } = getLineIntersection(coords[0], coords[2], coords[1], coords[3]);
  return coords.map(point => rotatePointWithCenterPoint(point, degree, [x, y]));
}

function getLineIntersection(pointOne: Coordinate, pointTwo: Coordinate, pointThree: Coordinate, pointFour: Coordinate): {
  x: number,
  y: number
} {
  const denominator =
    (pointFour[1] - pointThree[1]) * (pointTwo[0] - pointOne[0]) -
    (pointFour[0] - pointThree[0]) * (pointTwo[1] - pointOne[1]);
  if (denominator === 0) {
    return {
      x: 0,
      y: 0
    };
  }
  let a = pointOne[1] - pointThree[1];
  const b = pointOne[0] - pointThree[0];
  const numerator = (pointFour[0] - pointThree[0]) * a - (pointFour[1] - pointThree[1]) * b;
  a = numerator / denominator;

  return {
    x: pointOne[0] + a * (pointTwo[0] - pointOne[0]),
    y: pointOne[1] + a * (pointTwo[1] - pointOne[1])
  };
}

export function convertClockwiseOrder(coordinates: Coordinate[]): Coordinate[] {
  if (!isClockwiseOrder(coordinates)) {
    const firstCoord = coordinates.shift();
    const reverseCoordinates = coordinates.reverse();
    reverseCoordinates.unshift(firstCoord);
    return reverseCoordinates;
  }
  return coordinates;
}

// reference https://en.wikipedia.org/wiki/Shoelace_formula
export function isClockwiseOrder(coordinates: Coordinate[]): boolean {
  let area = 0;
  coordinates = compact(coordinates);
  const coordLength = coordinates.length;
  for (let i = 0; i < coordLength; i++) {
    const nextIndex = (i + 1) % coordLength;
    const [currX, currY] = coordinates[i];
    const [nextX, nextY] = coordinates[nextIndex];
    area += currX * nextY;
    area -= nextX * currY;
  }
  return (area / 2) > 0;
}

export function roundDecimals(num: number, unit = 0.01): number {
  if (!unit || !isNumber(unit) || unit === 0) {
    unit = 0.01;
  }
  const reversedUnit = 1 / unit;
  return Math.round((num + Number.EPSILON) * reversedUnit) / reversedUnit;
}

// 인자로 들어오는 수를 가장 근접한 0.05단위의 수로 치환
export function unitize(num: number, unit: number): number {
  if (!unit || !isNumber(unit) || unit === 0) {
    unit = 1;
  }
  const roundedNum = roundDecimals(num, unit);
  const remainder = roundDecimals(roundedNum % unit, unit);
  if (remainder === unit) {
    return roundedNum;
  }
  return roundedNum - remainder;
}

export function distanceBetweenPoints(point1: Coordinate, point2: Coordinate): number {
  return Math.pow(point1[0] - point2[0], 2) + Math.pow(point1[1] - point2[1], 2);
}

export function degreeToRadian(degrees: number): number {
  return degrees * Math.PI / 180;
}

export function cutRangeValue(value: number, maxRange: number, minRange?: number): number {
  if (typeof minRange === 'undefined') {
    minRange = -maxRange;
  }
  return Math.max(Math.min(value, maxRange), minRange);
}

export function radianToDegree(radians: number): number {
  let degree = radians * (180 / Math.PI);
  degree = (degree + 180) % 360;
  return degree <= 0 ? degree + 180 : degree - 180;
}

export function getMinMaxPointFromPoints(points: Coordinate[]): number[] {
  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = 0;
  let maxY = 0;
  points.forEach(([x, y]) => {
    minX = Math.min(x, minX);
    minY = Math.min(y, minY);
    maxX = Math.max(x, maxX);
    maxY = Math.max(y, maxY);
  });

  return [minX, minY, maxX, maxY];
}

export function convertPointToBox(points: Coordinate[]): number[] {
  const [xMin, yMin, xMax, yMax] = getMinMaxPointFromPoints(points);
  const width = xMax - xMin;
  const height = yMax - yMin;
  return [xMin, yMin, width, height];
}

export function getBoxRatioFromPoints(points: Coordinate[]): { width: number; height: number; diagonal: number; } {
  const [, , width, height] = convertPointToBox(points);
  const diagonal = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));

  return { width, height, diagonal };
}

export function convertBoxToPoint([topLeftX, topLeftY, width, height]: [number, number, number, number]): Coordinate[] {
  return [[topLeftX, topLeftY], [width + topLeftX, topLeftY], [topLeftX + width, topLeftY + height], [topLeftX, topLeftY + height]];
}

export function getCoordinatesByCenterWithPoints(coordinates: Coordinate[], isPolyline = false): Coordinate[] {
  if (!isPolyline) {
    coordinates = cloneDeep(coordinates);
    coordinates.push(first(coordinates));
  }
  return coordinates.reduce((acc, currentCoordinate, currentIndex) => {
    const nextCoordinate = coordinates[currentIndex + 1];
    if (nextCoordinate) {
      const [x1, y1] = currentCoordinate;
      const [x2, y2] = nextCoordinate;
      const centerCoordinate = [x1 + (x2 - x1) / 2, y1 + (y2 - y1) / 2] as Coordinate;
      acc.push(centerCoordinate);
      return acc;
    }
    return acc;
  }, [] as Coordinate[]);
}

export function removeSequenceSamePoints(coordinates: Coordinate[]): Coordinate[] {
  const length = coordinates?.length;
  if (isEmpty(coordinates) || length <= 1) {
    return coordinates;
  }
  return coordinates.reduce((acc, currentCoordinate, currentIndex) => {
    const nextCoordinate = coordinates[currentIndex + 1] ?? coordinates[0];
    if (!isEqual(currentCoordinate, nextCoordinate)) {
      acc.push(currentCoordinate);
    }
    return acc;
  }, [] as Coordinate[]);
}

export function validation2DCuboid(coordinates: Coordinate[]): boolean {
  if (coordinates?.length !== 8) {
    return false;
  }
  return (coordinates[0][1] < coordinates[3][1] && coordinates[1][1] < coordinates[2][1])
    && (coordinates[4][1] < coordinates[7][1] && coordinates[5][1] < coordinates[6][1]);
}

interface SplitCoordinatesResult {
  firstCoordinates: Coordinate[];
  secondCoordinates: Coordinate[];
}

export function splitCoordinates(sourceCoordinates: Coordinate[], splitTargetCoordinates: Coordinate[]): SplitCoordinatesResult {
  const firstCoordinates = [] as Coordinate[];
  const secondCoordinates = [] as Coordinate[];
  let currentCoordinates = firstCoordinates;
  sourceCoordinates.forEach(([x, y]) => {
    currentCoordinates.push([x, y]);
    if (splitTargetCoordinates.some(([sx, sy]) => sx === x && sy === y)) {
      currentCoordinates = firstCoordinates === currentCoordinates ? secondCoordinates : firstCoordinates;
      currentCoordinates.push([x, y]);
    }
  });

  return { firstCoordinates, secondCoordinates };
}

export function resizeBoxForSquareMode(currentPoints: Coordinate[], resizerIndex: BoxDirection): void {
  const { width, height } = getBoundaryBox(currentPoints);
  const diff = width - height;
  switch (resizerIndex) {
    case BoxDirection.TopLeft:
      currentPoints[0][1] -= diff;
      currentPoints[1][1] = currentPoints[0][1];
      break;
    case BoxDirection.TopRight:
      currentPoints[0][1] -= diff;
      currentPoints[1][1] = currentPoints[0][1];
      break;
    case BoxDirection.BottomRight:
      currentPoints[2][1] += diff;
      currentPoints[3][1] = currentPoints[2][1];
      break;
    case BoxDirection.BottomLeft:
      currentPoints[2][1] += diff;
      currentPoints[3][1] = currentPoints[2][1];
      break;
    case BoxDirection.Top:
      currentPoints[2][0] -= diff;
      currentPoints[1][0] = currentPoints[2][0];
      break;
    case BoxDirection.Right:
      currentPoints[0][1] -= diff;
      currentPoints[1][1] = currentPoints[0][1];
      break;
    case BoxDirection.Bottom:
      currentPoints[2][0] -= diff;
      currentPoints[1][0] = currentPoints[2][0];
      break;
    case BoxDirection.Left:
      currentPoints[0][1] -= diff;
      currentPoints[1][1] = currentPoints[0][1];
      break;
  }
}

export function resizeBoxForRollbackBbox(currentPoints: Coordinate[], resizerIndex: BoxDirection): void {
  switch (resizerIndex) {
    case BoxDirection.TopLeft:
      currentPoints[1][1] = currentPoints[0][1];
      currentPoints[3][0] = currentPoints[0][0];
      break;
    case BoxDirection.TopRight:
      currentPoints[0][1] = currentPoints[1][1];
      currentPoints[2][0] = currentPoints[1][0];
      break;
    case BoxDirection.BottomRight:
      currentPoints[3][1] = currentPoints[2][1];
      currentPoints[1][0] = currentPoints[2][0];
      break;
    case BoxDirection.BottomLeft:
      currentPoints[2][1] = currentPoints[3][1];
      currentPoints[0][0] = currentPoints[3][0];
      break;
  }
}

export function getCuboid2DInformation(coordinates: Coordinate[]): CuboidInformation | null {
  if (coordinates.length !== 8) {
    return null;
  }
  const frontCoordinates = coordinates.slice(0, 4);
  const backCoordinates = coordinates.slice(4, 8);
  const { width: frontWidth, height: frontHeight } = getBoundaryBox(frontCoordinates);
  const { width: backWidth, height: backHeight } = getBoundaryBox(backCoordinates);
  const frontArea = frontWidth * frontHeight;
  const frontPolygonArea = getPolygonArea(frontCoordinates);
  const backArea = backWidth * backHeight;
  const backPolygonArea = getPolygonArea(backCoordinates);
  return {
    frontArea,
    frontPolygonArea,
    frontWidth,
    frontHeight,
    backWidth,
    backHeight,
    backArea,
    backPolygonArea,
  };
}

export function getCuboid2DSumDistance(coordinates: Coordinate[], imageSize: ImageSize): number | null {
  if (coordinates.length !== 8 || validateCoordinateOutsideImage(coordinates, imageSize)) {
    return null;
  }
  const targetPoints = [
    [coordinates[0], coordinates[1]], [coordinates[1], coordinates[2]], [coordinates[3], coordinates[4]], [coordinates[4], coordinates[0]],
    [coordinates[4], coordinates[5]], [coordinates[5], coordinates[6]], [coordinates[6], coordinates[7]], [coordinates[7], coordinates[4]],
    [coordinates[0], coordinates[4]], [coordinates[1], coordinates[5]], [coordinates[2], coordinates[6]], [coordinates[3], coordinates[7]]
  ];

  return targetPoints.reduce((acc, [point1, point2]) => acc + getDistanceBetweenTwoPoint(point1, point2), 0);
}

function validateCoordinateOutsideImage(coordinates: Coordinate[], imageSize: ImageSize): boolean {
  return coordinates.some(([x, y]) => y > imageSize.width || x > imageSize.width || x < 0 || y < 0);
}

export function validateCuboidBoxSizes(validations: Partial<ValidationOption>, coordinates: Coordinate[]): boolean {
  if (!isEmpty(validations) && coordinates.length === 8) {
    const frontCoordinates = coordinates.slice(0, 4);
    const backCoordinates = coordinates.slice(4, 8);
    const leftCoordinates = [coordinates[1], coordinates[5], coordinates[6], coordinates[2]];
    const rightCoordinates = [coordinates[0], coordinates[4], coordinates[7], coordinates[3]];
    const minFrontBoxSizes = validations.minFrontBoxSizes ?? [];
    const minBackBoxSizes = validations.minBackBoxSizes ?? [];
    const minLeftBoxSizes = validations.minLeftBoxSizes ?? [];
    const minRightBoxSizes = validations.minRightBoxSizes ?? [];

    if (minFrontBoxSizes.length === 0 && minBackBoxSizes.length === 0 && minLeftBoxSizes.length === 0 && minRightBoxSizes.length === 0) {
      return true;
    }
    return validationCuboidBoxForArea(minFrontBoxSizes, frontCoordinates)
      || validationCuboidBoxForArea(minBackBoxSizes, backCoordinates)
      || validationCuboidBoxForArea(minLeftBoxSizes, leftCoordinates)
      || validationCuboidBoxForArea(minRightBoxSizes, rightCoordinates);
  }

  return true;
}

export function validationCuboidBoxForArea(minBoxSizes: SizeValue[], coordinates: Coordinate[]): boolean {
  if (minBoxSizes.length === 0) {
    return false;
  } else {
    const { width, height } = getBoundaryBox(coordinates);
    return minBoxSizes.some(({ width: minWidth, height: minHeight }) => width >= minWidth && height >= minHeight);
  }
}

export function pointInPolygon(point: Coordinate, polygon: Coordinate[]): boolean {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  // ref. https://www.algorithms-and-technologies.com/point_in_polygon/javascript

  const x = point[0];
  const y = point[1];

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect = ((yi > y) !== (yj > y))
      && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

export function isZeroWidthFromCoordinates(coordinates: Coordinate[]): boolean {
  return coordinates ? isArrayEqual(coordinates[0], coordinates[1]) && isArrayEqual(coordinates[2], coordinates[3]) : false;
}

export function isValidateFlatCuboid(coordinates: Coordinate[]): boolean {
  if (isZeroWidthFromCoordinates(coordinates)) {
    return true;
  }
  const originCoordinates = coordinates.slice(0, 4);
  const bboxCenterPoint = getCenterFromCoordinates(originCoordinates);
  const otherCoordinates = coordinates.slice(4);
  if (isEmpty(otherCoordinates)) {
    return true;
  }
  const sideAreaCoordinates = getSideAreaCoordinatesFromFlatCuboid(coordinates);
  // 기준면 0 1 2 3 시계방향 점 순서 보장
  const isClockWise = isBoxClockWise(bboxCenterPoint, originCoordinates);
  const isCrossed = isCrossedBox(originCoordinates);
  const isValidOriginBox = isClockWise && !isCrossed;
  // 측면이 기준면과 겹칠 수 없음
  const overlapped = getOverlapSize(sideAreaCoordinates, originCoordinates) > 1;
  // 측면 추가 점이 기준면 안에 위치할 수 없음
  const otherPointOutOfBox = otherCoordinates.every(point => !isInside(point, originCoordinates));
  return otherPointOutOfBox
    && !overlapped
    && !pointInPolygon(bboxCenterPoint, sideAreaCoordinates)
    && isValidOriginBox;
}

function isBoxClockWise(bboxCenterPoint: Coordinate, coordinates: Coordinate[]): boolean {
  const upperAngle = calculateAngleWithDirection(bboxCenterPoint, coordinates[0], bboxCenterPoint, coordinates[1]);
  const lowerAngle = calculateAngleWithDirection(bboxCenterPoint, coordinates[2], bboxCenterPoint, coordinates[3]);
  return upperAngle > 0 && lowerAngle > 0;
}

function calculateAngleWithDirection(vector1Start: Coordinate, vector1End: Coordinate, vector2Start: Coordinate, vector2End: Coordinate): number {
  const [x1, y1] = [vector1End[0] - vector1Start[0], vector1End[1] - vector1Start[1]];
  const [x2, y2] = [vector2End[0] - vector2Start[0], vector2End[1] - vector2Start[1]];

  const dotProduct = x1 * x2 + y1 * y2;
  const magnitude1 = Math.sqrt(x1 * x1 + y1 * y1);
  const magnitude2 = Math.sqrt(x2 * x2 + y2 * y2);

  const cosTheta = dotProduct / (magnitude1 * magnitude2);
  const angleRadians = Math.acos(cosTheta);
  let angleDegrees = (angleRadians * 180) / Math.PI;

  // Determine the sign of the angle based on the cross product
  const crossProduct = x1 * y2 - x2 * y1;
  if (crossProduct < 0) {
    angleDegrees = -angleDegrees;
  }

  return angleDegrees;
}

export function isPolygonRectangle(coordinates: Coordinate[]): boolean {
  if (coordinates.length !== 4) {
    return false;
  }

  const edges = coordinates.map((point, i) => {
    const nextPoint = coordinates[(i + 1) % 4];
    const dx = nextPoint[0] - point[0];
    const dy = nextPoint[1] - point[1];
    return { dx, dy };
  });

  const dotProducts = edges.map((edge, i) => {
    const nextEdge = edges[(i + 1) % 4];
    return edge.dx * nextEdge.dx + edge.dy * nextEdge.dy;
  });

  return dotProducts.every(product => Math.abs(product) < 0.1);
}

export function needSwitchExtendedPoint(coordinates: Coordinate[]): boolean {
  if (!(coordinates[4] && coordinates[5])) {
    return false;
  }
  const sideArea = getSideAreaCoordinatesFromFlatCuboid(coordinates);
  return isCrossedBox(sideArea);
}

// 사각형에서 대각선이 변보다 긴 원리 이용
function isCrossedBox(coordinates: Coordinate[]): boolean {
  const verticalDistance = getDistanceBetweenTwoPoint(coordinates[0], coordinates[3]) + getDistanceBetweenTwoPoint(coordinates[1], coordinates[2]);
  const horizonDistance = getDistanceBetweenTwoPoint(coordinates[0], coordinates[1]) + getDistanceBetweenTwoPoint(coordinates[2], coordinates[3]);
  const crossDistance = getDistanceBetweenTwoPoint(coordinates[0], coordinates[2]) + getDistanceBetweenTwoPoint(coordinates[1], coordinates[3]);
  return (verticalDistance > crossDistance) || (horizonDistance > crossDistance);
}

export function isRightSideArea(coordinates: Coordinate[]): boolean {
  if (!coordinates[4]) {
    return false;
  }
  const originBox = coordinates.slice(0, 4);
  const rightSide = [coordinates[1], ...coordinates.slice(4), coordinates[2]];
  const leftSide = [coordinates[0], ...coordinates.slice(4), coordinates[3]];
  const rightOverlap = getOverlapSize(originBox, rightSide);
  const leftOverlap = getOverlapSize(originBox, leftSide);
  return leftOverlap > rightOverlap;
}

export function findPerpendicularFoot(point: Coordinate, lineStart: Coordinate, lineEnd: Coordinate): Coordinate {
  const [x, y] = point;
  const [x1, y1] = lineStart;
  const [x2, y2] = lineEnd;

  const dx = x2 - x1 === 0 ? 1 : x2 - x1;
  const dy = y2 - y1;

  const t = ((x - x1) * dx + (y - y1) * dy) / (dx * dx + dy * dy);
  const footX = x1 + t * dx;
  const footY = y1 + t * dy;

  return [footX, footY];
}

export function findIntersection(line1: Coordinate[], line2: Coordinate[]): Coordinate | null {
  const [[x1, y1], [x2, y2]] = line1;
  const [[x3, y3], [x4, y4]] = line2;

  // Calculate the denominator of the intersection point formula
  let denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

  // Check if the lines are parallel or coincident
  if (denominator === 0) {
    denominator = 1;
  }

  // Calculate the x-coordinate of the intersection point
  const numeratorX = (x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4);
  const x = numeratorX / denominator;

  // Calculate the y-coordinate of the intersection point
  const numeratorY = (x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4);
  const y = numeratorY / denominator;

  return [x, y];
}

export function getDistanceBetweenLineAndPoint(line: Coordinate[], point: Coordinate): number {
  const [[x1, y1], [x2, y2]] = line;
  const [x3, y3] = point;

  // Calculate the distance formula components
  const numerator = Math.abs((y2 - y1) * x3 - (x2 - x1) * y3 + x2 * y1 - y2 * x1);
  const denominator = Math.sqrt(Math.pow(y2 - y1, 2) + Math.pow(x2 - x1, 2));

  // Calculate the distance between the line and the point
  return numerator / denominator;
}

function getMiddleCoordinate(p1: Coordinate, p2: Coordinate): Coordinate {
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  return [(x1 + x2) / 2, (y1 + y2) / 2];
}

export function getSideAreaCoordinatesFromFlatCuboid(coordinates: Coordinate[], isMiddleLine = false): Coordinate[] {
  if (coordinates[4]) {
    if (isMiddleLine) {
      return [getMiddleCoordinate(coordinates[0], coordinates[1]), ...coordinates.slice(4), getMiddleCoordinate(coordinates[2], coordinates[3])];
    }
    if (isRightSideArea(coordinates)) {
      return [coordinates[1], ...coordinates.slice(4), coordinates[2]];
    } else {
      return [coordinates[0], ...coordinates.slice(4), coordinates[3]];
    }
  }
  return [];
}

export function getCenterFromCoordinates(coordinates: Coordinate[]): Coordinate {
  const { x, y, width, height } = getBoundaryBox(coordinates);
  return [x + (width / 2), y + (height / 2)];
}

export function getCenterFromTrapezoid(coordinates: Coordinate[]): Coordinate {
  const centerX = sumBy(coordinates, ([x, _]) => x) / 4;
  const centerY = sumBy(coordinates, ([_, y]) => y) / 4;
  return [centerX, centerY];
}

export function getDividePoints([x0, y0]: Coordinate, [x1, y1]: Coordinate, gridCount: number): Coordinate[] {
  const coordinateLength = gridCount + 1;
  const widthDiff = (x1 - x0) / gridCount;
  const heightDiff = (y1 - y0) / gridCount;
  return times(coordinateLength).map(i => {
    if (i === 0) {
      return [x0, y0];
    } else if (i === gridCount) {
      return [x1, y1];
    }
    return [x0 + widthDiff * i, y0 + heightDiff * i];
  }) as Coordinate[];
}

export function getReorderPointsForGridMode(coordinates: Coordinate[]): Coordinate[] {
  let targetIndex = coordinates.findIndex(point => {
    let topXCount = 0;
    let topYCount = 0;
    coordinates.forEach(diffPoint => {
      if (point[0] <= diffPoint[0]) {
        topXCount++;
      }
      if (point[1] <= diffPoint[1]) {
        topYCount++;
      }
    });
    if (topXCount > 2 && topYCount > 2) {
      return true;
    }
    return false;
  });
  if (targetIndex === 0) {
    return coordinates;
  }
  while (targetIndex > 0) {
    targetIndex--;
    const firstP = coordinates.shift();
    coordinates.push(firstP);
  }
  return coordinates;
}

export function convertCornerCoordinates(coordinates: Coordinate[], gridCount: number | undefined): Coordinate[] {
  if (!isNil(gridCount) && coordinates.length === (gridCount + 1) * (gridCount + 1)) {
    return [0, gridCount, gridCount * gridCount + gridCount + gridCount, gridCount * gridCount + gridCount].map(i => coordinates[i]);
  }
  return coordinates;
}

export function switchFlatCuboidPoints(points: Coordinate[]): Coordinate[] {
  const tempPoints = cloneDeep(points);
  if (isRightSideArea(points)) {
    points[0] = tempPoints[1];
    points[1] = tempPoints[4];
    points[2] = tempPoints[5];
    points[3] = tempPoints[2];
    points[4] = tempPoints[0];
    points[5] = tempPoints[3];
  } else {
    points[0] = tempPoints[4];
    points[1] = tempPoints[0];
    points[2] = tempPoints[3];
    points[3] = tempPoints[5];
    points[4] = tempPoints[1];
    points[5] = tempPoints[2];
  }
  return points;
}

export function reIndexBboxPoints(coordinates: Coordinate[]): Coordinate[] {
  const reIndexedPoints = [];
  const points = coordinates.slice(0, 4);
  const xPoints = points.map(point => point[0]);
  const yPoints = points.map(point => point[1]);
  const [minX, maxX] = [Math.min(...xPoints), Math.max(...xPoints)];
  const [minY, maxY] = [Math.min(...yPoints), Math.max(...yPoints)];

  points.forEach(point => {
    switch (point.toString()) {
      case [minX, minY].toString():
        reIndexedPoints[0] = point;
        break;
      case [maxX, minY].toString():
        reIndexedPoints[1] = point;
        break;
      case [maxX, maxY].toString():
        reIndexedPoints[2] = point;
        break;
      case [minX, maxY].toString():
        reIndexedPoints[3] = point;
        break;
    }
  });

  if (compact(reIndexedPoints).length === points.length) {
    return reIndexedPoints;
  }
  return coordinates;
}

interface PolygonShapePointsResult {
  polygonShapePoints: Coordinate[];
  reversedPointMap: Map<string, Coordinate>;
}

const coordinateCompareFn = (a: Coordinate, b: Coordinate) => a[0] === b[0] ? a[1] - b[1] : a[0] - b[0];

export function getPolygonShapePointsWithMap(targetPoints: Coordinate[], points: Coordinate[], useFloat: boolean): PolygonShapePointsResult {
  const reversedPointMap = new Map<string, Coordinate>();
  const offset = 0.25;
  const originOrderPoints = points.map(([x, y]) => {
    const p1 = [x - offset, y] as Coordinate;
    const p2 = [x, y - offset] as Coordinate;
    reversedPointMap.set(JSON.stringify(p1), [x, y]);
    reversedPointMap.set(JSON.stringify(p2), [x, y]);
    return [p1, p2];
  });
  const reversedPoints = points.slice().reverse().map(([x, y]) => {
    const p1 = [x, y + offset] as Coordinate;
    const p2 = [x + offset, y] as Coordinate;
    reversedPointMap.set(JSON.stringify(p1), [x, y]);
    reversedPointMap.set(JSON.stringify(p2), [x, y]);
    return [p1, p2];
  });
  const polygonShapePoints: Coordinate[] = [...flatten(originOrderPoints), ...flatten(reversedPoints)];
  const wrappedIntersection = useFloat ? getIntersection(targetPoints, polygonShapePoints) : roundMultiPolygon(getIntersection(targetPoints, polygonShapePoints));
  const intersections = uniqBy(flattenDepth<Coordinate>(wrappedIntersection, 2), (point => JSON.stringify(point)));
  const epsilon = useFloat ? offset * 2 : 1;
  if (!isEmpty(intersections)) {
    intersections.sort(coordinateCompareFn);
    intersections.reduce((prev, curr) => {
      const xDiff = Math.abs(curr[0] - prev[0]);
      const yDiff = Math.abs(curr[1] - prev[1]);
      const isXSimilar = xDiff > 0 && xDiff <= epsilon;
      const isYSimilar = yDiff > 0 && yDiff <= epsilon;
      if (isXSimilar && isYSimilar) {
        const key = JSON.stringify(curr);
        if (!reversedPointMap.has(key)) {
          reversedPointMap.set(key, prev);
        }
        return curr;
      } else if (isXSimilar || isYSimilar) {
        return prev;
      }
      return curr;
    });
  }
  return { polygonShapePoints, reversedPointMap };
}

export function getIntersection(targetPoints: Coordinate[], boolOpPoints: Coordinate[]): MultiPolygon {
  if (isEmpty(targetPoints) || isEmpty(boolOpPoints)) {
    return [];
  }
  const targetMultiPolygon = [targetPoints];
  const polyBoolOpPolygon = [boolOpPoints];
  let intersectionResult: MultiPolygon;
  try {
    intersectionResult = LazyPolygonClipping.intersection(targetMultiPolygon, polyBoolOpPolygon);
    return intersectionResult;
  } catch (e) {
    captureException(`polygon clipping intersection error: ${e}, targetPoints: ${targetPoints}, boolOpPoints: ${boolOpPoints}`);
    return [];
  }
}

export function polygonBooleanOperations(polyBoolOp: PolyBoolOp, targetPoints: Coordinate[], boolOpPoints: Coordinate[], useFloat: boolean): Coordinate[][] {
  if (isEmpty(targetPoints) || isEmpty(boolOpPoints)) {
    return [];
  }
  const targetMultiPolygon = [targetPoints];
  const polyBoolOpPolygon = [boolOpPoints];
  const intersectionResult = getIntersection(targetPoints, boolOpPoints);
  if (isEmpty(intersectionResult)) {
    return [];
  }
  switch (polyBoolOp) {
    case PolyBoolOp.union:
      const unionClippingResult = useFloat ? LazyPolygonClipping.union(targetMultiPolygon, polyBoolOpPolygon) : roundMultiPolygon(LazyPolygonClipping.union(targetMultiPolygon, polyBoolOpPolygon));
      return [transformMultiPolygonToPolygonByConnection(unionClippingResult)];
    case PolyBoolOp.difference:
      const differenceClippingResult = useFloat ? LazyPolygonClipping.difference(targetMultiPolygon, polyBoolOpPolygon) : roundMultiPolygon(LazyPolygonClipping.difference(targetMultiPolygon, polyBoolOpPolygon));
      // 영역 제거로 폴리곤이 나눠지는 경우
      if (differenceClippingResult.length > 1) {
        return differenceClippingResult.reduce((accPolygonList, currPolygonList) => [...accPolygonList, ...currPolygonList], []);
      } else {
        return [transformMultiPolygonToPolygonByConnection(differenceClippingResult)];
      }
    default:
      return [];
  }
}

export function lerpCuboid(startInstance: Partial<CuboidAnnotation>, endInstance: Partial<CuboidAnnotation>, t: number): Partial<CuboidAnnotation> {
  const { position: startPosition, geometry: startGeometry, rotation: startRotation } = startInstance;
  const { position: endPosition, geometry: endGeometry, rotation: endRotation } = endInstance;
  const position = lerp3D(startPosition, endPosition, t);
  const geometry = lerp3D(startGeometry, endGeometry, t);
  const rotation = lerp3D(startRotation, endRotation, t);
  return { position, geometry, rotation };
}

export function lerpBoundingBox(startBox: RectXYWH, endBox: RectXYWH, t: number): Coordinate[] {
  const { x: startX, y: startY, width: startWidth, height: startHeight } = startBox;
  const { x: endX, y: endY, width: endWidth, height: endHeight } = endBox;
  const [x, y] = lerp2D([startX, startY], [endX, endY], t);
  const width = lerp(startWidth, endWidth, t);
  const height = lerp(startHeight, endHeight, t);
  return convertBoxToPoint([x, y, width, height]);
}

export function lerp(start: number, end: number, t: number): number {
  return start + (end - start) * t;
}

export function lerp2D(start: Coordinate, end: Coordinate, t: number): Coordinate {
  const [x1, y1] = start;
  const [x2, y2] = end;

  const lerpedX = lerp(x1, x2, t);
  const lerpedY = lerp(y1, y2, t);

  return [lerpedX, lerpedY];
}

export type PointCloudLerpType = PointCloudPosition | PointCloudGeometry | PointCloudRotation;

// Linear interpolation for 3D coordinates (x, y, z)
export function lerp3D(start: PointCloudLerpType, end: PointCloudLerpType, t: number): PointCloudLerpType {
  const [x1, y1, z1] = start;
  const [x2, y2, z2] = end;

  const lerpedX = lerp(x1, x2, t);
  const lerpedY = lerp(y1, y2, t);
  const lerpedZ = lerp(z1, z2, t);

  return [lerpedX, lerpedY, lerpedZ];
}

export function calculateRectangularCuboidCenter(coordinates: Coordinate[]): Coordinate | null {
  if (coordinates.length !== 8) {
    return null;
  }
  const frontCenterCoordinate = getCenterFromTrapezoid(coordinates.slice(0, 4));
  const rearCenterCoordinate = getCenterFromTrapezoid(coordinates.slice(4, 8));
  return getCenterFromCoordinates([frontCenterCoordinate, rearCenterCoordinate]);
}

export function isInsideBuffer(x: number, y: number, buffer: svgjs.Circle, bufferRadius: number, scale: number): boolean {
  const { x: bufferX, y: bufferY } = buffer.transform();
  const radius = bufferRadius / scale;
  const [minX, maxX] = [bufferX - radius, bufferX + radius];
  const [minY, maxY] = [bufferY - radius, bufferY + radius];
  return x >= minX && x <= maxX && y >= minY && y <= maxY;
}

export function updateFlatCuboidBoxOnlyClassesPoints(instance: StudioAnnotation, flatCuboidFaceOption: FlatCuboidFaceOption): boolean {
  const { attributes, points } = instance;
  if (isNil(attributes)) {
    return false;
  }
  const boxOnlyClasses = flatCuboidFaceOption?.boxOnlyClasses ?? [];
  const boxOnlyFreePointClasses = flatCuboidFaceOption?.boxOnlyFreePointClasses ?? [];
  const targetClassValue = attributes[CLASS_KEY];
  const originCoordinates = points;
  if (originCoordinates.length >= 4) {
    if (boxOnlyClasses.includes(targetClassValue)) {
      const coordinates = originCoordinates.slice(0, 4) as Coordinate[];
      if (!isPolygonRectangle(coordinates)) {
        coordinates[1][1] = coordinates[0][1];
        coordinates[3][0] = coordinates[0][0];
        coordinates[2][0] = coordinates[1][0];
        coordinates[2][1] = coordinates[3][1];
      }
      if (isArrayEqual(coordinates, originCoordinates)) {
        return false;
      }
      instance.points = coordinates;
      return true;
    }

    if (boxOnlyFreePointClasses.includes(targetClassValue)) {
      const coordinates = originCoordinates.slice(0, 4) as Coordinate[];
      if (isArrayEqual(coordinates, originCoordinates)) {
        return false;
      }
      instance.points = coordinates;
      return true;
    }
    return false;
  } else {
    return false;
  }
}

export function areCoordinatesEqual(arr1: Coordinate[], arr2: Coordinate[]): boolean {
  arr1 = isEmpty(arr1) ? [] : arr1.slice().sort(coordinateCompareFn);
  arr2 = isEmpty(arr2) ? [] : arr2.slice().sort(coordinateCompareFn);
  return arr1.length === arr2.length && isArrayEqual(arr1, arr2);
}

