export function getBrightness(hexColor: string): number {
  const rgb = hexColor.replace(/^#/, '');
  const r = parseInt(rgb.substring(0, 2), 16);
  const g = parseInt(rgb.substring(2, 4), 16);
  const b = parseInt(rgb.substring(4, 6), 16);

  // 밝기 계산 (YIQ 알고리즘 사용)
  return (r * 299 + g * 587 + b * 114) / 1000;
}

export function getContrastColor(backgroundColor: string): string {
  return getBrightness(backgroundColor) > 128 ? '#000000' : '#ffffff';
}
