import { isEqual, isNil } from 'lodash-es';
import type { BoxGeometry, Vector3 } from 'three';
import { AnnotationType, StudioAnnotation } from '../../model/src/interfaces';

export function getCubeVertices(geometry: BoxGeometry, position: Vector3): number[][] {
  const { x, y, z } = position;
  const { width, height, depth } = geometry.parameters;
  const [halfWidth, halfHeight, halfDepth] = [width / 2, height / 2, depth / 2];
  return [
    [x - halfWidth, y + halfHeight, z - halfDepth],
    [x + halfWidth, y + halfHeight, z - halfDepth],
    [x + halfWidth, y + halfHeight, z + halfDepth],
    [x - halfWidth, y + halfHeight, z + halfDepth],
    [x - halfWidth, y - halfHeight, z - halfDepth],
    [x + halfWidth, y - halfHeight, z - halfDepth],
    [x + halfWidth, y - halfHeight, z + halfDepth],
    [x - halfWidth, y - halfHeight, z + halfDepth]
  ];
}

export function getCuboids(annotations: StudioAnnotation[]): StudioAnnotation[] {
  return annotations.filter(annotation => annotation.type === AnnotationType.cuboid);
}

export function isPointCountFilledAll(cuboids: StudioAnnotation[]): boolean {
  return cuboids.every(annotation => !isNil(annotation.pointCount));
}

const COORDINATE_ATTRIBUTES = ['geometry', 'position', 'rotation'];

export function isCoordinateChangedForCuboid(prevCuboid: StudioAnnotation, currCuboid: StudioAnnotation): boolean {
  return COORDINATE_ATTRIBUTES.some(attr => !isEqual(prevCuboid[attr], currCuboid[attr]));
}
