import { ErrorHandler } from '@angular/core';
import { AppInjector } from '@falcon/common/utils';
import { iif, Observable, of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

export function GlobalCatchError(target: any, propertyKey: string, descriptor: PropertyDescriptor): void {
  const originalMethod = descriptor.value || descriptor.get;
  if (originalMethod) {
    const overrideFunction = function(...args: any[]): any {
      const result = originalMethod.apply(this, args);
      if (result instanceof Observable) {
        return result.pipe(
          catchError(err => {
            const injector = AppInjector.getInstance().getInjector();
            if (injector) {
              injector.get(ErrorHandler).handleError(err);
            }
            return of(err);
          })
        );
      } else {
        return result;
      }
    };
    descriptor.value ? descriptor.value = overrideFunction : descriptor.get = overrideFunction;
  }
}

export function globalCatchError<T>(errorCallback?: () => void, canCaught = false): (source: Observable<T>) => Observable<T> {
  return (source: Observable<unknown>) => source.pipe(
    catchError((err, caught: Observable<T>) => {
      if (errorCallback) {
        errorCallback();
      }
      const injector = AppInjector.getInstance().getInjector();
      if (injector) {
        injector.get(ErrorHandler).handleError(err);
      }
      return canCaught ? caught : of(err);
    })
  );
}
