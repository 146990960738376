import type { Coordinate, ImageSize } from '@aimmo/annotator-model';
import { AnnotationAttributeAnswer } from '@bluewhale/ngx-annotator/components/attributes/model';
import { DisplayLabelOption, PointAnnotation } from '@bluewhale/ngx-annotator/model';
import type { CanvasDrawType, CircleChangeType, ItemType, Rect, TextBoxDirection } from './image-tool-enum';

export interface LoadingState {
  isLoadingImage: boolean;
  isLoadingInstance: boolean;
}

export interface PointLabel {
  id: string;
  name: string;
  color: string;
  optional?: boolean;
}

// TODO 상세 아이템별 인터페이스 분리 필요
export interface LoadableData {
  type: ItemType;
  id?: string;
  label?: string;
  displayLabel?: string;
  displayLabelOptions?: DisplayLabelOption[];
  color?: string;
  children?: ItemData[] | GroupData[];
  points?: Coordinate[];
  pointAnnotations?: PointAnnotation[];
  rect?: Rect;
  isUpperPlaneOnly?: boolean;
  isUnModifiable?: boolean;
  isBackground?: boolean;
  isVisualized?: boolean;
  degree?: number;
  direction?: TextBoxDirection;
  category?: string;
  groupId?: string;
  attributes?: AnnotationAttributeAnswer;
  attributeLabel?: AttributeLabel[];
  keyPoints?: KeyPoint[];
  pointLabels?: PointLabel[];
  pairIds?: string[];
  invisibleKeys?: string[];
  reworkRequested?: boolean;
  locked?: boolean;
  visible?: boolean;
  strokeDashed?: boolean;
  selected?: boolean;
  isApproximateProjection?: boolean;
  internalLabel?: string;
}

export interface CopiedItem {
  loadableData: LoadableData;
}

export interface CopiedAttributesWithDataType {
  type: ItemType;
  answer: AnnotationAttributeAnswer;
}


export interface WorkerRequestParam {
  id: string;
  params: {
    type: WorkerRequestType;
    url: string;
    pixelIndices?: number[];
    canvasWidth?: number;
    canvasHeight?: number;
    fillColor?: string;
    outlineColor?: string;
    dataBuffer?: Uint32Array;
  };
}

export enum WorkerRequestType {
  loadImage = 'load-image',
  loadPointCloud = 'load-point-cloud',
  loadMultiplePointCloud = 'load-multiple-point-cloud',
  mergeAllPointCloud = 'merge-all-point-cloud',
  updateColor = 'update-color',
  updateMultipleColor = 'update-multiple-color',
  getEmptyPixels = 'get-empty-pixels',
  updateIncludedPoints = 'update-included-points',
  updateIntensityColor = 'update-intensity-color',
  updateOpacityPositionByZFiltering = 'update-opacity-position-by-filtering',
  updateVelocityColor = 'update-velocity-color',
  updateChannelColors = 'update-channel-colors',
  updateGroundColors = 'update-ground-colors',
  getOriginColors = 'get-origin-colors',
  updateIntensityColors = 'update-intensity-colors',
  updateVelocityColors = 'update-velocity-colors',
  updateOriginColor = 'update-origin-color',
  getOriginColor = 'get-origin-color',
  convertToMask = 'convertToMask',
  getPointCounts = 'getPointCounts'
}

export interface KeyPoint {
  [key: string]: Coordinate;
}

export interface LimitBoxDataParam {
  width?: number;
  height?: number;
  byUserAction?: boolean;
  lastDegree?: number;
  diffX?: number;
  diffY?: number;
  byKeyboard?: boolean;
}

export interface DefaultData {
  type: ItemType;
  label: string;
  color: string;
  validatedColor: string;
  id: string;
  points?: Coordinate[];
  order?: number;
  invalidMessages?: InvalidMessage[];
  externalInvalidMessages?: InvalidMessage[];
  warningMessages?: InvalidMessage[];
  trackId?: string;
  geometryInfo?: GeometryInfo;
  surroundingBox?: Coordinate[];
  groupId?: string;
  selectable?: boolean;
  isTemp?: boolean;
}

export enum GeometryInfoType {
  image,
  cuboid2d,
  cuboid3d,
  lasso,
  polylinePoint3d,
  polygon3d
}

export interface GeometryInfoForPolylinePoint3d {
  type: GeometryInfoType.polylinePoint3d;
  depth: number;
  width: number;
  height: number;
  pointsCount: number;
}

export interface GeometryInfoForLasso {
  type: GeometryInfoType.lasso;
  pointsCount: number;
}

export interface GeometryInfoForCuboid {
  type: GeometryInfoType.cuboid3d;
  depth: number;
  width: number;
  height: number;
  x: number;
  y: number;
  z: number;
  yaw: number;
  pitch?: number;
  roll?: number;
  pointCount?: number;
  distance?: number;
}

export interface GeometryInfoForPolygon3d {
  type: GeometryInfoType.polygon3d;
  depth: number;
  width: number;
  height: number;
}

export interface GeometryInfoFor2DCuboid {
  type: GeometryInfoType.cuboid2d;
  frontArea: number;
  frontPolygonArea: number;
  frontWidth: number;
  frontHeight: number;
  backArea: number;
  backPolygonArea: number;
  backWidth: number;
  backHeight: number;
}

export interface GeometryInfoForImageTool {
  type: GeometryInfoType.image;
  width: number;
  height: number;
  diagonal?: number;
  widthRatio?: number;
  heightRatio?: number;
  diagonalRatio?: number;
  topLeftPoint?: Coordinate;
  polylineLength?: number;
  polygonArea?: number;
  polygonAreaRatio?: number;
}

export type GeometryInfo =
  GeometryInfoForImageTool
  | GeometryInfoForCuboid
  | GeometryInfoFor2DCuboid
  | GeometryInfoForLasso
  | GeometryInfoForPolylinePoint3d;

export interface KeypointData extends DefaultData {
  keyPoints: KeyPoint[];
  invisibleKeys: string[];
  colorById: string;
  displayLabel?: string;
  pointLabels?: PointLabel[];
}

export interface ItemData extends DefaultData {
  points: Coordinate[];
}

export interface CuboidData extends ItemData {
  linePoints: Coordinate[][];
  isApproximateProjection?: boolean;
}

export interface OBBoxData extends DefaultData {
  degree: number;
  rect: Rect;
  points?: Coordinate[];
}

export interface PolylinePointData extends ItemData {
  pointAnnotations: PointAnnotation[];
}

export interface BBoxData extends ItemData {
  attributes?: AnnotationAttributeAnswer;
}

export interface GroupData extends DefaultData {
  children: ItemData[] | GroupData[];
}

export interface CircleChange {
  type: CircleChangeType;
  coordinate?: Coordinate;
  coordinates?: Coordinate[];
  circleIndex?: number;
  pointNames?: string[];
}

// Todo name refactoring 필요
export interface GeometryViewerInfo {
  geometryInfo: GeometryInfo;
}

export interface NextPointInfo {
  num: number;
  name?: string;
}

export interface CoordinateObject {
  x: number;
  y: number;
}

export interface CanvasItemParam {
  color: string;
  points: Coordinate[];
}

export interface CanvasItemDrawOption {
  items: CanvasItemParam[];
  imageSize: ImageSize;
  downloadType?: CanvasDrawType;
  convertColor?: (c: string) => string;
  globalCompositeOperation?: GlobalCompositeOperation;
}


export interface UndoRedoAttributeInfo {
  id: string;
  answer: AnnotationAttributeAnswer;
}

export interface AttributeLabel {
  name: string;
  value: string;
}

export interface InvalidMessage {
  title: string;
  description?: string;
}

export interface BoxInformation {
  width: number;
  height: number;
  diagonal: number;
}

export interface CuboidInformation {
  frontArea: number;
  frontWidth: number;
  frontHeight: number;
  frontPolygonArea: number;
  backArea: number;
  backWidth: number;
  backHeight: number;
  backPolygonArea: number;
}

export enum CuboidFace {
  top = 'top',
  front = 'front',
  rear = 'rear',
  left = 'left',
  right = 'right',
  bottom = 'bottom'
}

export interface CuboidFaceOption {
  [CuboidFace.top]: FaceOption;
  [CuboidFace.front]: FaceOption;
  [CuboidFace.rear]: FaceOption;
  [CuboidFace.left]: FaceOption;
  [CuboidFace.right]: FaceOption;
  [CuboidFace.bottom]: FaceOption;
}

export enum FlatCuboidFaceOptionType {
  singleDirection = 'singleDirection',
  multiDirection = 'multiDirection',
}

export interface FlatCuboidFaceOption {
  faceKey: string;
  faceExtensionKey?: string;
  front: DirectionFaceOption;
  rear: DirectionFaceOption;
  left: DirectionFaceOption;
  right: DirectionFaceOption;
  type: FlatCuboidFaceOptionType | undefined;
  boxOnlyClasses: string[];
  boxOnlyFreePointClasses: string[];
  middleLineSideAreaClasses: string[];
}

export interface FaceOption {
  color: string;
  opacity: number;
}

export interface DirectionFaceOption {
  value: string;
  color: string;
  matchValues?: string[];
}

export interface PolygonGridOptions {
  strokeWidth: number;
  strokeColor: string;
  strokeOpacity: number;
  gridCount: number;
}
