import type { AnnotationType } from '@bluewhale/ngx-annotator/model';

/**
 * 추가 수정 시 아래 파일들 같이 수정해야 함
 * {@link ProjectTypeIconComponent}, {@link AnnotationTypeIconComponent}, {@link ProjectOptionButtonComponent}, {@link DatasetTypePipe}
 */
export enum DatasetType {
  image = 'image',
  pointCloud = 'point_cloud',
  dicom = 'dicom',
  video = 'video',
  text = 'text',
  lotteQa = 'lotte_qa',
  segmentation = 'segmentation',
  objectTracking = 'object_tracking',
  motherData = 'mother_data',
  unknown = 'unknown'
}

export enum DatasetFormName {
  type = 'type'
}

export interface DatasetFormData {
  type: DatasetType;
}

export interface Dataset {
  dirCount: number;
  fileCount: number;
  id: string;
  size: number;
  type: DatasetType;
}

export enum QuotaOptionStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  hidden = 'hidden'
}

export interface ImageAnnotationsQuota {
  bbox: QuotaOptionStatus;
  bitmap: QuotaOptionStatus;
  landmark: QuotaOptionStatus;
  landmarkHdOd: QuotaOptionStatus;
  obbox: QuotaOptionStatus;
  obboxV2: QuotaOptionStatus;
  polySeg: QuotaOptionStatus;
  polygon: QuotaOptionStatus;
  polyline: QuotaOptionStatus;
  polylinePoint: QuotaOptionStatus;
  point: QuotaOptionStatus;
  cuboid: QuotaOptionStatus;
  cuboidPlain: QuotaOptionStatus;
  resource: QuotaOptionStatus;
  multiClassification: QuotaOptionStatus;
}

export interface PointCloudAnnotationsQuota {
  cuboid: QuotaOptionStatus;
  resource: QuotaOptionStatus;
  polylinePoint3d: QuotaOptionStatus;
  point3d: QuotaOptionStatus;
  lasso: QuotaOptionStatus;
  polygon3d: QuotaOptionStatus;
}

export interface VideoAnnotationsQuota {
  frameRange: QuotaOptionStatus;
  resource: QuotaOptionStatus;
}

export interface DicomAnnotationsQuota {
  resource: QuotaOptionStatus;
}

export interface TextAnnotationsQuota {
  entity: QuotaOptionStatus;
  resource: QuotaOptionStatus;
}

export interface LotteQaAnnotationsQuota {
  lotteQaRow: QuotaOptionStatus;
  resource: QuotaOptionStatus;
}

export interface SegmentationAnnotationsQuota {
  segmentationMask: QuotaOptionStatus;
  resource: QuotaOptionStatus;
}

export interface SequenceQuota {
  sequenceFrame: QuotaOptionStatus;
  singleFrame: QuotaOptionStatus;
}

export interface ImageDatasetQuota {
  annotations: ImageAnnotationsQuota;
  sequence: SequenceQuota;
  status: QuotaOptionStatus;
  type: DatasetType.image;
  aiAssist: AIAssistQuota;
}

export interface PointCloudDatasetQuota {
  annotations: PointCloudAnnotationsQuota;
  sequence: SequenceQuota;
  status: QuotaOptionStatus;
  type: DatasetType.pointCloud;
}

export interface VideoDatasetQuota {
  annotations: VideoAnnotationsQuota;
  status: QuotaOptionStatus;
  type: DatasetType.video;
}

export interface DicomDatasetQuota {
  annotations: DicomAnnotationsQuota;
  status: QuotaOptionStatus;
  type: DatasetType.dicom;
}

export interface TextDatasetQuota {
  annotations: TextAnnotationsQuota;
  status: QuotaOptionStatus;
  type: DatasetType.text;
}

export interface LotteQaDatasetQuota {
  annotations: LotteQaAnnotationsQuota;
  status: QuotaOptionStatus;
  type: DatasetType.lotteQa;
}

export interface SegmentationDatasetQuota {
  annotations: SegmentationAnnotationsQuota;
  status: QuotaOptionStatus;
  type: DatasetType.segmentation;
}

export interface ObjectTrackingDatasetQuota {
  annotations: ImageAnnotationsQuota;
  status: QuotaOptionStatus;
  type: DatasetType.objectTracking;
}

export interface AnnotationTypeQuota {
  type: AnnotationType;
  status: QuotaOptionStatus;
}

export type DatasetQuota =
  ImageDatasetQuota
  | PointCloudDatasetQuota
  | VideoDatasetQuota
  | DicomDatasetQuota
  | TextDatasetQuota
  | LotteQaDatasetQuota
  | SegmentationDatasetQuota
  | ObjectTrackingDatasetQuota;
export type SequenceDatasetQuota = ImageDatasetQuota | PointCloudDatasetQuota;

export interface AIAssistQuota {
  genericModel: QuotaOptionStatus;
}

export function isImageDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is ImageDatasetQuota {
  return datasetQuota?.type === DatasetType.image;
}

export function isObjectTrackingDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is ImageDatasetQuota {
  return datasetQuota?.type === DatasetType.objectTracking;
}

export function isPointCloudDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is PointCloudDatasetQuota {
  return datasetQuota?.type === DatasetType.pointCloud;
}

export function isVideoDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is VideoDatasetQuota {
  return datasetQuota?.type === DatasetType.video;
}

export function isDicomDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is DicomDatasetQuota {
  return datasetQuota?.type === DatasetType.dicom;
}

export function isTextDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is TextDatasetQuota {
  return datasetQuota?.type === DatasetType.text;
}

export function isLotteQaDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is LotteQaDatasetQuota {
  return datasetQuota?.type === DatasetType.lotteQa;
}

export function isSegmentationDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is SegmentationDatasetQuota {
  return datasetQuota?.type === DatasetType.segmentation;
}

export function isSequenceDatasetQuota(datasetQuota?: DatasetQuota): datasetQuota is SequenceDatasetQuota {
  return isImageDatasetQuota(datasetQuota) || isPointCloudDatasetQuota(datasetQuota);
}

export function isSequenceDatasetType(datasetType: DatasetType | undefined): boolean {
  return !!datasetType && [DatasetType.objectTracking, DatasetType.pointCloud].includes(datasetType);
}

export function isPointCloudDatasetType(datasetType: DatasetType | undefined): boolean {
  return !!datasetType && datasetType === DatasetType.pointCloud;
}

export function isVideoDatasetType(datasetType: DatasetType | undefined): boolean {
  return !!datasetType && datasetType === DatasetType.video;
}

export function isImageResourceDatasetType(datasetType: DatasetType | undefined): boolean {
  return [DatasetType.image, DatasetType.objectTracking, DatasetType.segmentation].includes(datasetType);
}

export function isTextResourceDatasetType(datasetType: DatasetType | undefined): boolean {
  return [DatasetType.text, DatasetType.lotteQa].includes(datasetType);
}

export interface Dataset {
  id: string;
  isSequence: boolean;
  type: DatasetType;
}

enum FileExtension {
  bin = 'bin',
  csv = 'csv',
  txt = 'txt',
  mp4 = 'mp4',
  unknown = 'unknown'
}

const FileIconByDatasetType = {
  [DatasetType.pointCloud]: FileExtension.bin,
  [DatasetType.lotteQa]: FileExtension.csv,
  [DatasetType.text]: FileExtension.txt,
  [DatasetType.video]: FileExtension.mp4,
  [DatasetType.unknown]: FileExtension.unknown
} as const;

export function getFileIconByDatasetType(datasetType: string, fileName?: string): string {
  if (datasetType === DatasetType.text) {
    if (fileName) {
      const splitFileName = fileName.split('.');
      if (splitFileName.length > 1) {
        return splitFileName[splitFileName.length - 1].trim().toLowerCase();
      }
    }
  }
  return FileIconByDatasetType[datasetType] ?? FileExtension.unknown;
}
