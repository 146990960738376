import { ModeType } from '@bluewhale/ngx-annotator/tool/image/model';
import { SensorModelType } from '@bluewhale/ngx-annotator/tool/point-cloud/model';
import { TemplateType } from '../enums';
import { PointCloudTemplateOption } from '../interfaces';
import { DEFAULT_DEGREE_RANGE, PointCloudChannel } from '../point-cloud-model';
import { Annotator } from './annotator';

// @dynamic
export class PointCloudAnnotator extends Annotator {
  private static instance: PointCloudAnnotator;

  private constructor() {
    super();
  }

  public get code(): TemplateType {
    return TemplateType.point_cloud;
  }

  public static getInstance(): PointCloudAnnotator {
    if (!this.instance) {
      this.instance = new PointCloudAnnotator();
    }
    return this.instance;
  }

  public getDefaultTemplateOption(): PointCloudTemplateOption {
    return {
      isShowSideArea: false,
      defaultMode: ModeType.select,
      useAnnotationDragdropOrdering: false,
      renderOption: {
        unit: 1,
        degreeRange: DEFAULT_DEGREE_RANGE,
        pointColor: '#ffffff',
        radialDistances: [],
        customCenter: null,
        carModel: { degree: 0, yPosition: 0, xPosition: 0, zPosition: 0, visible: false, type: SensorModelType.aimmo },
        camera: { position: [50, 50, 50], zoom: 50, halfPolarAngle: false, rotateStraightAngle: false },
        ground: { visible: false, yPosition: 0, color: '#749792', radius: 60 },
        saveTrackIdsInfo: false,
        sameSizeErrorRange: 0,
        controlVisible: true,
        enablePitch: false,
        enableRoll: false,
        createModeByRecentSelectCuboidShape: false
      },
      enableConvexHul: false,
      instanceCreatable: true,
      sizeCandidates: [],
      hasAIAssist: false,
      minGroupBySelectCount: 2,
      pointCloudChannel: PointCloudChannel.xyzi
    };
  }

  public getAnnotatedAnswerItems(items: any[]): any[] {
    return items;
  }

  public toString(): string {
    return 'PointCloudAnnotator[point_cloud]';
  }
}
