import { remove } from 'lodash-es';

export enum UndoRedoAction {
  createItems = 'create-items',
  updateItems = 'update-items',
  updateClassifications = 'update-classifications',
  removeItems = 'remove-items',
  updateCancelableItems = 'update-cancelable-items',
  creatingItem = 'creating-item',
  cancelCreateItem = 'cancel-create-item',
  createCancelableItems = 'create-cancelable-items',
  createRITMTemporalItems = 'create-ritm-temporal-items',
  changeInstancesOrder = 'change-instances-order',
  setFilter = 'set-filter',
  setFileAnswer = 'set-file-answer',
  frameMove = 'frame-move',
  reset = 'reset'
}

export interface CommandData {
  id?: string | string[];

  [key: string]: any;
}

export interface CommandResult {
  type?: string;
  data?: CommandData;
}

export interface ICommand<T> extends CommandResult {
  undo: UndoRedoExecute<T>;
  redo: UndoRedoExecute<T>;
}

export type UndoRedoContext<T> = Map<string, T>;

export type UndoRedoExecute<T> = (context: UndoRedoContext<T>) => void;

export class UndoManager {
  private commands: ICommand<any>[] = [];
  private index = -1;
  private readonly limit: number;
  private readonly context: UndoRedoContext<any> = new Map<string, any>();

  public constructor(limit = 0) {
    this.limit = limit;
  }

  public add<T extends any>(command: ICommand<T>): UndoManager {
    this.commands = this.commands.slice(0, this.index + 1);
    this.commands.push(command);
    if (this.limit > 0 && this.commands.length > this.limit) {
      this.commands.shift();
    } else {
      this.index++;
    }
    return this;
  }

  public remove<T extends any>(command: ICommand<T>): UndoManager {
    const index = this.commands.findIndex(c => c === command);
    if (index > -1) {
      remove(this.commands, com => com === command);
      if (index === this.index && this.index >= 0) {
        this.index--;
      }
      if (this.index > this.commands.length - 1) {
        this.index = this.commands.length - 1;
      }
    }
    return this;
  }

  public redo(): CommandResult | undefined {
    if (this.index < this.commands.length - 1) {
      this.index++;
      const command = this.commands[this.index];
      command.redo(this.context);
      const { type, data } = command;
      return { type, data };
    }
    return undefined;
  }

  public undo(): CommandResult | undefined {
    if (this.index >= 0) {
      const command = this.commands[this.index];
      command.undo(this.context);
      this.index--;
      const { type, data } = command;
      return { type, data };
    }
    return undefined;
  }

  public hasUndo(): boolean {
    return this.index >= 0;
  }

  public hasRedo(): boolean {
    return this.index < this.commands.length - 1;
  }

  public reset(resetCommandAndContext = true): void {
    this.index = -1;
    if (resetCommandAndContext) {
      this.commands = [];
      this.context.clear();
    }
  }

  public flushRedo(): void {
    if (this.hasRedo()) {
      this.commands = this.commands.slice(0, this.index + 1);
    }
  }
}

export const undoManager = new UndoManager();
