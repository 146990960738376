import { getFirstHyphenString, textToNumber } from '@bluewhale/ngx-annotator/util/text';
import { isEmpty, isNil, times } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

export interface IdInstance {
  id?: string;
  groupId?: string;
  pointAnnotations?: IdInstance[];
}

export interface LastNumbers {
  id: number;
  groupId: number;
  pointId: number;
}

export class IdGenerator {
  private currentFile = '';
  private lastNumbersMap = new Map<string, LastNumbers>();
  private groupIds: number[] = [];

  public setCurrentFile(currentFile: string): void {
    this.currentFile = currentFile;
  }

  public updateLastNumberIds(instances: IdInstance[], currentFile = this.currentFile): void {
    let lastNumberId = 0;
    let lastNumberGroupId = 0;
    let lastNumberPointId = 0;
    this.groupIds = [];
    instances
      .filter(i => !isNil(i))
      .forEach(({ id, groupId, pointAnnotations }) => {
        if (id) {
          lastNumberId = Math.max(textToNumber(getFirstHyphenString(id), 0), lastNumberId);
        }
        if (!isEmpty(pointAnnotations)) {
          pointAnnotations.filter(i => !isNil(i)).forEach(({ id: pointId }) => {
            if (pointId) {
              lastNumberPointId = Math.max(textToNumber(getFirstHyphenString(id), 0), lastNumberId);
            }
          });
        }
        if (groupId) {
          const groupIdNumber = textToNumber(getFirstHyphenString(groupId), 0);
          this.groupIds.push(groupIdNumber);
          lastNumberGroupId = Math.max(groupIdNumber, lastNumberGroupId);
        }
      });
    const lastNumbers = this.getLastNumbers(currentFile);
    lastNumbers.id = lastNumberId;
    lastNumbers.groupId = lastNumberGroupId;
    lastNumbers.pointId = lastNumberPointId;
  }

  public generateId(currentFile = this.currentFile): string {
    this.getLastNumbers(currentFile).id += 1;
    return `${this.getLastNumbers(currentFile).id}-${uuidv4()}`;
  }

  public generateGroupId(currentFile = this.currentFile): string {
    this.getLastNumbers(currentFile).groupId += 1;
    return `${this.getLastNumbers(currentFile).groupId}-${uuidv4()}`;
  }

  public generatePointId(currentFile = this.currentFile): string {
    this.getLastNumbers(currentFile).pointId += 1;
    return `${this.getLastNumbers(currentFile).pointId}-${uuidv4()}`;
  }

  public generateGroupIdByEmptyOrMaxOne(): string {
    const groupId = getNumberIdByEmptyOrMaxPlusOne(this.groupIds);
    return `${groupId}-${uuidv4()}`;
  }

  private getLastNumbers(currentFile = this.currentFile): LastNumbers {
    if (!this.lastNumbersMap.has(currentFile)) {
      this.lastNumbersMap.set(currentFile, { id: 0, groupId: 0, pointId: 0 });
    }
    return this.lastNumbersMap.get(currentFile) as LastNumbers;
  }
}

export const idGenerator = new IdGenerator();

export function getNumberIdByEmptyOrMaxPlusOne(numberIds: number[]): number {
  const numberIdMap = new Map<number, boolean>();
  numberIds.forEach(numberId => numberIdMap.set(numberId, true));
  return times(numberIds.length + 2).find(index => index !== 0 && !numberIdMap.has(index));
}
