import type * as PolygonClipping from 'polygon-clipping';
import { concat, defer, forkJoin, Observable } from 'rxjs';
import { map, tap, toArray } from 'rxjs/operators';
import type * as svgjs from 'svg.js';

export let LazySvgJs: Omit<typeof svgjs, 'default'>;
export let LazySvgJsLibrary: svgjs.Library;
export let LazyPolygonClipping: typeof PolygonClipping;

export function loadLazySvgJs(): Observable<void> {
  return forkJoin([
    concat(
      defer(() => import('svg.js')).pipe(
        tap(t => LazySvgJsLibrary = t.default),
        tap(t => LazySvgJs = t.default)
      ),
    ).pipe(toArray()),
    defer(() => import('polygon-clipping')).pipe(tap(t => LazyPolygonClipping = t.default))
  ]).pipe(map(() => undefined));
}
