import type { EdgeInfo } from '@aimmo/annotator-model';
import type {
  AnnotationAttribute,
  ChoiceRenderType,
  ColorSize,
  InputValidation,
  MinBoxSize,
  MinPolylineLength,
  MultiChoiceValidation,
  SizeValue
} from '@bluewhale/ngx-annotator/components/attributes/model';
import type { PointLabel } from '@bluewhale/ngx-annotator/tool/image/model';
import { CuboidMinSpec, CuboidSameSizeSpec } from './point-cloud-model';

export interface ChoiceByAnnotationType {
  [key: string]: AttributeChoice;
}

export interface AttributeChoice {
  name: string;
  value: string;
  description?: string;
  color?: string;
  semantic?: boolean;
  spec?: ValidationOption;
  groupName?: string;

  // for filter
  checked?: boolean;
  url?: string;

  // for class extension option
  creatable?: boolean;
  updatable?: boolean;
  removable?: boolean;
  visible?: boolean;
}

export interface ChoiceAnnotationAttribute extends AnnotationAttribute {
  choices: AttributeChoice[];
  choicesSourceType?: ChoicesSourceType;
  fileMetadataChoicesKey?: string;
  choiceRenderType?: ChoiceRenderType;
}

export interface MultiChoiceAnnotationAttribute extends ChoiceAnnotationAttribute {
  validation: MultiChoiceValidation;
}

export interface InputAnnotationAttribute extends AnnotationAttribute {
  validation: InputValidation;
}

export enum ValidationRuleType {
  anyPassNeeded = 'any_pass_needed',
  allPassNeeded = 'all_pass_needed'
}

export enum ChoicesSourceType {
  direct = 'direct',
  fileMetadata = 'file_metadata'
}

export interface PolygonPointsCountSpec {
  maxPoints?: number;
  minPoints?: number;
}

export interface Cuboid2dSpec {
  minFrontBoxSizes?: SizeValue[];
  minBackBoxSizes?: SizeValue[];
  minLeftBoxSizes?: SizeValue[];
  minRightBoxSizes?: SizeValue[];
}

export interface PointCloudCuboidSpec {
  cuboidSameSizes?: CuboidSameSizeSpec[];
  minCuboidSizes?: CuboidMinSpec[];
  minPointCount?: number;
}

export interface ValidationOption extends PolygonPointsCountSpec, PointCloudCuboidSpec, Cuboid2dSpec {
  minBoxSizes?: SizeValue[];
  maxBoxSizes?: SizeValue[];
  minBoxRatios?: SizeValue[];
  pointLabels?: PointLabel[];
  minLineLength?: MinPolylineLength;
  additionalOptions?: {
    [key: string]: any;
    colors?: ColorSize[];
    edges?: EdgeInfo[];
  };
  validationRuleType?: ValidationRuleType;
  guideImageUrl?: string;

  // @deprecated 임시 유효성 설정 때문에 추가됨
  minPolygonArea?: MinBoxSize;
}

