import type { Coordinate } from '@aimmo/annotator-model';

export interface EdgeRenderInfo {
  coordinates: Coordinate[];
  color: string;
  opacity: number;
  strokeWidth: number;
}

export enum TemplateType {
  image = 'image',
  point_cloud = 'point_cloud',
  point_cloud_segmentation = 'point_cloud_segmentation',
  object_tracking = 'object_tracking',
  frame_range = 'frame_range',
  dicom = 'dicom',
  text = 'text',
  lotte_qa_row = 'lotte_qa_row',
  segmentationMask = 'segmentation_mask'
}

// TODO enum property name camelCase로 할것
export enum AnnotatorEventType {
  pasteInstances = 'pasteInstances',
  AttributeSet = 'AttributeSet',
  ToolChange = 'ToolChange',
  ShowInvalidPixelImage = 'ShowInvalidPixelImage',
  AddOrRemoveAnnotations = 'AddOrRemoveAnnotations',
  fitToSize = 'fitToSize',
  DownloadSegmentation = 'DownloadSegmentation',
  downloadResource = 'downloadResource',
  errorLoadResource = 'errorLoadResource',
  errorLoadLightingImprovedImage = 'errorLoadLightingImprovedImage',
  instanceLoadCompleted = 'instanceLoadCompleted',
  loadResourceCompleted = 'loadResourceCompleted',
  visibleBackgroundItems = 'visibleBackgroundItems',
  userAction = 'userAction',
  reset = 'reset',
  invalidFullyOverlappedEntity = 'invalidFullyOverlappedEntity',
  notAvailableTextEntity = 'notAvailableTextEntity',
  attemptToComplete = 'attemptToComplete',
  removeExternalApiMessages = 'removeExternalApiMessages',
  searchAttributeSet = 'searchAttributeSet'
}

export enum ArrowType {
  Up = 'ArrowUp',
  Right = 'ArrowRight',
  Down = 'ArrowDown',
  Left = 'ArrowLeft'
}

export enum AnnotatorType {
  image = 'image',
  sequenceImage = 'sequenceImage',
  pointCloud = 'pointCloud',
  pointCloudSegmentation = 'pointCloudSegmentation',
  dicom = 'dicom',
  text = 'text',
  frameRange = 'frameRange',
  lotteQaRow = 'lotte_qa_row',
  segmentationMask = 'segmentation_mask'
}

export enum AnnotatedItemEvent {
  click = 'click',
  add = 'add',
  remove = 'remove',
  update = 'update',
  select = 'select',
  unselect = 'unselect',
  reordering = 'reordering',
  show = 'show',
  hide = 'hide',
  externalAdd = 'externalAdd',
  externalRemove = 'externalRemove',
  externalUpdate = 'externalUpdate',
  lock = 'lock',
  unlock = 'unlock'
}

export enum AreaIncludeRelationOption {
  parentClass = 'parentClass',
  childClass = 'childClass',
}

export enum FirstPointStyleOption {
  use = 'use',
  radius = 'radius',
  opacity = 'opacity',
  color = 'color',
}

export enum BboxGridOption {
  use = 'use',
  color = 'color',
  width = 'width',
  divideCount = 'divideCount',
  supportClasses = 'supportClasses'
}

export enum FrameSelectType {
  current,
  range,
  all
}

// TODO enum property name convention 정의 하면 좋을 것 같음
export enum ActionType {
  pair = 'pair',
  redo = 'redo',
  undo = 'undo',
  zoom = 'zoom',
  delete = 'delete',
  download = 'download',
  fitImage = 'fit-image',
  magicCrop = 'magic-crop',
  changeMode = 'change-mode',
  finishItem = 'finish-item',
  originImage = 'origin-image',
  lineDownload = 'line-download',
  changeContrast = 'change-contrast',
  changePointSize = 'change-point-size',
  changeSaturate = 'change-saturate',
  toggleApplyColorsToIntensityRange = 'toggle-apply-colors-to-intensity-range',
  changeIntensityHueRange = 'change-intensity-hue-range',
  changeIntensityRange = 'change-intensity-range',
  changeVelocityRange = 'change-velocity-range',
  changePointZRange = 'change-point-z-range',
  changeBrightness = 'change-brightness',
  parentGroupItems = 'parent-group-items',
  ungroupParentGroup = 'ungroup-parent-group',
  toggleSemanticPattern = 'toggle-semantic-pattern',
  toggleShowEmptyPixel = 'toggle-show-empty-pixel',
  segmentationGrayScaleDownload = 'segmentation-gray-scale-download',
  segmentationDownload = 'segmentation-download',
  toggleFillColorOpacity = 'toggle-fill-color-opacity',
  toggleAutoInheritInvisibleKeys = 'toggle-auto-inherit-invisible-keys',
  toggledMaintainText = 'toggledMaintainText',
  toggleVisibleBackgroundItems = 'toggleVisibleBackgroundItems',
  toggleInstanceFilter = 'toggleInstanceFilter',
  toggleBboxGrid = 'toggleBboxGrid',
  toggleInstanceColorByTrackId = 'toggleInstanceColorByTrackId',
  toggleInstanceColorByGroupId = 'toggleInstanceColorByGroupId',
  switchTrackIds = 'switchTrackIds',
  toggleShowLabel = 'toggleShowLabel'
}

export enum VisualizeAttributeKey {
  polySeg = 'origin_poly_seg',
  bbox = 'origin_2d_bbox',
  polygon = 'origin_polygon',
  polyline = 'origin_polyline',
  pointCloudCuboid = 'origin_3d_cuboid'
}

export enum ViewerActionType {
  fitSize,
  originSize,
  checkAreaSize
}

export enum ViewerActionPhase {
  pressed,
  released
}

export enum MainViewActionType {
  changeResourceViewerSize,
  checkAreaSize
}

export interface RITMSegmentationEmitParams {
  coordinate: Coordinate;
  positive: boolean;
}

export interface RITMSegmentationRequestParams {
  point: Coordinate;
  positive: boolean;
  prevMaskId?: string;
  threshold?: number; // default 0.5
}

export interface RITMPolygonSegmentationRequestParams {
  point: Coordinate;
  pointDensity?: number;
  positive: boolean;
  prevMaskId?: string;
  threshold?: number; // default 0.5
}

export interface RITMGuidancePointConfig {
  positive: boolean;
  coordinate: Coordinate;
}
