import { DEFAULT_LABEL_FONT_SIZE } from '../constants';
import { TemplateType } from '../enums';
import { AnnotatedItem } from '../interfaces';
import { TextAnswerItem, TextItem, TextTemplateOption } from '../text-model';
import { Annotator } from './annotator';

// @dynamic
export class TextAnnotator extends Annotator {
  private static instance: TextAnnotator;

  private constructor() {
    super();
  }

  public get code(): string {
    return TemplateType.text;
  }

  public static getInstance(): TextAnnotator {
    if (!this.instance) {
      this.instance = new TextAnnotator();
    }
    return this.instance;
  }

  public getDefaultTemplateOption(): TextTemplateOption {
    return {
      isShowSideArea: true,
      useAnnotationDragdropOrdering: false,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 100,
      useEntity: false,
      instanceCreatable: true,
      instanceDeletable: true,
      matchAllEntity: false,
      entityLabelFontSize: DEFAULT_LABEL_FONT_SIZE,
      fullyOverlappedMode: false
    };
  }

  public override convertAnnotations(annotations: AnnotatedItem[]): AnnotatedItem[] {
    return annotations.map(annotatedItem => {
      annotatedItem.visible = true;
      return annotatedItem;
    });
  }

  public getAnnotatedAnswerItems(items: TextItem[]): TextAnswerItem[] {
    return items.map(({ id, type, attributes, start, end, text }) =>
      ({ id, type, attributes, start, end, text } as TextAnswerItem));
  }

  public toString(): string {
    return 'TextAnnotator[text]';
  }
}
