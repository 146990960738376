import type { Coordinate } from '@aimmo/annotator-model';
import type { AnnotationConfig } from '@aimmo/eimmo/models';
import type {
  AnnotationType,
  DisplayLabelOption,
  PointCloudChannel,
  PointCloudConvexHullBound,
  PointCloudGeometry,
  PointCloudPosition,
  PointCloudRotation,
  RvDataType,
  RVSettingsParam,
  StudioAnnotation,
  SyncData,
  ThreeBboxParam,
  UpdatedItemsParam
} from '@bluewhale/ngx-annotator/model';
import type { HexColor } from '@bluewhale/ngx-annotator/tool/brush/model';
import type { CalibrationOption } from 'app/core/services/api/studio-tools/studio-tools-api.interfaces';
import type { EventInstance } from 'app/pages/annotator-tool/services/annotator-tool-sequence.model';
import type { ToolPreference } from 'app/pages/project/settings/tool-preference/tool-preference.model';
import type { PhotoContext, ReferenceGt } from 'app/shared/models/studio.model';
import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import type { EulerOrder, OrthographicCamera, Raycaster, Vector3 } from 'three';
import { KeyboardActionEvent } from '../../../../service/src/common-keyboard-action.interfaces';
import {
  PointCloudSelectPolyMovePointParam,
  PointCloudSelectPolyPointParam,
  PointCloudUpdateInstanceParam,
  PointCloudUpdatePolyInstanceParam
} from '../../src/components/point-cloud-edit-view/point-cloud-edit-view.component';
import type { Comment } from '../../src/core/comment';
import type { PathLine } from '../../src/core/path-line';
import type { PathLineSelector } from '../../src/core/path-line-selector';
import type { ThreeBbox } from '../../src/core/three-bbox';
import type { ThreePoint } from '../../src/core/three-point';
import type { ThreePolygon } from '../../src/core/three-polygon';

export type PointCloudItemType = ThreeBbox | ThreePolygon | PathLine;
export type PointCloudItemData = ThreeBboxData | ThreePolyBaseData;
export type PointCloudItemPostMessageRequestData = ThreeBboxData | ThreePolyPostMessageRequestData;

export interface ThreePolyBaseData {
  id: string;
  points: ThreePoint[];
  color: HexColor;
  groupId: string;
  trackId: string;
  tempHide: boolean;
  selected: boolean;
  visible: boolean;
  resizable: boolean;
  movable: boolean;
  fontSize: number;
  locked?: boolean;
  geometry: PointCloudGeometry;
  type?: AnnotationType;
  displayLabelOptions?: DisplayLabelOption[];
}

export interface ThreePolyPostMessageRequestData extends Omit<ThreePolyBaseData, 'points'> {
  points: ThreePointData[];
  camera?: OrthographicCamera;
}

export interface ThreePointData {
  id: string;
  position: PointCloudPosition;
  color: HexColor;
  selected: boolean;
  displayLabelOptions?: DisplayLabelOption[];
}

export interface ThreePolyBaseParams {
  id: string;
  points: ThreePointParams[];
  camera: OrthographicCamera;
  raycaster?: Raycaster;
  color?: string;
  selected?: boolean;
  visible?: boolean;
  resizable?: boolean;
  movable?: boolean;
  trackId?: string;
  groupId?: string;
  tempHide?: boolean;
  locked?: boolean;
  displayLabelOptions?: DisplayLabelOption[];
  fontSize?: number;
  emitEventFunc?: (type: PointCloudEventType, data: PointCloudEventData) => void;
}

export interface ThreePointParams {
  id: string;
  position: PointCloudPosition;
  color?: string;
  selected?: boolean;
  displayLabelOptions?: DisplayLabelOption[];
  locked?: boolean;
}

export interface ThreeMeshPointParams extends ThreePointParams {
  threePoint: ThreePoint;
}

export interface PathLineSelectorParams {
  camera: OrthographicCamera;
  raycaster: Raycaster;
  color: HexColor;
  isCreating: boolean;
  id?: string;
  type?: AnnotationType;
  points?: ThreePointParams[];
  isPolygon?: boolean;
}

export interface TrackIdSelectorInfo {
  trackId: string;
  invalid: boolean;
  selected: boolean;
}

export interface SelectTrackIdParam {
  trackId: string;
  multi: boolean;
}

export type PointCloudResourceMessageEvent =
  PointCloudResourceMessageEventSyncData
  | PointCloudResourceMessageEventSyncReferenceGts
  | PointCloudResourceMessageEventLoaded
  | PointCloudResourceMessageEventUpdatedInstances
  | PointCloudResourceMessageEventChangeViewType
  | PointCloudResourceMessageEventSelectedInstances
  | PointCloudResourceMessageEventHideReferenceGts
  | PointCloudResourceMessageEventCloseResourceWindow
  | PointCloudResourceMessageEventUpdatePointer
  | PointCloudResourceMessageEventSelectedInstance
  | PointCloudResourceMessageEventSyncModifiable
  | PointCloudResourceMessageEventUpdateRvInstance
  | PointCloudResourceMessageEventUpdatedPointerPosition
  | PointCloudResourceMessageEventToggledSwitchingCameraMode
  | PointCloudResourceMessageEventChangeIndex
  | PointCloudResourceMessageEventShowBackGroundItems;

interface PointCloudResourceMessageEventSyncData {
  type: ResourcePostMessageEventType.syncData;
  data: PointCloudResourceSyncData;
}

interface PointCloudResourceMessageEventUpdateRvInstance {
  type: ResourcePostMessageEventType.updateRvInstanceOnMovement;
  data: UpdatedItemsParam;
}

interface PointCloudResourceMessageEventToggledSwitchingCameraMode {
  type: ResourcePostMessageEventType.switchingCameraMode;
  data: boolean;
}

interface PointCloudResourceMessageEventUpdatedPointerPosition {
  type: ResourcePostMessageEventType.calibratedUpdatedPosition;
  data: PointCloudPosition;
}

interface PointCloudResourceMessageEventSelectedInstance {
  type: ResourcePostMessageEventType.selectedInstance;
  data: StudioAnnotation;
}

interface PointCloudResourceMessageEventSyncModifiable {
  type: ResourcePostMessageEventType.syncModifiable;
  data: PointCloudResourceEventSyncModifiable;
}

interface PointCloudResourceMessageEventChangeIndex {
  type: ResourcePostMessageEventType.changeIndex;
  data: number;
}

interface PointCloudResourceMessageEventCloseResourceWindow {
  type: ResourcePostMessageEventType.closeWindow;
  data: SyncData;
}

interface PointCloudResourceMessageEventSyncReferenceGts {
  type: ResourcePostMessageEventType.syncReferenceGts;
  data: PointCloudResourceSyncReferenceGts;
}

interface PointCloudResourceMessageEventUpdatePointer {
  type: ResourcePostMessageEventType.updatedPointer;
  data: PointCloudResourceEventPointer;
}

interface PointCloudResourceMessageEventLoaded {
  type: ResourcePostMessageEventType.loaded;
  data: RvDataType;
}

interface PointCloudResourceMessageEventUpdatedInstances {
  type: ResourcePostMessageEventType.updatedInstances;
  data: PointCloudResourceEventInstance;
}

interface PointCloudResourceMessageEventChangeViewType {
  type: ResourcePostMessageEventType.changeViewType;
  data: PointCloudResourceEventChangeViewType;
}

interface PointCloudResourceMessageEventSelectedInstances {
  type: ResourcePostMessageEventType.selectedInstances;
  data: string[];
}

interface PointCloudResourceMessageEventHideReferenceGts {
  type: ResourcePostMessageEventType.hideReferenceGts;
  data: boolean;
}

interface PointCloudResourceMessageEventShowBackGroundItems {
  type: ResourcePostMessageEventType.syncShowBackgroundItems;
  data: boolean;
}

export interface PointCloudResourceEventInstance extends EventInstance {
  currentInstances: StudioAnnotation[];
}

export interface PointCloudResourceEventPointer {
  exposePointInstance: StudioAnnotation;
}

export interface PointCloudResourceEventSyncModifiable {
  isModifiable: boolean;
}

export interface PointCloudResourceEventChangeViewType {
  rvType: RvDataType;
  viewType: ResourceViewType;
}

export interface PointCloudResourceSyncData {
  rvType: RvDataType;
  isSensorFusion: boolean;
  calibrationOptions: CalibrationOption[];
  loadedInstances: StudioAnnotation[];
  isModifiable: boolean;
  fileUrl: string;
  photoContexts: PhotoContext[];
  referenceGts: ReferenceGt[];
  currentInstances: StudioAnnotation[];
  rvCurrentIndex: number;
  useCanvasApi: boolean;
  rvSettings: RVSettingsParam;
  showBackgroundItems: boolean;
  hideReferenceGts?: boolean;
  exposePointInstance?: StudioAnnotation;
  switchingCameraMode?: boolean;
  channel?: PointCloudChannel;
  isPointCloudSegmentation?: boolean;
  isIncludePointCloudCuboid?: boolean;
  isSingleImage?: boolean;
  isSingleImageRvSyncProject?: boolean;
  reverseCalibrationOptions?: CalibrationOption[];
  rvSyncProjectToolPreference?: ToolPreference;
  rvSyncProjectAnnotationConfigs?: AnnotationConfig[];
}

export interface PointCloudResourceSyncReferenceGts {
  referenceGts: ReferenceGt[];
}

export enum ResourcePostMessageEventType {
  loaded,
  syncData,
  syncReferenceGts,
  updatedInstances,
  selectedInstances,
  changeViewType,
  hideReferenceGts,
  changeIndex,
  closeWindow,
  updatedPointer,
  calibratedUpdatedPosition,
  switchingCameraMode,
  selectedInstance,
  syncModifiable,
  syncShowBackgroundItems,
  updateRvInstanceOnMovement
}

export type PointCloudMiniViewMessageEvent =
  PointCloudMiniViewMessageEventLoaded
  | PointCloudMiniViewMessageEventSyncData
  | PointCloudMiniViewMessageEventSelectedInstance
  | PointCloudMiniViewMessageEventCloseResourceWindow
  | PointCloudMiniViewMessageEventUpdatedInstance
  | PointCloudMiniViewMessageEventUpdatedPolyInstance
  | PointCloudMiniViewMessageEventChangeViewMode
  | PointCloudMiniViewMessageEventUpdatedSelectInstanceEvent
  | PointCloudMiniViewMessageEventSelectedPoint
  | PointCloudMiniViewMessageEventMouseDownFrom
  | PointCloudMiniViewMessageEventMovePoint
  | PointCloudMiniViewMessageEventKeyboardEvent
  | PointCloudMiniViewMessageEventChangeFirstPoint
  | PointCloudMiniViewMessageEventEnterPoint;

interface PointCloudMiniViewMessageEventLoaded {
  type: MiniViewPostMessageEventType.loaded;
  data: any;
}

interface PointCloudMiniViewMessageEventSyncData {
  type: MiniViewPostMessageEventType.syncData;
  data: PointCloudMiniViewSyncData;
}

interface PointCloudMiniViewMessageEventSelectedInstance {
  type: MiniViewPostMessageEventType.selectedInstance;
  data: PointCloudItemPostMessageRequestData;
}

interface PointCloudMiniViewMessageEventSelectedPoint {
  type: MiniViewPostMessageEventType.selectedPoint;
  data: PointCloudSelectPolyPointParam;
}

interface PointCloudMiniViewMessageEventUpdatedInstance {
  type: MiniViewPostMessageEventType.updatedInstance;
  data: PointCloudUpdateInstanceParam;
}

interface PointCloudMiniViewMessageEventUpdatedPolyInstance {
  type: MiniViewPostMessageEventType.updatedPolyInstance;
  data: PointCloudUpdatePolyInstanceParam;
}

interface PointCloudMiniViewMessageEventUpdatedSelectInstanceEvent {
  type: MiniViewPostMessageEventType.updatedSelectInstanceEvent;
  data: PointCloudEditViewSelectItemParam;
}

interface PointCloudMiniViewMessageEventChangeViewMode {
  type: MiniViewPostMessageEventType.changeViewMode;
  data: ViewMode;
}

interface PointCloudMiniViewMessageEventMouseDownFrom {
  type: MiniViewPostMessageEventType.mouseDownFrom;
  data: AnnotationType;
}

interface PointCloudMiniViewMessageEventCloseResourceWindow {
  type: MiniViewPostMessageEventType.closeWindow;
  data: any;
}

interface PointCloudMiniViewMessageEventMovePoint {
  type: MiniViewPostMessageEventType.movePoint;
  data: PointCloudSelectPolyMovePointParam;
}

interface PointCloudMiniViewMessageEventKeyboardEvent {
  type: MiniViewPostMessageEventType.keyboardEvent;
  data: KeyboardActionEvent;
}

interface PointCloudMiniViewMessageEventChangeFirstPoint {
  type: MiniViewPostMessageEventType.changeFirstPoint;
  data: any;
}

interface PointCloudMiniViewMessageEventEnterPoint {
  type: MiniViewPostMessageEventType.enterPoint | MiniViewPostMessageEventType.leavePoint;
  data: { id: string };
}

export interface PointCloudMiniViewSyncData {
  selectedItemData: PointCloudItemPostMessageRequestData;
  channelVisibility: boolean[];
  colorByChannel: boolean;
  colorByIntensity: boolean;
  colorByVelocity: boolean;
  convexHullBound: PointCloudConvexHullBound;
  isGroundMode: boolean;
  isModifiable: boolean;
  isMultiChannel: boolean;
  pointCloudData: MultiplePointCloudData | PointCloudData;
  updateSelectInstanceEvent$?: Observable<PointCloudEditViewSelectItemParam>;
  currentViewMode: ViewMode;
  renderOption: RenderOption;
}

export enum MiniViewPostMessageEventType {
  loaded = 'mini-loaded',
  syncData = 'mini-syncData',
  updatedInstance = 'mini-updatedInstance',
  updatedPolyInstance = 'mini-updatedPolyInstance',
  closeWindow = 'mini-closeWindow',
  selectedInstance = 'mini-selectedInstance',
  changeViewMode = 'mini-changeViewMode',
  updatedSelectInstanceEvent = 'mini-updatedSelectInstanceEvent',
  selectedPoint = 'mini-selectedPoint',
  mouseDownFrom = 'mini-mouseDownFro',
  movePoint = 'mini-movePoint',
  keyboardEvent = 'mini-keyboardEvent',
  changeFirstPoint = 'mini-changeFirstPoint',
  enterPoint = 'enterPoint',
  leavePoint = 'leavePoint'
}

export interface TaskPoint {
  url: string;
}

export enum RadialType {
  circle = 'circle',
  box = 'box',
  line = 'line',
  sector = 'sector'
}

export const PC_EULER_ORDER: EulerOrder = 'YXZ';
export const DEFAULT_COLOR = '#FFD800';

export interface ResourceViewTypes {
  [RvDataType.image]?: ResourceViewType;
  [RvDataType.radar]?: ResourceViewType;
  [RvDataType.lidar]?: ResourceViewType;
}

export interface ReferenceViewers {
  [RvDataType.image]?: WindowProxy | undefined;
  [RvDataType.radar]?: WindowProxy | undefined;
  [RvDataType.lidar]?: WindowProxy | undefined;
}

export enum ResourceViewType {
  defaultView,
  twoSplitView,
  newWindowView,
  multichannelView,
}

export enum ArrowType {
  Up = 'ArrowUp',
  Right = 'ArrowRight',
  Down = 'ArrowDown',
  Left = 'ArrowLeft'
}

export interface VectorWithIndex {
  x: number;
  y: number;
  z: number;
  index: number;
}

export interface RecentSelectCuboidShapeInfo {
  geometry: PointCloudGeometry;
  rotation: PointCloudRotation;
}

export interface ThreeBboxData {
  id: string;
  groupId: string;
  trackId: string;
  color: string;
  geometry: PointCloudGeometry;
  position: PointCloudPosition;
  rotation: PointCloudRotation;

  selected: boolean;
  visible: boolean;
  movable: boolean;
  resizable: boolean;
  tempHide: boolean;
  displayLabel: string;
  displayLabelOptions: DisplayLabelOption[];
  fontSize: number;
  distance?: number;
  type?: AnnotationType;
  camera?: OrthographicCamera;
}

type ThreeBboxParamPickList = 'geometry' | 'position' | 'rotation' | 'unit';

export interface TrackingGuideBboxData extends Pick<ThreeBboxParam, ThreeBboxParamPickList> {
  camera: OrthographicCamera;
}

export interface CuboidGeometryInfo {
  position: PointCloudPosition;
  geometry: PointCloudGeometry;
  rotation: PointCloudRotation;
  pointCount?: number;
  distance?: number;
}

export interface PointCloudResponse {
  position: Float32Array;
  originColors?: Float32Array;
  filteredPosition?: Float32Array;
  originColorsByIntensity?: Float32Array;
  intensities?: Float32Array;
  originColorsByVelocity?: Float32Array;
  velocities?: Float32Array;
  channels?: Uint32Array;
  originColorsByChannel?: Float32Array;
  channel?: PointCloudChannel;
}

export interface MultiplePointCloudResponse {
  channels: Uint32Array;
  positions: Float32Array[];
  filteredPositions?: Float32Array[];
  originColors?: Float32Array[];
  originColorsByIntensity?: Float32Array[];
  intensities?: Float32Array[];
  originColorsByVelocity?: Float32Array[];
  velocities?: Float32Array[];
  originColorsByChannel?: Float32Array[];
  channel?: PointCloudChannel;
}

export interface PointCloudColorIntensities {
  filteredPosition: Float32Array;
  intensities: Float32Array;
}

export interface PointCloudOpacityPosition {
  opacityPosition: Float32Array;
}

export interface PointCloudColorIntensitiesUseOpacity {
  opacityPosition: Float32Array;
  intensities: Float32Array;
}

export interface PointCloudColorVelocities {
  velocities: Float32Array;
}

export interface PointCloudColorChannels {
  channelColors: Float32Array[];
}

export interface PointCloudMultipleOriginColors {
  originColors: Float32Array[];
}

export interface PointCloudOriginColors {
  originColors: Float32Array;
}

export interface PointCloudColorIntensityArray {
  positionsByIntensities: Float32Array[];
  intensityColors: Float32Array[];
  totalOpacityPositions?: Float32Array;
  totalIntensityColors?: Float32Array;
}

export interface PointCloudColorVelocityArray {
  velocityColors: Float32Array[];
}

export interface PointCloudData {
  position: Float32Array;
  colors?: Float32Array;
  originColors?: Float32Array;
  filteredPosition?: Float32Array;
  originColorsByIntensity?: Float32Array;
  intensities?: Float32Array;
  originColorsByVelocity?: Float32Array;
  velocities?: Float32Array;
  channels?: Uint32Array;
  originColorsByChannel?: Float32Array;
  channel?: PointCloudChannel;
  opacityPosition?: Float32Array;
}

export interface MultiplePointCloudData {
  channels: Uint32Array;
  positions: Float32Array[];
  colors?: Float32Array[];
  filteredPositions?: Float32Array[];
  originColors?: Float32Array[];
  originColorsByIntensity?: Float32Array[];
  intensities?: Float32Array[];
  originColorsByVelocity?: Float32Array[];
  velocities?: Float32Array[];
  originColorsByChannel?: Float32Array[];
  channel?: PointCloudChannel;
}

export enum ViewMode {
  top = 'top',
  front = 'front',
  rear = 'rear',
  leftSide = 'leftSide',
  rightSide = 'rightSide',
}

export enum PointCloudEventType {
  addItem = 'addItem',
  addPathLineItem = 'addPathLineItem',
  addPolygonItem = 'addPolygonItem',
  clickItem = 'clickItem',
  clickPathLine = 'clickPathLine',
  clickPoint = 'clickPoint',
  createPointAdd = 'createPointAdd',
  pointAdd = 'pointAdd',
  pointRemove = 'pointRemove',
  cancelCreating = 'cancelCreating',
  mouseDownInstancePoint = 'mouseDownInstancePoint',
  updatePolygon = 'updatePolygon',
  rightClickInstancePoint = 'rightClickInstancePoint',
  updateColor = 'updateColor',
  updateCubePointsColor = 'updateCubePointsColor',
  updateDistance = 'updateDistance',
  updateGeometry = 'updateGeometry',
  changeCameraZoomValue = 'changeCameraZoomValue',
  enterInstance = 'enterInstance',
  leaveInstance = 'leaveInstance',
  startCreateComment = 'startCreateComment',
  focusComment = 'focusComment',
  moveComment = 'moveComment',
  enterPoint = 'enterPoint',
  leavePoint = 'leavePoint',
}

export enum CameraFocusType {
  topView = 'topView',
  quarterView = 'quarterView',
  leftSideView = 'leftSideView'
}

export interface UpdateColorParam {
  currentPointCloudData: PointCloudData;
  dataForColors: DataForColor[];
}

export interface UpdateCubePointsColorParam {
  currentPointCloudData: MultiplePointCloudData;
  dataForColors: DataForColor[];
}

export interface PointCloudEventData {
  id?: string;
  targetCube?: ThreeBbox;
  targetLine?: PathLine;
  targetPolygon?: ThreePolygon;
  targetPoint?: ThreePoint;
  targetSelector?: PathLineSelector;
  multi?: boolean;
  dataForColors?: DataForColor[];
  position?: PointCloudPosition;
  mousePosition?: Coordinate;
  dataForDistance?: DataForDistance[];
  frameIndex?: number;
  zoomValue?: number;
  targetComment?: Comment;
}

export interface PointCloudEvent {
  type: PointCloudEventType;
  data: PointCloudEventData;
}

export interface PointCloudEditViewSelectItemParam {
  onlyColor?: boolean;
  needFocus?: boolean;
}

export enum PointCloudMiniViewSceneEventType {
  updatePointCount = 'updatePointCount',
  updateCubeFromMouse = 'updateCubeFromMouse',
  updatePolyItemFromMouse = 'updatePolyItemFromMouse',
  enterInstance = 'enterInstance',
  leaveInstance = 'leaveInstance',
  enterPoint = 'enterPoint',
  leavePoint = 'leavePoint',
  movePoint = 'movePoint',
  rightClickPoint = 'rightClickPoint',
  hoverControl = 'hoverControl',
  draggingInstance = 'draggingInstance',
  endDraggingInstance = 'endDraggingInstance',
}

export interface PointCloudEditSceneEvent {
  type: PointCloudMiniViewSceneEventType;
  data: Partial<PointCloudEditSceneEventData>;
}

export interface PointCloudEditSceneEventData {
  type?: AnnotationType;
  id?: string;
  position?: PointCloudPosition;
  geometry?: PointCloudGeometry;
  pointCount?: number;
  onlySync?: boolean;
  index?: number;
}

export enum SensorModelType {
  'aimmo' = 'aimmo',
  'ferrari' = 'ferrari'
}

export interface CarModelOption {
  degree: number;
  xPosition: number;
  yPosition: number;
  zPosition: number;
  visible: boolean;
  rotation?: PointCloudPosition;
  type: SensorModelType | undefined;
}

export interface GroundOption {
  radius: number;
  visible: boolean;
  yPosition: number;
  color: string;
}

export interface CameraOption {
  position: [number, number, number];
  zoom: number;
  halfPolarAngle?: boolean;
  rotateStraightAngle?: boolean;
}

export type ColorArray = [number, number, number];

export interface RenderOption {
  unit: number;
  pointColor: string;
  radialDistances?: RadialDistanceOption[];
  customCenter?: CustomCenterOption;
  carModel: CarModelOption;
  camera: Partial<CameraOption>;
  ground: GroundOption;
  saveTrackIdsInfo: boolean;
  sameSizeErrorRange: number;
  controlVisible: boolean;
  enablePitch: boolean;
  enableRoll: boolean;
  displayEgoVehicleOnMinimap?: boolean | null;
  degreeRange: number;
  createModeByRecentSelectCuboidShape: boolean;

  manualBoundary?: ManualBoundaryOption;
}

export interface ManualBoundaryOption {
  enabled: boolean;
  maxY: number;
  minY: number;
}

export interface RadialDistanceOption {
  degree: number;
  type: RadialType;
  color: string;
  radius?: number;
  width?: number;
  height?: number;
  position?: PointCloudPosition;
  positions?: PointCloudPosition[];
}

export interface CustomCenterOption {
  activatedClassKey: string; // classification attribute class key
  positionKey: string;  // center position key
  headingPitchKey?: string; // device heading pitch key
  headingRollKey?: string; // device heading roll key
  headingYawKey?: string; // device heading yaw key
}

export interface PointsByItem {
  targetId: string;
  points: VectorWithIndex[];
  pointCount: number;
}

export interface DataForColor {
  targetId: string;
  boxMinMax: { min: Vector3; max: Vector3; };
  inverseMatrix: number[];
  colors: number[];
}

export interface DataForDistance {
  id: string;
  distance: number;
}

export interface UpdateColorResponse {
  colors: Float32Array;
  frameIndex: number;
  pointsByItems: PointsByItem[];
  filteredPosition?: Float32Array;
}

export interface UpdateCubePointsColorResponse {
  frameIndex: number;
  pointsByItems: PointsByItem[];
}

export interface UpdateOriginColorResponse {
  originColors: Float32Array;
  originColorsByIntensity?: Float32Array;
  originColorsByVelocity?: Float32Array;
}

export enum GeometryType {
  height,
  width,
  depth,
}

export function selectViewModeByKeyCode(code: string): ViewMode {
  switch (code) {
    case 'F1':
      return ViewMode.rear;
    case 'F2':
      return ViewMode.top;
    case 'F3':
      return ViewMode.leftSide;
  }
  return undefined;
}

export function selectCameraViewTypeByKeyCode(keyCode: string): CameraFocusType {
  switch (keyCode) {
    case 'Digit8':
      return CameraFocusType.leftSideView;
    case 'Digit0':
      return CameraFocusType.topView;
    case 'Digit9':
    default:
      return CameraFocusType.quarterView;
  }
}

export function selectViewModeByKeyCodeForSegmentation(keyCode: string): ViewMode {
  switch (keyCode) {
    case 'F2':
      return ViewMode.top;
    case 'F3':
      return ViewMode.rightSide;
    case 'F4':
      return ViewMode.leftSide;
    case 'F5':
      return ViewMode.front;
    case 'F6':
      return ViewMode.rear;
  }
  return undefined;
}

export interface CustomCenter {
  position: PointCloudPosition;
  pitch: number;
  roll: number;
  yaw: number;
}

export function isMultiplePointCloudData(pointCloudData: MultiplePointCloudData | PointCloudData): pointCloudData is MultiplePointCloudData {
  return !isEmpty((pointCloudData as MultiplePointCloudData)?.channels);
}

export function flattenFloat32Array(chunks: Float32Array[]): Float32Array {
  const length = chunks.reduce((acc, elem) => acc + elem.length, 0);
  const result = new Float32Array(length);

  let currentFrame = 0;
  chunks.forEach((chunk) => {
    result.set(chunk, currentFrame);
    currentFrame += chunk.length;
  });
  return result;
}

export function isSupportGroundModeInViewMode(viewMode: ViewMode): boolean {
  return [ViewMode.rear, ViewMode.leftSide].includes(viewMode);
}
