import { Coordinate, DataType } from '@aimmo/annotator-model';
import { ItemType, KeyPoint, ModeType, PointLabel } from '@bluewhale/ngx-annotator/tool/image/model';
import { convertDataTypeToItemType, convertItemTypeToDataType } from '@bluewhale/ngx-annotator/util/image';
import { cloneDeep, first, intersection, isEmpty, isNil, omitBy } from 'lodash-es';
import { VisualizeAttributeKeyToItemType } from './constants';
import { AnnotatorType, MainViewActionType, ViewerActionType, VisualizeAttributeKey } from './enums';
import { AnnotationType, StudioAnnotation } from './interfaces';
import { PointCloudPosition, PointCloudRotation } from './point-cloud-model';

export function convertModeTypeToItemType(modeType: ModeType): ItemType {
  switch (modeType) {
    case ModeType.extremeBbox:
    case ModeType.bbox:
      return ItemType.Bbox;
    case ModeType.obboxV2:
      return ItemType.OrientedBoxV2;
    case ModeType.RITM:
    case ModeType.RITM_N:
    case ModeType.semanticPolygon:
      return ItemType.SemanticPolygon;
  }
  return undefined;
}

export function convertModeTypeToAnnotationType(modeType?: ModeType): AnnotationType | undefined {
  switch (modeType) {
    case ModeType.keypoint:
      return AnnotationType.keypoint;
    case ModeType.hdOdKeypoint:
      return AnnotationType.keypointOD;
    case ModeType.extremeBbox:
    case ModeType.cornerBbox:
    case ModeType.bbox:
      return AnnotationType.bbox;
    case ModeType.obboxV2:
      return AnnotationType.obboxV2;
    case ModeType.polygon:
      return AnnotationType.polygon;
    case ModeType.polygon3d:
      return AnnotationType.polygon3d;
    case ModeType.cuboid:
      return AnnotationType.cuboid2d;
    case ModeType.flatCuboid:
      return AnnotationType.flatCuboid;
    case ModeType.polyline:
      return AnnotationType.polyline;
    case ModeType.polylinePoint:
      return AnnotationType.polylinePoint;
    case ModeType.semanticPolygon:
      return AnnotationType.polySeg;
    case ModeType.paint:
      return AnnotationType.bitmap;
  }
  return undefined;
}

// 필요시에 명칭 추가하여서 사용하면 됨
export function convertItemTypeToAnnotationType(itemType: ItemType): AnnotationType | undefined {
  return convertDataTypeToAnnotationType(convertItemTypeToDataType(itemType));
}

export function convertAnnotationTypeToItemType(annotationType: AnnotationType, throwErrorOff = false): ItemType | undefined {
  return convertDataTypeToItemType(convertAnnotationTypeToDataType(annotationType), throwErrorOff);
}

export function convertAnnotationTypeToModeType(annotationType: AnnotationType): ModeType | undefined {
  switch (annotationType) {
    case AnnotationType.keypoint:
      return ModeType.keypoint;
    case AnnotationType.keypointOD:
      return ModeType.hdOdKeypoint;
    case AnnotationType.bbox:
      return ModeType.bbox;
    case AnnotationType.obboxV2:
      return ModeType.obboxV2;
    case AnnotationType.polygon:
      return ModeType.polygon;
    case AnnotationType.polygon3d:
      return ModeType.polygon3d;
    case AnnotationType.cuboid2d:
      return ModeType.cuboid;
    case AnnotationType.flatCuboid:
      return ModeType.flatCuboid;
    case AnnotationType.polyline:
      return ModeType.polyline;
    case AnnotationType.polySeg:
      return ModeType.semanticPolygon;
    case AnnotationType.polylinePoint:
      return ModeType.polylinePoint;
  }
  return undefined;
}

export function convertDataTypeToAnnotationType(dataType: DataType): AnnotationType | undefined {
  switch (dataType) {
    case DataType.Keypoint:
      return AnnotationType.keypoint;
    case DataType.KeypointOD:
      return AnnotationType.keypointOD;
    case DataType.Bbox:
      return AnnotationType.bbox;
    case DataType.ObboxV2:
      return AnnotationType.obboxV2;
    case DataType.Cuboid:
      return AnnotationType.cuboid2d;
    case DataType.FlatCuboid:
      return AnnotationType.flatCuboid;
    case DataType.Tbox:
      return AnnotationType.cuboid;
    case DataType.Polygon:
      return AnnotationType.polygon;
    case DataType.Polygon3d:
      return AnnotationType.polygon3d;
    case DataType.Polyline:
      return AnnotationType.polyline;
    case DataType.PolylinePoint:
      return AnnotationType.polylinePoint;
    case DataType.PolylinePoint3d:
      return AnnotationType.polylinePoint3d;
    case DataType.SemanticPolygon:
      return AnnotationType.polySeg;
    case DataType.Pair:
      return AnnotationType.pair;
    case DataType.FrameRange:
      return AnnotationType.frameRange;
    case DataType.Entity:
      return AnnotationType.entity;
    case DataType.LotteQaRow:
      return AnnotationType.lotteQaRow;
    case DataType.SegmentationMask:
      return AnnotationType.segmentationMask;
    case DataType.classification:
      return AnnotationType.classification;
    case DataType.Lasso:
      return AnnotationType.lasso;
  }
  return undefined;
}

export function convertAnnotationTypeToDataType(annotationType: AnnotationType): DataType | undefined {
  switch (annotationType) {
    case AnnotationType.bbox:
      return DataType.Bbox;
    case AnnotationType.obboxV2:
      return DataType.ObboxV2;
    case AnnotationType.cuboid2d:
      return DataType.Cuboid;
    case AnnotationType.cuboid:
      return DataType.Tbox;
    case AnnotationType.flatCuboid:
      return DataType.FlatCuboid;
    //  obbox는 더 이상 지원하지 않음 데이터 로드시 센트리 오류가 발생하지 않도록만 처리
    case AnnotationType.obbox:
    case AnnotationType.polygon:
      return DataType.Polygon;
    case AnnotationType.polygon3d:
      return DataType.Polygon3d;
    case AnnotationType.polyline:
      return DataType.Polyline;
    case AnnotationType.polylinePoint:
      return DataType.PolylinePoint;
    case AnnotationType.polylinePoint3d:
      return DataType.PolylinePoint3d;
    case AnnotationType.keypoint:
      return DataType.Keypoint;
    case AnnotationType.keypointOD:
      return DataType.KeypointOD;
    case AnnotationType.polySeg:
      return DataType.SemanticPolygon;
    case AnnotationType.pair:
      return DataType.Pair;
    case AnnotationType.frameRange:
      return DataType.FrameRange;
    case AnnotationType.entity:
      return DataType.Entity;
    case AnnotationType.lotteQaRow:
      return DataType.LotteQaRow;
    case AnnotationType.segmentationMask:
      return DataType.SegmentationMask;
    case AnnotationType.classification:
      return DataType.classification;
    case AnnotationType.lasso:
      return DataType.Lasso;
  }
  return undefined;
}

export function convertVisualizedAttributeKeyToItemType(type: VisualizeAttributeKey): ItemType {
  if (!VisualizeAttributeKeyToItemType.hasOwnProperty(type)) {
    throw Error(`${type} 존재하지 않는 타입입니다.`);
  }
  return VisualizeAttributeKeyToItemType[type] as ItemType;
}

export function convertViewerActionTypeFromKeyCode(keyCode: string): ViewerActionType | undefined {
  switch (keyCode) {
    case 'Digit0':
      return ViewerActionType.originSize;
    case 'Digit9':
      return ViewerActionType.fitSize;
    case 'KeyZ':
      return ViewerActionType.checkAreaSize;
  }
  return undefined;
}

export function getMainViewActionType(viewerActionType: ViewerActionType): MainViewActionType | undefined {
  switch (viewerActionType) {
    case ViewerActionType.checkAreaSize:
      return MainViewActionType.checkAreaSize;
    case ViewerActionType.fitSize:
    case ViewerActionType.originSize:
      return MainViewActionType.changeResourceViewerSize;
    default:
      return undefined;
  }
}

export function isKeyPointType(annotationType: AnnotationType): boolean {
  return [AnnotationType.keypoint, AnnotationType.keypointOD].includes(annotationType);
}

export function isPolylineType(annotationType: AnnotationType): boolean {
  return [AnnotationType.polylinePoint, AnnotationType.polylinePoint3d].includes(annotationType);
}

export function isPolylinePointType(annotationType: AnnotationType): boolean {
  return [AnnotationType.point, AnnotationType.point3d].includes(annotationType);
}

export function is3dPolyBaseType(annotationType: AnnotationType): boolean {
  return [AnnotationType.polylinePoint3d, AnnotationType.polygon3d].includes(annotationType);
}

export function isPolylineTypeIncluded(annotationTypes: AnnotationType[]): boolean {
  return intersection(annotationTypes, [AnnotationType.polylinePoint, AnnotationType.polylinePoint3d]).length !== 0;
}

export function isPolylinePointTypeIncluded(annotationTypes: AnnotationType[]): boolean {
  return intersection(annotationTypes, [AnnotationType.point, AnnotationType.point3d]).length !== 0;
}

export function isSupportSplitType(annotationType: AnnotationType): boolean {
  return [AnnotationType.polygon, AnnotationType.polySeg, AnnotationType.polyline, AnnotationType.polylinePoint].includes(annotationType);
}

export function convertKeyPointServerFromKeyPointClient(keyPoints: KeyPoint[]): KeyPoint {
  return omitBy(keyPoints.reduce((acc, keyPoint) => Object.assign(acc, keyPoint), {}), isNil);
}

export function convertPointsToKeyPoints(pointLabels: PointLabel[], points: Coordinate[]): KeyPoint[] {
  if (isEmpty(pointLabels)) {
    return [];
  }
  return points.reduce((keyPoints, coord, idx) => {
    if (isNil(coord) || isNil(pointLabels[idx])) {
      return keyPoints;
    } else {
      return keyPoints.concat({ [pointLabels[idx].id]: coord });
    }
  }, []);
}

export function convertKeyPointsToPoints(pointLabels: PointLabel[], keyPoints: KeyPoint[]): Coordinate[] {
  if (isEmpty(keyPoints) || isEmpty(pointLabels)) {
    return [];
  }
  const points: Coordinate[] = [];
  keyPoints.forEach(keyPoint => {
    const key = first(Object.keys(keyPoint));
    const idx = pointLabels.findIndex(({ id }) => id === key);
    points[idx] = first(Object.values(keyPoint));
  });
  return points;
}

export function convertToThreeCoordinates<T extends PointCloudPosition | PointCloudRotation>(position: T): T {
  const [z, x, y] = position;
  return [x, y, z] as T;
}

export function convertThreeCoordinates(instance: StudioAnnotation): StudioAnnotation | undefined {
  if (!instance) {
    return undefined;
  }
  return {
    ...instance,
    position: convertToThreeCoordinates(instance.position ?? [0, 0, 0]),
    rotation: convertToThreeCoordinates(instance.rotation ?? [0, 0, 0]),
  };
}

export function convertPointAnnotationThreeCoordinates(instance: StudioAnnotation): StudioAnnotation | undefined {
  if (!instance) {
    return undefined;
  }
  const point3dAnnotations = instance.point3dAnnotations?.map(point => ({
    ...point,
    position: convertToThreeCoordinates(point.position)
  })) ?? [];
  return {
    ...instance,
    point3dAnnotations
  };
}

export function convertToRealCoordinates(position: PointCloudPosition): PointCloudPosition {
  const [x, y, z] = position;
  return [z, x, y];
}

export function convertRealCoordinates(instance: StudioAnnotation): StudioAnnotation {
  if (!instance.position || !instance.rotation) {
    return instance;
  }
  const [x, y, z] = instance.position;
  const [rx, ry, rz] = instance.rotation;
  return {
    ...instance,
    position: [z, x, y],
    rotation: [rz, rx, ry],
  };
}

export function convertPositionRealCoordinates(instance: StudioAnnotation): StudioAnnotation {
  if (!instance.position) {
    return instance;
  }
  const [x, y, z] = instance.position;
  return {
    ...instance,
    position: [z, x, y]
  };
}

export function applyPointLabelsToKeyPointServer(pointLabels: PointLabel[], keyPoint: KeyPoint): KeyPoint {
  const newKeyPoint = cloneDeep(keyPoint);
  Object.keys(newKeyPoint).forEach((key, idx) => {
    const label = pointLabels.find(({ id }) => id === key);
    if (isNil(label)) {
      const idxMatchedLabel = pointLabels[idx];
      if (isNil(idxMatchedLabel)) {
        delete newKeyPoint[key];
      } else {
        // 변경된 클래스 pointLabels 에 기존 key(id) 없는 경우 점 순서에 맞게 key(id) 변환, before 클래스의 key 제거
        const point = newKeyPoint[key];
        const newKey = idxMatchedLabel.id;
        newKeyPoint[newKey] = point;
        delete newKeyPoint[key];
      }
    }
  });
  return newKeyPoint;
}

export function getAnnotatorType(instance: StudioAnnotation): AnnotatorType {
  switch (instance.type) {
    case AnnotationType.bbox:
    case AnnotationType.obboxV2:
    case AnnotationType.keypointOD:
    case AnnotationType.keypoint:
    case AnnotationType.polygon:
    case AnnotationType.polySeg:
    case AnnotationType.polyline:
      return AnnotatorType.image;
    case AnnotationType.frameRange:
      return AnnotatorType.frameRange;
    case AnnotationType.entity:
      return AnnotatorType.text;
    case AnnotationType.segmentationMask:
      return AnnotatorType.segmentationMask;
    case AnnotationType.lotteQaRow:
      return AnnotatorType.lotteQaRow;
  }
  // todo : AnnotationType 전체 case 처리 (https://app.asana.com/0/1179494858925986/1207986189154530/f)
  return undefined;
}
