import { JoinStatus } from '@admin/@core/services/api/users-api/users-api.model';
import { Lang } from '@aimmo/i18n';

export enum SocialProvider {
  google = 'google',
  azure = 'azure'
}

export enum ConfirmMailQuery {
  code = 'code',
  expiration = 'expiration'
}

export interface OAuthParam {
  provider: SocialProvider;
  accessToken?: string;
  idToken?: GoogleIDToken;
}

// todo OAuthParam 을 OAuthParam2 로 통일해야함 - 인증 서버 별도 구축 시 변경 필요
// https://app.asana.com/0/692598029547572/1207654046807064
export type OAuthParamNew = OAuthParamGoogle | OAuthParamAzure;

export interface OAuthParamGoogle {
  provider: SocialProvider.google;
  idToken: GoogleIDToken;
}

export interface OAuthParamAzure {
  provider: SocialProvider.azure;
  azureIdToken: string;
}

export interface GoogleIDToken {
  clientId: string;
  token: string;
}

export interface LoginParam {
  email: string;
  password: string;
}

export interface Token {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
}

export interface RefreshParam {
  accessToken: string;
  refreshToken: string;
}

export interface OauthProfile {
  uid: string;
  name: string;
  email: string;
}

export interface User {
  avatarThumbnailUrl?: string;
  avatarUrl?: string;
  email: string;
  emailConfirmed: boolean;
  id: string;
  languageCode: Lang;
  lastLoginAt: string;
  loginMethodTypes?: LoginMethodType[];
  marketingAgreement: MarketingAgreement;
  memberType?: MemberType;
  name: string;
  // TODO: saas 전용 추후 동일 프로세스로 전환
  firstName?: string;
  lastName?: string;
  // TODO end
  phoneNumber: string;
  // TODO: [DB] 이 아래로는 제거 혹은 리팩토링 필요
  needToCertification: boolean;
  needToAddAccount: boolean;
  needToAddSsn: boolean;
  needToSetName: boolean;
  needToSetPasswordName: boolean;
  hasPassword: boolean;
  hasSsn: boolean;
  birthday: string;
  workType: UserWorkType;
  joinStatus?: JoinStatus;
}

export enum LoginMethodType {
  password = 'password',
  social = 'social'
}

export enum UserWorkType {
  partTime = 'part_time',
  fullTime = 'full_time'
}


export interface MarketingAgreement {
  receiveEmail: boolean;
  receiveSms: boolean;
}

export interface BaseTermAgreements {
  agreeToPrivacyPolicy: boolean;
  marketingAgreement: MarketingAgreement;
  agreeToTerms: boolean;
  agreeToSoftware?: boolean;
  // TODO: [DB] only crowd
  agreeToNotMinority: boolean;
}

export interface Credential {
  type: CredentialType;
}

export enum CredentialType {
  social = 'social',
  password = 'password'
}

export enum SignupType {
  saas = 'saas',
  invited = 'invited',
  crowd = 'crowd'
}

export enum MemberType {
  saas = 'saas',
  crowd = 'crowd'
}

export interface PasswordCredential extends Credential {
  password: string;
}

export interface SocialCredential extends Credential {
  provider: SocialProvider;
  accessToken?: string;
  idToken?: GoogleIDToken;
}

export interface SaasSignUpUserData extends BaseTermAgreements {
  email?: string;
  type: SignupType;
  firstName?: string;
  lastName?: string;
  name?: string;
  code?: string;
  credential: PasswordCredential | SocialCredential;
}

export interface SocialLoginMethod {
  credential: SocialCredential;
}

export enum AuthState {
  authorized = 'authorized',
  needCertificationForPartTime = 'needCertificationForPartTime',
  needSignUpEmailConfirmForPartTime = 'needEmailConfirm',
  unauthorized = 'unauthorized',
  needToSetPasswordNameForFullTime = 'needToSetPasswordNameForFullTime',
  needToSetNameForFullTime = 'needToSetNameForFullTime'
}

export interface IsEmailExist {
  isExist: boolean;
}

export interface CheckDuplicateEmailResult {
  isExist: boolean;
  isUnregistered: boolean;
}

export enum RealUserWorkType {
  partTimeWorker,
  fullTimeAdmin,
  fullTimeWorker
}

export interface EmailParam {
  email: string;
}

export interface ResetPasswordPayload {
  code: string;
  password: string;
}
