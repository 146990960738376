import { CanvasContext } from '@bluewhale/ngx-annotator/model';

export function checkHardwareAcceleration(context: CanvasContext): boolean {
  try {
    const canvas = document.createElement('canvas');
    switch (context) {
      case CanvasContext.webgl:
      case CanvasContext.experimentalWebgl:
        return !!(WebGLRenderingContext && (canvas.getContext(CanvasContext.webgl) || canvas.getContext(CanvasContext.experimentalWebgl)));
      case CanvasContext.webgl2:
        return !!(WebGL2RenderingContext && canvas.getContext(CanvasContext.webgl2));
      // 사용하지 않음
      case CanvasContext.webgpu:
      case CanvasContext.bitmaprenderer:
      case CanvasContext.twoDimensional:
        return false;
    }
  } catch (e) {
    return false;
  }
}
