import { CLASS_KEY, EMPTY_PIXEL_DEFAULT_ALPHA } from '@aimmo/annotator-model';
import {
  DEFAULT_IMAGE_POINT_SIZE,
  DEFAULT_POINT_LABEL_DISPLAYED,
  ModeType,
  ScaleCondition
} from '@bluewhale/ngx-annotator/tool/image/model';
import { TemplateType } from '../enums';
import { ImageAnnotatedItem, ImageAnswerItem, ImageTemplateOption } from '../image-model';
import { Annotator } from './annotator';

// @dynamic
export class ImageAnnotator extends Annotator {
  private static instance: ImageAnnotator;

  private constructor() {
    super();
  }

  public get code(): TemplateType {
    return TemplateType.image;
  }

  public static getInstance(): ImageAnnotator {
    if (!this.instance) {
      this.instance = new ImageAnnotator();
    }
    return this.instance;
  }

  public getDefaultTemplateOption(): ImageTemplateOption {
    return {
      labelKeys: [CLASS_KEY],
      labelCustomOptions: [],
      checkFullSegmentation: false,
      checkFullSegmentationAlphaValue: EMPTY_PIXEL_DEFAULT_ALPHA,
      useMagicCrop: false,
      useBrightness: true,
      useFillColorOpacityToMax: true,
      useContrast: true,
      useSaturate: true,
      isRotatable: true,
      usePan: true,
      defaultMode: ModeType.polygon,
      useExtremeBbox: false,
      useCornerBbox: false,
      useBbox: false,
      usePolygon: true,
      useSemanticPolygon: false,
      useKeypoint: false,
      usePoint: false,
      usePolyline: false,
      usePolylinePoint: false,
      useObboxV2: false,
      useCuboid: false,
      useCuboidPlain: false,
      useMinimap: true,
      useImageDownload: false,
      useImageMergeViewTool: false,
      scaleCondition: ScaleCondition.Default,
      useCroppedTaskImage: false,
      isShowSideArea: true,
      useAnnotationDragdropOrdering: true,
      useDotNumberText: false,
      useSegmentationImageDownload: false,
      useKeypointDownload: false,
      attributeColorInfo: undefined,
      latex: {
        useLatex: false,
        expressionAttributeKey: undefined,
        expressionClassValue: undefined,
        tokenClassValue: undefined,
        tokenOrderAttributeKey: undefined,
        tokenValueAttributeKey: undefined,
        latexFullTextTokenizerCharacterInfos: '{}'
      },
      showCrossLine: false,
      showOriginDistanceGuide: undefined,
      instanceCreatable: true,
      instanceCoordinateChangable: true,
      instanceDeletable: true,
      useToggleBackgroundROIS: false,
      minGroupBySelectCount: 2,
      polylineStrokeWidth: 1,
      firstPointStyle: null,
      usePointSize: false,
      displayPointLabel: DEFAULT_POINT_LABEL_DISPLAYED,
      defaultPointSize: DEFAULT_IMAGE_POINT_SIZE,
      useInstanceCountRestriction: false,
      maxInstanceCount: 8,
      useFloat: false,
      manualPhotoContextMappingInfo: []
    };
  }

  public getAnnotatedAnswerItems(items: ImageAnnotatedItem[]): ImageAnswerItem[] {
    return items.map(({
                        type, label, points, rect, degree,
                        children, id, attributes,
                        groupId, keyPoints, locked,
                        invisibleKeys
                      }) => {
      return {
        type, label, points, rect, children,
        id, groupId, attributes, degree,
        keyPoints, locked, invisibleKeys
      } as ImageAnswerItem;
    });
  }

  public toString(): string {
    return 'ImageAnnotator[image]';
  }
}
