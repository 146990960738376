export enum LotteQaColumnField {
  speaker = 'speaker',
  text = 'text',
  workable = 'workable',
  qa = 'qa',
  qaNumber = 'qaNumber',
  emotion = 'emotion',
  intents = 'intents',
  entities = 'entities'
}

export enum LotteQaIntentsColumnField {
  intent0 = 'intent0',
  intent1 = 'intent1',
  intent2 = 'intent2'
}

export enum LotteQaEntitiesColumnField {
  prices = 'prices',
  quantities = 'quantities',
  sizes = 'sizes',
  times = 'times',
  dates = 'dates',
  goods = 'goods',
  places = 'places',
  organizations = 'organizations',
  persons = 'persons'
}

export enum LotteQaSpeaker {
  customer = 'c',
  shop = 's'
}

export enum LotteQaWorkable {
  yes = 'work_target',
  no = 'not_work_target'
}

export enum LotteQaQA {
  question = 'q',
  answer = 'a'
}

export enum LotteQaEmotion {
  moderate = 'm',
  negative = 'n',
  positive = 'p',
}

export enum LotteQaEditableColumnClass {
  editable = 'editable',
  notEditable = 'not-editable',
  textCell = 'text-cell',
  textCellAlignCenter = 'text-cell--align-center'
}

export enum LotteQaEditableRowClass {
  invalid = 'invalid'
}

export enum LotteQaCellEditor {
  radioSelect = 'radioSelectCellEditor',
  entities = 'entitiesCellEditor',
  select = 'selectCellEditor'
}

export enum LotteQaCellRenderer {
  radioSelect = 'radioSelectCellRenderer',
  entities = 'entitiesCellRenderer',
  select = 'selectCellRenderer'
}

export enum CellPinned {
  left = 'left',
  right = 'right'
}
