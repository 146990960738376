import { CLASS_KEY } from '@aimmo/annotator-model';
import { AnnotationAttributeAnswer } from '@bluewhale/ngx-annotator/components/attributes/model';
import {
  LotteQaColumnField,
  LotteQaEntitiesColumnField,
  LotteQaWorkable
} from '@bluewhale/ngx-annotator/tool/lotte-qa/model';
import { TemplateType } from '../enums';
import { AnnotatedAnswerItem, AnnotatedItem, TemplateOption } from '../interfaces';
import { DELIMITER, LotteQaItem } from '../lotte-qa-model';
import { Annotator } from './annotator';

export enum AttributeKey {
  qaNumber = 'qa_number',
  intent0 = 'intent_0',
  intent1 = 'intent_1',
  intent2 = 'intent_2'
}

// @dynamic
export class LotteQaAnnotator extends Annotator {
  private static instance: LotteQaAnnotator;

  private constructor() {
    super();
  }

  public get code(): string {
    return TemplateType.lotte_qa_row;
  }

  public static getInstance(): LotteQaAnnotator {
    if (!this.instance) {
      this.instance = new LotteQaAnnotator();
    }
    return this.instance;
  }

  public getDefaultTemplateOption(): TemplateOption {
    return {
      isShowSideArea: true,
      useAnnotationDragdropOrdering: false
    };
  }

  public getAnnotatedAnswerItems(items: LotteQaItem[]): AnnotatedAnswerItem[] {
    return items.map(({
                        id, type, workable, qa, qaNumber, emotion, intent0, intent1, intent2,
                        prices, quantities, sizes, times, dates, goods, places, organizations, persons
                      }) => {
      const attributes = {} as AnnotationAttributeAnswer;
      if (workable === LotteQaWorkable.yes) {
        attributes[CLASS_KEY] = workable;
        attributes[LotteQaColumnField.qa] = qa;
        attributes[AttributeKey.qaNumber] = qaNumber;
        attributes[LotteQaColumnField.emotion] = emotion;
        attributes[AttributeKey.intent0] = intent0;
        attributes[AttributeKey.intent1] = intent1;
        attributes[AttributeKey.intent2] = intent2;
        attributes[LotteQaEntitiesColumnField.prices] = prices?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.quantities] = quantities?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.sizes] = sizes?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.times] = times?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.dates] = dates?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.goods] = goods?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.places] = places?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.organizations] = organizations?.join(DELIMITER);
        attributes[LotteQaEntitiesColumnField.persons] = persons?.join(DELIMITER);
      } else {
        attributes[CLASS_KEY] = LotteQaWorkable.no;
      }
      return { id, type, attributes } as AnnotatedAnswerItem;
    });
  }

  public toString(): string {
    return 'LotteQaAnnotator[lotte_qa_row]';
  }

  public override convertAnnotations(annotations: AnnotatedItem[]): LotteQaItem[] {
    return annotations?.map(annotation => {
      const attributes = annotation.attributes ?? {};
      const workable = attributes[CLASS_KEY];
      const baseLotteQaItem = { id: annotation.id, type: annotation.type } as LotteQaItem;
      if (workable === LotteQaWorkable.yes) {
        const qaNumber = attributes[AttributeKey.qaNumber];
        return Object.assign(baseLotteQaItem, {
          workable,
          qa: attributes[LotteQaColumnField.qa],
          qaNumber: typeof qaNumber === 'string' ? parseInt(qaNumber, 10) : qaNumber,
          emotion: attributes[LotteQaColumnField.emotion],
          intent0: attributes[AttributeKey.intent0],
          intent1: attributes[AttributeKey.intent1],
          intent2: attributes[AttributeKey.intent2],
          prices: attributes[LotteQaEntitiesColumnField.prices]?.toString()?.split(DELIMITER),
          quantities: attributes[LotteQaEntitiesColumnField.quantities]?.toString()?.split(DELIMITER),
          sizes: attributes[LotteQaEntitiesColumnField.sizes]?.toString()?.split(DELIMITER),
          times: attributes[LotteQaEntitiesColumnField.times]?.toString()?.split(DELIMITER),
          dates: attributes[LotteQaEntitiesColumnField.dates]?.toString()?.split(DELIMITER),
          goods: attributes[LotteQaEntitiesColumnField.goods]?.toString()?.split(DELIMITER),
          places: attributes[LotteQaEntitiesColumnField.places]?.toString()?.split(DELIMITER),
          organizations: attributes[LotteQaEntitiesColumnField.organizations]?.toString()?.split(DELIMITER),
          persons: attributes[LotteQaEntitiesColumnField.persons]?.toString()?.split(DELIMITER)
        } as LotteQaItem);
      } else {
        return Object.assign(baseLotteQaItem, { workable: LotteQaWorkable.no } as LotteQaItem);
      }
    });
  }
}
