import type { ConnectedPosition } from '@angular/cdk/overlay';
import { AnnotatorItemClass, ItemType } from '@bluewhale/ngx-annotator/tool/image/model';
import { cloneDeep } from 'lodash-es';
import type { AnnotatorOption, TemplateOption } from './interfaces';
import { VisualizeAttributeKey } from './enums';

export const NgxAnnotatorContext = 'ngx-annotator';
export const THRESHOLD_KEY = 'score';
export const MINBOX_KEY = 'minBox';
export const CLASS_FILTERS_KEY = 'classFilters';
export const COMMON_FILTERS_KEY = 'commonFilters';
export const COLORS = [
  '#ff24a3', '#51fb51', '#d966ff', '#9bfd9b',
  '#ff80ca', '#e699ff', '#ff4000', '#ff794d',
  '#3366ff', '#b3c6ff', '#ff8a80', '#80c269',
  '#f6d16c', '#3dbbae', '#ecdabb', '#d50000',
  '#705e82', '#469ede', '#c6ea82', '#b9b8bb'
];

export const KEYPOINT_COLORS = [
  '#000000', '#f6d16c', '#3366ff',
  '#1f8737', '#c62828', '#ff80ca'
];

export const COLORS_FOR_POLYLINE = [
  '#d50000', '#ff24a3',
  '#22a6ba', '#ff4000',
  '#3366ff', '#1f8737',
  '#00ff0d', '#6ea108'
];
export const GROUP_UNSPECIFIED_COLOR = '#868E96';
export const TRACK_ID_COLORS = [
  '#CCFF00', '#29D330', '#4253FF', '#FF68DE',
  '#B65611', '#1B626B', '#7E2DE4', '#FFE600',
  '#02ECEC', '#CA32FF', '#AC616E', '#3AA1FF',
  '#FFB74A', '#FF6F07', '#ACB4FF', '#9B097B',
  '#804C01', '#849BAF', '#4F00B2', '#FFF8B8',
  '#E1F9F9', '#2D74FF', '#FFCDB0', '#07569E',
  '#9E00FF', '#C0F2C2', '#0A19B0', '#FFCEF4',
  '#FFCD83', '#269E5D', '#816DFC', '#7A6E00',
  '#B6FFFF', '#6EBA53', '#00FFA3', '#849BAF'
];

export const DEFAULT_TEMPLATE = {
  annotatedItem: {},
  isShowSideArea: false,
  useAnnotationDragdropOrdering: false
};

export const DEFAULT_FEATURE_ENABLED = {
  deletable: false,
  modifiable: false,
};

const DEFAULT_ANNOTATOR_OPTION: AnnotatorOption<TemplateOption> = {
  isEmbedMode: false,
  visibleControlArea: true,
  visibleValidationInfo: true,
  visibleHeaderArea: false,
  visibleBottomControlPanel: true,
  template: DEFAULT_TEMPLATE,
  featureEnabled: DEFAULT_FEATURE_ENABLED
};

export function getDefaultAnnotatorOption(): AnnotatorOption<TemplateOption> {
  return cloneDeep(DEFAULT_ANNOTATOR_OPTION);
}

export const CLASS_ANNOTATOR_OVERLAY_BACKDROP = 'annotator-overlay-backdrop';

export const BACKGROUND_ROI_DEFAULT = 'default$$';

export const CLASS_ANNOTATOR_DIALOG = 'annotator-dialog';

export const DEFAULT_LABEL_DISPLAYED = true;

export const DEFAULT_LABEL_FONT_SIZE = 10;

export const ENTITY_LABEL_MAX_FONT_SIZE = 30;

export const MENU_OVERLAY_CONNECTED_POSITIONS = [{
  originX: 'end',
  originY: 'center',
  overlayX: 'start',
  overlayY: 'center',
  offsetX: 7
}] as ConnectedPosition[];

export const VERTICAL_MENU_OVERLAY_CONNECTED_POSITIONS = [{
  originX: 'end',
  originY: 'center',
  overlayX: 'start',
  overlayY: 'center',
  offsetX: 18
}] as ConnectedPosition[];

export enum SliderMenuType {
  zoomMenu = 'zoom-menu',
  contrastMenu = 'contrast-menu',
  pointSizeMenu = 'point-size-menu',
  brightnessMenu = 'brightness-menu',
  polyColorFillMenu = 'poly-color-fill-menu',
  saturateMenu = 'saturate-menu',
  intensitySaturateMenu = 'intensity-saturate-menu',
  intensityLightnessMenu = 'intensity-lightness-menu',
  intensityRangeMenu = 'intensity-range-menu',
  velocityRangeMenu = 'velocity-range-menu',
  pointZRangeMenu = 'point-z-range-menu',
}

export const VisualizeAttributeKeyToItemType = {
  [VisualizeAttributeKey.polySeg]: ItemType.SemanticPolygon,
  [VisualizeAttributeKey.bbox]: ItemType.Bbox,
  [VisualizeAttributeKey.polygon]: ItemType.Polygon,
  [VisualizeAttributeKey.polyline]: ItemType.Polyline
};

export const ATTRIBUTE_MIN_KEY_INDEX = 0;
export const ATTRIBUTE_MAX_KEY_INDEX = 7;

export const MIN_FLAT_CUBOID_POINT_COUNT = 4;
export const MAX_FLAT_CUBOID_POINT_COUNT = 6;

export const COMMENT_ICON_SVG = '<svg width="35" height="35" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '<path class="' + AnnotatorItemClass.commentPath + '" d="M2.5 17.5V5C2.5 4.30964 3.05964 3.75 3.75 3.75H16.25C16.9404 3.75 17.5 4.30964 17.5 5V12.5C17.5 13.1904 16.9404 13.75 16.25 13.75H6.25L2.5 17.5Z" fill="#3797FF"/>\n' +
  '<path class="' + AnnotatorItemClass.commentPath + '" d="M5.73223 12.5H16.25V5H3.75V14.4822L5.73223 12.5ZM2.5 17.5V5C2.5 4.30964 3.05964 3.75 3.75 3.75H16.25C16.9404 3.75 17.5 4.30964 17.5 5V12.5C17.5 13.1904 16.9404 13.75 16.25 13.75H6.25L2.5 17.5Z" fill="#DFDFDF"/>\n' +
  '</svg>';

export const COMMENT_ICON_HEIGHT = 35;
export const COMMENT_ICON_WIDTH = 35;
export const COMMENT_EXTRA_NUMBER_SIZE = 10;
export const COMMENT_DEFAULT_COORDINATE = { x: -1, y: -1 };
export const COMMENT_ICON_NAME = 'comment-icon';
export const LISTED_COMMENT_ICON_NAME = 'listed-comment-icon';
export const LISTED_COMMENT_MAX_LENGTH = 5;
export const POINT_CLOUD_COMMENT_CORRECTION_VALUE = 10;

const FRAME_BORDER_WIDTH = 1;
const FRAME_TOP_MARGIN = 8;
export const MULTI_FRAME_PASS_REJECT_PANEL_TOP_OFFSET = -(FRAME_BORDER_WIDTH + FRAME_TOP_MARGIN);
export const VISUALIZED_ITEM_COLOR = '#E7FF00';
