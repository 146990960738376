export function UnModifiableFilter(): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => void {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function(...args: any[]): any {
      if ((this as any).isSelectableOnly) {
        return;
      }
      return originalMethod.apply(this, args);
    };
  };
}

export function MovableFilter(): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => void {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function(...args: any[]): any {
      if (!(this as any).isMovable) {
        return;
      }
      return originalMethod.apply(this, args);
    };
  };
}

export function ResizableFilter(): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => void {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    descriptor.value = function(...args: any[]): any {
      if (!(this as any).isResizable) {
        return;
      }
      return originalMethod.apply(this, args);
    };
  };
}
