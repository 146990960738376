// TODO uncacheable이 왜 필요한지 다시 확인해볼것
import { ImageSize } from '@aimmo/annotator-model';
import { getUncacheableUrl } from '@aimmo/utils/network';
import { DownloadImageParam } from '@bluewhale/ngx-annotator/service';
import { CanvasDrawType, CanvasItemParam } from '@bluewhale/ngx-annotator/tool/image/model';
import { saveToFile } from '@bluewhale/ngx-annotator/util/file';
import {
  convertHexColorToGrayScale,
  getEmptyPixelIndices,
  getItemDrawnCanvas
} from '@bluewhale/ngx-annotator/util/image';
import { format } from 'date-fns';
import { isNil } from 'lodash-es';
import { defer, forkJoin, fromEvent, Observable, of, Subject } from 'rxjs';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';

export interface InvalidPixels {
  indices: number[];
  color?: string;
  borderColor?: string;
}

export interface InvalidPixelResult {
  emptyPixels: InvalidPixels;
  overlapPixels?: InvalidPixels;
  error?: any;
}

export const VALID_HEX_REGEX = /^#[0-9A-F]{6}$/i;

export function loadImage(imageUrl: string, uncacheable = true): Observable<HTMLImageElement> {
  return new Observable<HTMLImageElement>(observer => {
    const image = new Image();
    const destroyedSource = new Subject<void>();
    const loadObservable = fromEvent(image, 'load').pipe(
      tap(() => {
        observer.next(image);
        observer.complete();
      })
    );
    const errorObservable = fromEvent(image, 'error').pipe(
      tap(err => observer.error(err))
    );
    forkJoin([
      loadObservable,
      errorObservable
    ]).pipe(
      takeUntil(destroyedSource)
    ).subscribe();

    image.crossOrigin = 'Anonymous';
    image.src = uncacheable ? getUncacheableUrl(imageUrl) : imageUrl;
    return () => destroyedSource.next();
  });
}

export function isValidHexColor(hex: string): boolean {
  return !isNil(hex) && VALID_HEX_REGEX.test(hex);
}

export class RGBA {
  public R: number;
  public G: number;
  public B: number;
  public A: number;

  constructor({ R, G, B, A }: RGBA) {
    this.R = R;
    this.G = G;
    this.B = B;
    this.A = A;
  }

  public toString(): string {
    return `rgba(${this.R},${this.G},${this.B},${this.A})`;
  }
}


export function hexToRGBA(hex: string, alpha = 1): RGBA {
  let targetHex = hex;
  if (!isValidHexColor(hex)) {
    targetHex = '#ffffff';
    console.error(`유효하지 않은 색상 값입니다. 색상: ${hex}`);
  }
  const R = parseInt(targetHex.slice(1, 3), 16);
  const G = parseInt(targetHex.slice(3, 5), 16);
  const B = parseInt(targetHex.slice(5, 7), 16);

  return new RGBA({ R, G, B, A: alpha });
}

// three.js r152 color management https://github.com/mrdoob/three.js/blob/dev/src/math/ColorManagement.js#L129
export function SRGBToLinear(color: number): number {
  return (color < 0.04045) ? color * 0.0773993808 : Math.pow(color * 0.9478672986 + 0.0521327014, 2.4);
}

export function resizeBase64(base64: string, resizeWidth = 300): Observable<string> {
  return new Observable(observer => {
    const destroyedSource = new Subject<void>();
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    const img = new Image();
    const onload = () => {
      canvas.width = Math.min(img.naturalWidth, resizeWidth);
      canvas.height = canvas.width * img.naturalHeight / img.naturalWidth;
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      const base64Thumbnail = canvas.toDataURL('webp', 0.7);
      observer.next(base64Thumbnail);
      observer.complete();
    };
    forkJoin([
      fromEvent(img, 'load').pipe(tap(() => onload())),
      fromEvent(img, 'error').pipe(tap(err => observer.error(err))),
    ]).pipe(takeUntil(destroyedSource)).subscribe();
    img.src = base64;
    return () => destroyedSource.next();
  });
}

export function getInvalidPixel(
  imageSize: ImageSize,
  canvasItemParam: CanvasItemParam[],
  alphaValue: number,
  blob?: Blob
): Observable<InvalidPixelResult> {
  return defer(() => {
    const { width, height } = imageSize;
    if (isNil(width) || isNil(height)) {
      return of({ emptyPixels: { indices: [] } });
    }
    const naturalWidth = Math.max(width, 1);
    const naturalHeight = Math.max(height, 1);
    const itemDrawnCanvas = getItemDrawnCanvas({
      items: canvasItemParam,
      imageSize
    });
    const itemDrawnContext = itemDrawnCanvas.getContext('2d');
    const itemDrawnData = itemDrawnContext.getImageData(0, 0, naturalWidth, naturalHeight);
    const defaultEmptyPixelIndices = getEmptyPixelIndices(itemDrawnData, alphaValue);
    const defaultResult = { emptyPixels: { indices: defaultEmptyPixelIndices } };
    if (blob) {
      return new Observable<InvalidPixelResult>(observer => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const image = new Image();
        const src = URL.createObjectURL(blob);
        canvas.width = naturalWidth;
        canvas.height = naturalHeight;
        image.onload = () => {
          context.drawImage(image, 0, 0);
          const imageData = context.getImageData(0, 0, naturalWidth, naturalHeight);
          const emptyIndices = getEmptyPixelIndices(imageData, alphaValue);
          observer.next({ emptyPixels: { indices: defaultEmptyPixelIndices.concat(emptyIndices) } });
          observer.complete();
        };
        image.onerror = () => {
          console.error('유효하지않은 픽셀 이미지 읽기 실패');
          observer.next(defaultResult);
          observer.complete();
        };
        image.src = src;
        return () => {
          if (src) {
            URL.revokeObjectURL(src);
          }
        };
      });
    } else {
      return of(defaultResult);
    }
  }).pipe(
    catchError(err => {
      console.error(err);
      return of({ emptyPixels: { indices: [] } });
    })
  );
}

export function downloadImageByType({
                                      downloadType,
                                      fileName,
                                      blob,
                                      imageSize,
                                      items
                                    }: DownloadImageParam): Observable<HTMLCanvasElement> {
  const observable: Observable<HTMLCanvasElement> = new Observable(observer => {
    const convertColor = downloadType === CanvasDrawType.grayScale ? convertHexColorToGrayScale : undefined;
    const { width, height } = imageSize;
    let src;
    if (!!blob) {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      const img = new Image();
      src = URL.createObjectURL(blob);
      canvas.width = width;
      canvas.height = height;
      img.onload = () => {
        context.drawImage(img, 0, 0);
        observer.next(canvas);
        observer.complete();
      };
      img.onerror = () => {
        console.error('blob 이미지 읽기 실패');
        observer.next(getItemDrawnCanvas({
          items,
          imageSize: { width, height },
          downloadType,
          convertColor
        }));
        observer.complete();
      };
      img.src = src;
    } else {
      observer.next(getItemDrawnCanvas({
        items,
        imageSize: { width, height },
        downloadType,
        convertColor
      }));
      observer.complete();
    }
    return () => {
      if (src) {
        URL.revokeObjectURL(src);
      }
    };
  });
  return observable.pipe(
    tap(canvas => {
      const imageUrl = canvas.toDataURL('image/png');
      fileName = fileName || `AIMMO_작업내역_${format(new Date(), 'MM월_dd일_HH시_mm분_ss초')}.png`;
      saveToFile(imageUrl, fileName);
    }),
    take(1),
  );
}
