import { Coordinate, EMPTY_PIXEL_DEFAULT_ALPHA } from '@aimmo/annotator-model';
import { hexToRGBA, isValidHexColor } from '@aimmo/utils/image';
import { CanvasDrawType, CanvasItemDrawOption, CanvasItemParam } from '@bluewhale/ngx-annotator/tool/image/model';
import { isEmpty } from 'lodash-es';
import type * as svgjs from 'svg.js';
import { ViewboxOptions } from './image-util';

interface Size {
  width: number;
  height: number;
}

export function rgbValueToHexColor([R, G, B]: [number, number, number]): string {
  R = Math.min(Math.max(R, 0), 255);
  G = Math.min(Math.max(G, 0), 255);
  B = Math.min(Math.max(B, 0), 255);

  const RR = R.toString(16).padStart(2, '0');
  const GG = G.toString(16).padStart(2, '0');
  const BB = B.toString(16).padStart(2, '0');

  return `#${RR}${GG}${BB}`;
}

export function convertHexColorToGrayScale(color: string): string {
  let { R, G, B } = hexToRGBA(color);

  // to make the colour less bright than the input
  // change the following three "+" symbols to "-"
  const grayScaleValue = Math.round((R + G + B) / 3);
  R = grayScaleValue;
  G = grayScaleValue;
  B = grayScaleValue;
  return rgbValueToHexColor([R, G, B]);
}

export function lightenDarkenColor(color: string = '#cccccc', amount: number = -20): string {
  if (!isValidHexColor(color)) {
    return '#ffffff';
  }

  let { R, G, B } = hexToRGBA(color);

  // to make the colour less bright than the input
  // change the following three "+" symbols to "-"
  R += amount;
  G += amount;
  B += amount;

  return rgbValueToHexColor([R, G, B]);
}

export function invertRGB(rgb: any): string {
  rgb = [].slice
    .call(arguments)
    .join(',')
    .replace(/rgb\(|\)|rgba\(|\)|\s/gi, '')
    .split(',');
  for (let i = 0; i < rgb.length; i++) {
    rgb[i] = (i === 3 ? 1 : 255) - rgb[i];
  }
  return rgbValueToHexColor(rgb);
}

export function getEmptyPixelIndices(imageData: ImageData, alphaValue = EMPTY_PIXEL_DEFAULT_ALPHA): number[] {
  const uncoloredIndices: number[] = [];

  for (let i = 0; i < imageData.data.length; i += 4) {
    const alpha = imageData.data[i + 3];

    if (alpha < alphaValue) { // Opacity 0.6 정도
      const pixelIndex = i / 4;
      uncoloredIndices.push(pixelIndex);
    }
  }

  return uncoloredIndices;
}

function getBoundaryBoxCoordinates(point: Coordinate, boundaryHalfWidth: number): number[][] {
  const [x, y] = point;
  const [minX, maxX, minY, maxY] = [x - boundaryHalfWidth, x + boundaryHalfWidth, y - boundaryHalfWidth, y + boundaryHalfWidth];
  return [
    [minX, minY],
    [maxX, minY],
    [maxX, maxY],
    [minX, maxY]
  ];
}

function getCoordinatesFromIndex(index: number, width: number): [number, number] {
  const x = index % width;
  const y = Math.floor(index / width);
  return [x, y];
}

function getIndexFromCoordinates(x: number, y: number, width: number): number {
  return y * width + x;
}

export function fillInvalidPixelWithColor(canvas: HTMLCanvasElement, indices: number[], fillHexColor: string, outlineColor: string): {
  filledCanvas: HTMLCanvasElement;
  validity: boolean;
} {
  const { canvas: newCanvas, context, imageData } = getCanvasData(canvas);
  let validity = true;
  if (context && imageData) {
    const data = new Uint32Array(imageData.data.buffer);
    const { R, G, B } = hexToRGBA(fillHexColor);
    const { R: sR, G: sG, B: sB } = hexToRGBA(outlineColor);
    // tslint:disable-next-line
    const fillColor = ((0xFF000000 | (B << 16) | (G << 8) | R) >>> 0);
    // tslint:disable-next-line
    const strokeColor = ((0xFF000000 | (sB << 16) | (sG << 8) | sR) >>> 0);
    data.forEach((_, i) => data[i] = 0);

    if (!isEmpty(indices)) {
      indices.forEach(idx => data[idx] = fillColor);
      const outerLineIndices = getOuterLineIndices(indices, canvas, data, 10);
      outerLineIndices.forEach(idx => data[idx] = strokeColor);
      validity = false;
    }

    const newData = new Uint32Array(imageData.data.buffer);
    newData.set(data);
    context.putImageData(imageData, 0, 0);
  }

  return { filledCanvas: newCanvas, validity };
}

function getOuterLineIndices(
  indices: number[],
  canvas: HTMLCanvasElement,
  dataArray: Uint32Array,
  thickness: number
): number[] {
  if (thickness <= 0) {
    return [];
  }

  const outerLineIndices = new Set<number>(getBoundaryIndices(indices, canvas, dataArray));
  const { width, height } = canvas;
  const imageSize = width * height;
  const limitValue = thickness - 1;

  Array.from(outerLineIndices.values()).forEach(idx => {
    const [x, y] = getCoordinatesFromIndex(idx, width);
    for (let dx = -limitValue; dx <= limitValue; dx++) {
      for (let dy = -limitValue; dy <= limitValue; dy++) {
        const newIdx = getIndexFromCoordinates(x + dx, y + dy, width);
        if (newIdx >= 0 && newIdx < imageSize && dataArray[newIdx] === 0 && !outerLineIndices.has(newIdx)) {
          outerLineIndices.add(newIdx);
        }
      }
    }
  });

  return Array.from(outerLineIndices.values());
}

function getBoundaryIndices(indices: number[], canvas: HTMLCanvasElement, dataArray: Uint32Array): number[] {
  const boundaryIndices = new Set<number>();
  const { width, height } = canvas;
  const imageSize = width * height;
  const unitVectors = [[0, -1], [0, 1], [-1, 0], [1, 0]];

  indices.forEach(idx => {
    const [x, y] = getCoordinatesFromIndex(idx, width);
    unitVectors.forEach(([dx, dy]) => {
      const newIdx = getIndexFromCoordinates(x + dx, y + dy, width);
      if (newIdx >= 0 && newIdx < imageSize && dataArray[newIdx] === 0 && !boundaryIndices.has(newIdx)) {
        boundaryIndices.add(newIdx);
      }
    });
  });

  return Array.from(boundaryIndices.values());
}

export function getCanvasData(canvas: HTMLCanvasElement): {
  canvas: HTMLCanvasElement;
  imageData?: ImageData;
  context: CanvasRenderingContext2D | null;
  width: number;
  height: number
} {
  const { width, height } = canvas;
  const newCanvas = document.createElement('canvas');
  newCanvas.width = width;
  newCanvas.height = height;
  const newCanvasContext = newCanvas.getContext('2d');
  let canvasImage: ImageData | undefined;
  if (width === 0 || height === 0) {
    console.error({
      message: 'canvas size is 0',
      canvas
    });
  } else {
    newCanvasContext?.drawImage(canvas, 0, 0);
    canvasImage = newCanvasContext?.getImageData(0, 0, width, height);
  }
  return {
    canvas: newCanvas,
    imageData: canvasImage,
    context: newCanvasContext,
    width,
    height,
  };
}

export function clearCanvasContext(contexts: CanvasRenderingContext2D[], size?: {
  width: number,
  height: number
}): void {
  contexts.forEach(context => {
    if (!context) {
      return;
    }
    const { width = 0, height = 0 } = isEmpty(size) ? context.canvas : size ?? {};
    context.clearRect(0, 0, width, height);
  });
}

export function drawImageToCanvasContext(layer: HTMLCanvasElement | ImageBitmap, ...contexts: CanvasRenderingContext2D[]): void {
  if (!layer) {
    return;
  }
  if (layer.width === 0 || layer.height === 0) {
    console.error({
      message: 'layer size is 0',
      layer
    });
    return;
  }
  contexts.forEach(context => {
    if (!context) {
      return;
    }
    context.drawImage(layer, 0, 0);
  });
}

export function changeCanvasSize(layers: HTMLCanvasElement[], { width, height }: Size): void {
  layers.forEach(layer => {
    if (!layer) {
      return;
    }
    layer.width = width;
    layer.height = height;
  });
}

// TODO: [toCanvas] viewbox 타입 변경 필요, layer 작동 안할듯
export function repositionCanvas(layer: HTMLCanvasElement, viewbox: svgjs.ViewBox | ViewboxOptions, scale: number): void {
  const x = -viewbox.x * scale;
  const y = -viewbox.y * scale;
  layer.style.left = `${x}px`;
  layer.style.top = `${y}px`;
}

export function getItemDrawnCanvas({
                                     items,
                                     imageSize,
                                     downloadType = CanvasDrawType.normal,
                                     convertColor = c => c,
                                     globalCompositeOperation = 'source-over'
                                   }: CanvasItemDrawOption): HTMLCanvasElement {
  const { width, height } = imageSize;
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  const context = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  if (context) {
    draw(downloadType, context, items, convertColor, globalCompositeOperation);
  }
  return canvas;
}

function draw(
  downloadType: CanvasDrawType,
  context: CanvasRenderingContext2D,
  items: CanvasItemParam[],
  convertColor = (c: string) => c,
  globalCompositeOperation: GlobalCompositeOperation = 'source-over'
): void {
  context.globalCompositeOperation = globalCompositeOperation;
  items.filter(v => !!v).forEach(item => {
    const { points = [], color } = item;
    downloadType === CanvasDrawType.line ?
      context.strokeStyle = convertColor(color) :
      context.fillStyle = convertColor(color);
    context.beginPath();
    points.forEach((point: Coordinate, idx) => {
      (idx === 0) ? context.moveTo(...point) : context.lineTo(...point);
      if (idx === (points.length - 1)) {
        context.closePath();
      }
    });
    downloadType === CanvasDrawType.line ?
      context.stroke() :
      context.fill();
  });
}
