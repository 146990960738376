import { AnnotatedAnswerItem, AnnotatedBaseItem, AnnotatedItem, TemplateOption } from '../interfaces';

export abstract class Annotator {
  abstract code: string;

  abstract getDefaultTemplateOption(): TemplateOption;

  abstract getAnnotatedAnswerItems(items: Partial<AnnotatedBaseItem>[]): Partial<AnnotatedAnswerItem>[];

  abstract toString(): string;

  public isOneOf(annotators: typeof Annotator[]): boolean {
    return annotators.some(annotator => this instanceof annotator);
  }

  public convertAnnotations(annotations: AnnotatedItem[]): AnnotatedItem[] {
    return annotations;
  }
}
