import { TemplateType } from '../enums';
import { AnnotatedAnswerItem, AnnotatedBaseItem, TemplateOption } from '../interfaces';
import { Annotator } from './annotator';

// @dynamic
export class DicomAnnotator extends Annotator {
  private static instance: DicomAnnotator;

  private constructor() {
    super();
  }

  public get code(): TemplateType {
    return TemplateType.dicom;
  }

  public static getInstance(): DicomAnnotator {
    if (!this.instance) {
      this.instance = new DicomAnnotator();
    }
    return this.instance;
  }

  public getAnnotatedAnswerItems(items: Partial<AnnotatedBaseItem>[]): Partial<AnnotatedAnswerItem>[] {
    return items;
  }

  public getDefaultTemplateOption(): TemplateOption {
    return {
      isShowSideArea: false,
      useAnnotationDragdropOrdering: false
    };
  }

  public toString(): string {
    return 'DicomAnnotator[dicom]';
  }
}
