import { TemplateType } from '../enums';
import { VideoAnswerItem, VideoItem, VideoTemplateOption } from '../video-model';
import { Annotator } from './annotator';

// @dynamic
export class VideoSelectAnnotator extends Annotator {
  private static instance: VideoSelectAnnotator;

  constructor() {
    super();
  }

  public get code(): TemplateType {
    return TemplateType.frame_range;
  }

  public static getInstance(): VideoSelectAnnotator {
    if (!this.instance) {
      this.instance = new VideoSelectAnnotator();
    }
    return this.instance;
  }

  public getDefaultTemplateOption(): VideoTemplateOption {
    return {
      isShowSideArea: true,
      useAnnotationDragdropOrdering: false
    };
  }

  public getAnnotatedAnswerItems(items: VideoItem[]): VideoAnswerItem [] {
    return items.map(({ type, id, startFrame, endFrame, label, attributes }) => ({
      id,
      type,
      label,
      startFrame,
      endFrame,
      attributes
    } as VideoAnswerItem));
  }

  public toString(): string {
    return 'VideoSelectAnnotator[frame_range]';
  }
}
