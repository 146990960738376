import { TemplateType } from '../enums';
import { Annotator } from './annotator';
import { DicomAnnotator } from './dicom-annotator';
import { ImageAnnotator } from './image-annotator';
import { LotteQaAnnotator } from './lotte-qa-annotator';
import { PointCloudAnnotator } from './point-cloud-annotator';
import { PointCloudSegmentationAnnotator } from './point-cloud-segmentation-annotator';
import { SegmentationMaskAnnotator } from './segmentation-mask-annotator';
import { SequenceAnnotator } from './sequence-annotator';
import { TextAnnotator } from './text-annotator';
import { VideoSelectAnnotator } from './video-annotator';

export function getAnnotatorFactory(type?: TemplateType, useLasso = false): Annotator {
  switch (type) {
    // sequenceImage
    case TemplateType.object_tracking:
      return SequenceAnnotator.getInstance();

    // lidar
    case TemplateType.point_cloud:
      return useLasso ? PointCloudSegmentationAnnotator.getInstance() : PointCloudAnnotator.getInstance();

    // dicom
    case TemplateType.dicom:
      return DicomAnnotator.getInstance();

    // video
    case TemplateType.frame_range:
      return VideoSelectAnnotator.getInstance();

    // text
    case TemplateType.text:
      return TextAnnotator.getInstance();

    // lotte sentence
    case TemplateType.lotte_qa_row:
      return LotteQaAnnotator.getInstance();

    // segmentation mask
    case TemplateType.segmentationMask:
      return SegmentationMaskAnnotator.getInstance();

    // semantic point cloud
    case TemplateType.point_cloud_segmentation:
      return PointCloudSegmentationAnnotator.getInstance();

    // image
    case TemplateType.image:
    default:
      console.log(type);
      return ImageAnnotator.getInstance();
    // throw Error('존재하지않는 타입의 Annotator 입니다.');
  }
}
