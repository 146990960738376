import { head } from 'lodash-es';

// 2019년 5월 4일 => 이천십구년 오월 사일
export function replaceNumToKor(text: string): string {
  let match: RegExpExecArray | null;
  const numRegex: RegExp = /([0-9]+)/g;
  let result = text;

  // tslint:disable-next-line
  while (!!(match = numRegex.exec(result))) {
    result = result.replace(match[1], changeNumToKor(+match[1]));
  }
  return result;
}

// 억 단위까지 처리 가능.
// 1111 => 천백십일(o), 일천일백일십일(x)
// 111111 => 십일만천백십일(o), 일십일만일천일백일십일(x)
export function changeNumToKor(num: number): string {
  if (!Number.isInteger((num))) {
    console.error('num variable is not Integer', num);
    return num.toString();
  }

  const numChar = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구', '십'];
  const digitChar = ['', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천'];
  const digitCount = (num + '').length;
  let result = '';
  let remainNum = num;

  const getDigitChar = (digitIndex: number) => {
    let bigDigitChar = '';
    if (digitIndex === 4) {
      bigDigitChar = '만';
    }
    if (digitIndex === 8) {
      bigDigitChar = '억';
    }
    return bigDigitChar + digitChar[digitIndex];
  };

  for (let i = 0; i < digitCount; i++) {
    const lastNum = remainNum % 10;

    if ((i !== 0 && i !== 4 && i !== 8) && lastNum === 1) {
      result = getDigitChar(i) + result;
    } else if (lastNum !== 0) {
      result = numChar[lastNum] + getDigitChar(i) + result;
    }
    remainNum = Math.floor(remainNum / 10);
  }
  return result;
}

export function getFirstHyphenString(str?: string | null, prefix?: string | null): string {
  return `${prefix ?? ''}${head(str?.split('-')) ?? ''}`;
}

export function textToNumber(value: string, defaultValue = 0): number {
  const numberValue = Number(value);
  return isNaN(numberValue) || !isFinite(numberValue) ? defaultValue : numberValue;
}

export function emToPercent(em: number): number {
  return Math.floor((1 + em) * 100);
}

export function percentToEm(percent: number): number {
  return percent / 100 - 1;
}

export function getUnmatchedTexts(fullText: string, currentTexts: string[]): string[] {
  return currentTexts.filter(text => {
    const matchedCount = [...fullText.matchAll(new RegExp(escapeRegExp(text), 'g'))].length;
    const currentCount = currentTexts.filter(currentText => currentText === text).length;
    return currentCount !== matchedCount;
  });
}

function escapeRegExp(text: string): string {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

interface CheckFullyOverlapItem {
  start: number;
  end: number;
}

export function checkFullyOverlapped(targetItem: CheckFullyOverlapItem, items: Partial<CheckFullyOverlapItem>[]): boolean {
  return items.every(({ start, end }) => {
    if (targetItem.end <= start || targetItem.start >= end) {
      return true;
    } else if (targetItem.start >= start && targetItem.end <= end) {
      return true;
    }
    return false;
  });
}

export interface AvailableTextArea {
  start: number;
  end: number;
}

export function getAvailableText(text: string, pattern: RegExp, diff = 0): AvailableTextArea | undefined {
  const matches = pattern.exec(text);
  const targetText = matches?.[1];
  if (targetText) {
    const start = text.indexOf(targetText) + diff;
    const end = start + targetText.length;
    return { start, end };
  }
  return undefined;
}
