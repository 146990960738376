import type {
  LotteQaEmotion,
  LotteQaQA,
  LotteQaSpeaker,
  LotteQaWorkable
} from '@bluewhale/ngx-annotator/tool/lotte-qa/model';
import { AnnotatedBaseItem, Resource } from './interfaces';

export interface LotteSentence {
  speaker: LotteQaSpeaker;
  text: string;
}

export interface LotteQaResource extends Resource {
  sentences: LotteSentence[];
}

export interface LotteQaItem extends AnnotatedBaseItem {
  workable: LotteQaWorkable;
  qa: LotteQaQA;
  qaNumber: number;
  emotion: LotteQaEmotion;
  intent0: string;
  intent1: string;
  intent2: string;
  prices: string[];
  quantities: string[];
  sizes: string[];
  times: string[];
  dates: string[];
  goods: string[];
  places: string[];
  organizations: string[];
  persons: string[];
}

export const DELIMITER = String.fromCharCode(11);
