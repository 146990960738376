import { CLASS_KEY, EMPTY_PIXEL_DEFAULT_ALPHA } from '@aimmo/annotator-model';
import { DEFAULT_IMAGE_POINT_SIZE, ModeType, ScaleCondition } from '@bluewhale/ngx-annotator/tool/image/model';
import { TemplateType } from '../enums';
import { AnnotatedItem, SequenceTemplateOption } from '../interfaces';
import { Annotator } from './annotator';

export const DEFAULT_IMAGE_CACHE_SIZE = 250;
export const DEFAULT_THUMBNAIL_CACHE_SIZE = 500;
export const DEFAULT_PRELOAD_SIZE = 100;

// @dynamic
export class SequenceAnnotator extends Annotator {
  private static instance: SequenceAnnotator;

  private constructor() {
    super();
  }

  public get code(): TemplateType {
    return TemplateType.object_tracking;
  }

  public static getInstance(): SequenceAnnotator {
    if (!this.instance) {
      this.instance = new SequenceAnnotator();
    }
    return this.instance;
  }

  public getAnnotatedAnswerItems(annotations: AnnotatedItem[]): AnnotatedItem[] {
    return annotations;
  }

  public override convertAnnotations(annotatedItems: AnnotatedItem[] = []): AnnotatedItem[] {
    return annotatedItems;
  }

  public toString(): string {
    return 'SequenceAnnotator[object_tracking]';
  }

  public getDefaultTemplateOption(): SequenceTemplateOption {
    return {
      labelKeys: [CLASS_KEY],
      labelCustomOptions: [],
      checkFullSegmentation: false,
      checkFullSegmentationAlphaValue: EMPTY_PIXEL_DEFAULT_ALPHA,
      useMagicCrop: false,
      usePan: true,
      defaultMode: ModeType.bbox,
      useBbox: true,
      usePolyline: false,
      usePolylinePoint: false,
      useObboxV2: false,
      useExtremeBbox: false,
      usePolygon: false,
      useMinimap: true,
      useBrightness: true,
      useSemanticPolygon: false,
      useKeypoint: false,
      useContrast: true,
      useSaturate: true,
      scaleCondition: ScaleCondition.Default,
      useCroppedTaskImage: false,
      isShowSideArea: true,
      useAnnotationDragdropOrdering: true,
      hideReset: true,
      useAutoInherit: true,
      useDotNumberText: false,
      useObjectTrackingAIAssist: false,
      attributeColorInfo: undefined,
      latex: {
        useLatex: false,
        expressionAttributeKey: undefined,
        expressionClassValue: undefined,
        tokenClassValue: undefined,
        tokenOrderAttributeKey: undefined,
        tokenValueAttributeKey: undefined,
        latexFullTextTokenizerCharacterInfos: '{}'
      },
      imageCacheSize: DEFAULT_IMAGE_CACHE_SIZE,
      thumbnailCacheSize: DEFAULT_THUMBNAIL_CACHE_SIZE,
      preloadSize: DEFAULT_PRELOAD_SIZE,
      showCrossLine: false,
      showOriginDistanceGuide: undefined,
      useCuboid: false,
      useCuboidPlain: false,
      instanceCreatable: true,
      instanceCoordinateChangable: true,
      instanceDeletable: true,
      useToggleBackgroundROIS: false,
      minGroupBySelectCount: 2,
      polylineStrokeWidth: 1,
      firstPointStyle: null,
      usePointSize: false,
      defaultPointSize: DEFAULT_IMAGE_POINT_SIZE,
      useFloat: false
    };
  }
}
