import { BrushAnswerItem, BrushItem } from '../brush-model';
import { TemplateType } from '../enums';
import { AnnotatedItem, TemplateOption } from '../interfaces';
import { Annotator } from './annotator';

export interface SegmentationMaskTemplateOption extends TemplateOption {
  checkFullSegmentation?: boolean;
  aiAssist?: boolean;
}

// @dynamic
export class SegmentationMaskAnnotator extends Annotator {
  private static instance: SegmentationMaskAnnotator;

  private constructor() {
    super();
  }

  public get code(): string {
    return TemplateType.segmentationMask;
  }

  public static getInstance(): SegmentationMaskAnnotator {
    if (!this.instance) {
      this.instance = new SegmentationMaskAnnotator();
    }
    return this.instance;
  }

  public getDefaultTemplateOption(): SegmentationMaskTemplateOption {
    return {
      isShowSideArea: true,
      useAnnotationDragdropOrdering: false,
      aiAssist: true,
      checkFullSegmentation: true
    };
  }

  public getAnnotatedAnswerItems(items: BrushItem[]): BrushAnswerItem[] {
    return items.map(({ id, type, attributes, bound, mask }) =>
      ({ id, type, attributes, bound, mask } as BrushAnswerItem));
  }

  public toString(): string {
    return 'SegmentationMaskAnnotator[segmentation_mask]';
  }

  public override convertAnnotations(annotations: AnnotatedItem[]): AnnotatedItem[] {
    return annotations.map(annotatedItem => {
      annotatedItem.visible = true;
      return annotatedItem;
    });
  }
}
