import { Coordinate, DataType } from '@aimmo/annotator-model';
import { distanceBetweenPoints, unitize } from '@aimmo/utils/point';
import {
  DataTypeToItemTypeMap,
  ItemType,
  ItemTypeToDataType,
  ModeType
} from '@bluewhale/ngx-annotator/tool/image/model';

export function unitizeNumbers(numbers: number[], unit: number): number[] {
  return numbers.map(num => unitize(num, unit));
}

export function convertToPath(points: Coordinate[]): string {
  const targetPoints = [];
  points.forEach(point => targetPoints.push(...point));
  const x0 = targetPoints.shift();
  const y0 = targetPoints.shift();
  return 'M' + x0 + ',' + y0 + 'L' + targetPoints.join(' ') + 'z';
}

export function distanceToSegment(newPoint: Coordinate, point1: Coordinate, point2: Coordinate): number {
  const line = distanceBetweenPoints(point1, point2);
  if (line === 0) {
    return distanceBetweenPoints(newPoint, point1);
  }
  let t = ((newPoint[0] - point1[0]) * (point2[0] - point1[0]) + (newPoint[1] - point1[1]) * (point2[1] - point1[1])) / line;
  t = Math.max(0, Math.min(1, t));
  const distToSegmentSquared = distanceBetweenPoints(newPoint, [point1[0] + t * (point2[0] - point1[0]), point1[1] + t * (point2[1] - point1[1])]);
  return Math.sqrt(distToSegmentSquared);
}

export function convertDataTypeToItemType(type: DataType, throwErrorOff = false): ItemType {
  if (!DataTypeToItemTypeMap.hasOwnProperty(type)) {
    if (throwErrorOff) {
      return null;
    } else {
      throw Error(`${type} 존재하지 않는 데이터 타입입니다.`);
    }
  }
  return DataTypeToItemTypeMap[type];
}


export function convertItemTypeToDataType(type: ItemType): DataType {
  if (!ItemTypeToDataType.hasOwnProperty(type)) {
    throw Error(`${type} 존재하지 않는 아이템 타입입니다.`);
  }
  return ItemTypeToDataType[type] as DataType;
}

export function isLassoToolMode(modeType: ModeType): boolean {
  return [ModeType.lasso, ModeType.lassoAdd, ModeType.lassoRemove].includes(modeType);
}

export function isLassoRetouchMode(modeType: ModeType): boolean {
  return [ModeType.lassoAdd, ModeType.lassoRemove].includes(modeType);
}

export function isPointAddRemoveMode(modeType: ModeType): boolean {
  return [ModeType.pointAdd, ModeType.pointRemove].includes(modeType);
}
