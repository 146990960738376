import { BoxDirection, Coordinate, ImageSize } from '@aimmo/annotator-model';
import { DatasetType } from '@aimmo/eimmo/models';
import { LazyPolygonClipping } from '@aimmo/lazy/svg-js';
import { distanceBetweenPoints, unitize } from '@aimmo/utils/point';
import { FileMetaData } from 'app/shared/models/studio.model';
import { cloneDeep, isEmpty, isNil } from 'lodash-es';
import type { MultiPolygon } from 'polygon-clipping';
import { forkJoin, fromEvent, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

interface Point {
  x: number;
  y: number;
}

export interface ViewboxOptions {
  x: number;
  width: number;
  y: number;
  height: number;
}

export interface RectXYWH {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface BoxResizeParam {
  xGrowth: number;
  yGrowth: number;
  originalCoordinates: Coordinate[];
  boxDirection: BoxDirection;
  freePointMode: boolean;
}

export interface GetBoxResizeDirectionParam {
  xGrowth: number;
}

export const ZOOM_UNIT = 0.05;

export function getPixelColorFromContext(context: CanvasRenderingContext2D, x: number, y: number): Uint8ClampedArray {
  try {
    return context.getImageData(x, y, 1, 1).data;
  } catch (err) {
    return Uint8ClampedArray.from([0, 0, 0, 0]);
  }
}

export function resizeBox({
                            xGrowth,
                            yGrowth,
                            originalCoordinates,
                            boxDirection,
                            freePointMode
                          }: BoxResizeParam): Coordinate[] {
  const currentPoints = cloneDeep(originalCoordinates);
  let addingValue = 0;
  switch (boxDirection) {
    case BoxDirection.Top:
      addingValue = -xGrowth;
      currentPoints[0][1] += addingValue;
      if (!freePointMode) {
        currentPoints[1][1] = currentPoints[0][1];
      } else {
        currentPoints[1][1] += addingValue;
      }
      break;
    case BoxDirection.Right:
      addingValue = xGrowth;
      currentPoints[1][0] += addingValue;
      if (!freePointMode) {
        currentPoints[2][0] = currentPoints[1][0];
      } else {
        currentPoints[2][0] += addingValue;
      }
      break;
    case BoxDirection.Bottom:
      addingValue = yGrowth;
      currentPoints[2][1] += addingValue;
      if (!freePointMode) {
        currentPoints[3][1] = currentPoints[2][1];
      } else {
        currentPoints[3][1] += addingValue;
      }
      break;
    case BoxDirection.Left:
      addingValue = -yGrowth;
      currentPoints[0][0] += addingValue;
      if (!freePointMode) {
        currentPoints[3][0] = currentPoints[0][0];
      } else {
        currentPoints[3][0] += addingValue;
      }
      break;
  }

  currentPoints[1][0] = currentPoints[1][0] < currentPoints[0][0] ? currentPoints[0][0] : currentPoints[1][0];
  currentPoints[2][0] = currentPoints[2][0] < currentPoints[3][0] ? currentPoints[3][0] : currentPoints[2][0];
  currentPoints[2][1] = currentPoints[2][1] < currentPoints[1][1] ? currentPoints[1][1] : currentPoints[2][1];
  currentPoints[3][1] = currentPoints[3][1] < currentPoints[0][1] ? currentPoints[0][1] : currentPoints[3][1];
  return currentPoints;
}

export function getResizeDirection({ xGrowth }: GetBoxResizeDirectionParam): BoxDirection {
  // 위 아래 or 가로
  return xGrowth === 0 ? BoxDirection.Bottom : BoxDirection.Right;
}

export function getCircularIndex(upcomingIndex: number, length: number): number {
  return (upcomingIndex % length + length) % length;
}

export function getImageUrlSize(url: string): Observable<ImageSize> {
  return new Observable(observer => {
    const image = new Image();
    image.src = url;
    const destroyedSource = new Subject<void>();
    forkJoin([
      fromEvent(image, 'load').pipe(
        tap(() => {
          observer.next({ width: image.naturalWidth, height: image.naturalHeight });
          observer.complete();
        })
      ),
      fromEvent(image, 'error').pipe(tap(err => observer.error(err)))
    ]).pipe(takeUntil(destroyedSource)).subscribe();
    return () => destroyedSource.next();
  });
}

export function getImageSize(image: SVGImageElement | HTMLImageElement | ImageBitmap): {
  width: number;
  height: number;
} {
  if (image instanceof HTMLImageElement) {
    const { naturalWidth: width, naturalHeight: height } = image;
    return { width, height };
  } else if (image instanceof SVGImageElement) {
    const [width, height] = [parseInt(image.getAttribute('width'), 10), parseInt(image.getAttribute('height'), 10)];
    return { width, height };
  } else if (image instanceof ImageBitmap) {
    const { width, height } = image;
    return { width, height };
  }
  return { width: 0, height: 0 };
}

export function validateBase64Url(url: string): boolean {
  return /^data:image/.test(url);
}

export function validateUrl(url: string): boolean {
  return /^http[s]?:\/\/([\S]{3,})/i.test(url);
}

export const PROXY_LIGHTING_IMPROVED_IMAGE_URL = 'https://image-backend-proxy.azurewebsites.net/predict/lle/lle-torch:1/';

export function convertLightingImprovedImageUrl(url: string): string {
  const proxyUrl = PROXY_LIGHTING_IMPROVED_IMAGE_URL;
  if (!url || !proxyUrl || url.includes(proxyUrl)) {
    return url;
  }
  return `${proxyUrl}${url.replace('://', '/')}`;
}

// 피타고라스 정리
export function getDistanceBetweenTwoPoint(startPoint: Coordinate, endPoint: Coordinate): number {
  return Math.sqrt(Math.pow(endPoint[0] - startPoint[0], 2) + Math.pow(endPoint[1] - startPoint[1], 2));
}

export function connectTwoPolygons(mainPolygon: Coordinate[], targetPolygon: Coordinate[]): Coordinate[] {
  if (!mainPolygon && !targetPolygon) {
    console.warn('mainPolygon, targetPolygon are not exists', mainPolygon, targetPolygon);
    return [];
  }

  if (!mainPolygon) {
    return targetPolygon;
  }

  if (!targetPolygon) {
    return mainPolygon;
  }

  const {
    closestIndexForFirstPolygon,
    closestIndexForLastPolygon
  } = getClosestIndexesBetweenTwoPolygon(mainPolygon, targetPolygon);

  // 가까운 point index 로 배열 한바퀴 돌리고 시작 포인트 추가
  const newTargetPolygon = [...targetPolygon.slice(closestIndexForLastPolygon), ...targetPolygon.slice(0, closestIndexForLastPolygon), targetPolygon[closestIndexForLastPolygon]];

  return [...mainPolygon.slice(0, closestIndexForFirstPolygon + 1), ...newTargetPolygon, mainPolygon[closestIndexForFirstPolygon], ...mainPolygon.slice(closestIndexForFirstPolygon)];
}

export function getClosestIndexesBetweenTwoPolygon(polygonA: Coordinate[], polygonB: Coordinate[]): {
  closestIndexForFirstPolygon: number,
  closestIndexForLastPolygon: number,
  minDistance: number
} {
  let closestIndexForFirstPolygon = null;
  let closestIndexForLastPolygon = null;
  let minDistance = Number.MAX_SAFE_INTEGER;

  polygonA.forEach((coordA, indexA) => {
    polygonB.forEach((coordB, indexB) => {
      const distance = getDistanceBetweenTwoPoint(coordA, coordB);
      if (minDistance > distance) {
        minDistance = distance;
        closestIndexForFirstPolygon = indexA;
        closestIndexForLastPolygon = indexB;
      }
    });
  });

  return {
    closestIndexForFirstPolygon,
    closestIndexForLastPolygon,
    minDistance
  };
}

export function getClosestCoordinateIndex(points: Coordinate[], x: number, y: number): number {
  let minDistance = Number.MAX_SAFE_INTEGER;
  let minIndex = -1;

  points.forEach((point, index) => {
    const distance = distanceBetweenPoints([x, y], point);
    if (minDistance > distance) {
      minDistance = distance;
      minIndex = index;
    }
  });

  return minIndex;
}

export function transformMultiPolygonToPolygonByConnection(multiPolygon: MultiPolygon): Coordinate[] {
  const resultPolygon = multiPolygon.map(polygon => polygon.reduce((connectedPolygonRing, polygonRing) => connectTwoPolygons(connectedPolygonRing, polygonRing), null))
    .reduce((connectedPolygon, polygon) => connectTwoPolygons(connectedPolygon, polygon), null);

  if (resultPolygon === null) {
    console.error('MultiPolygon to Polygon transform failed.', multiPolygon, resultPolygon);
    return [];
  }

  return resultPolygon;
}

export function roundMultiPolygon(multiPolygon: MultiPolygon): MultiPolygon {
  return multiPolygon
    .map(polygonList => polygonList
      .map(polygon => polygon
        .map(coordinate => coordinate
          .map(coord => unitize(coord, 1))))) as MultiPolygon;
}

export function cropPolygon(targetCoordinates: Coordinate[], removalCoordinates: Coordinate[][], useFloat: boolean): Coordinate[] | undefined {
  const targetMultiPolygon = [targetCoordinates];
  const removalMultiPolygon = removalCoordinates.map(coordinate => [coordinate]);

  const unionMultiPolygon = useFloat ? LazyPolygonClipping.union(removalMultiPolygon) : roundMultiPolygon(LazyPolygonClipping.union(removalMultiPolygon));
  const differenceMultiPolygon = useFloat ? LazyPolygonClipping.difference(targetMultiPolygon, unionMultiPolygon) : roundMultiPolygon(LazyPolygonClipping.difference(targetMultiPolygon, unionMultiPolygon));

  // TargetPolygon 이 AbovePolygon 안에 전부 내포 되어있는 경우 (ex) 도넛
  if (isEmpty(differenceMultiPolygon)) {
    return undefined;
  }

  const resultPolygonCoordinates = transformMultiPolygonToPolygonByConnection(differenceMultiPolygon);

  if (!resultPolygonCoordinates || resultPolygonCoordinates.length < 3) {
    console.error('cropPolygon is failed. [no result]', resultPolygonCoordinates);
    return undefined;
  }

  return resultPolygonCoordinates;
}

export function cropPolygonToSplit(targetCoordinates: Coordinate[], removalCoordinates: Coordinate[][], useFloat: boolean): Coordinate[][] {
  const targetMultiPolygon = [targetCoordinates];
  const removalMultiPolygon = removalCoordinates.map(coordinate => [coordinate]);

  const unionMultiPolygon = useFloat ? LazyPolygonClipping.union(removalMultiPolygon) : roundMultiPolygon(LazyPolygonClipping.union(removalMultiPolygon));
  const differenceClippingResult = useFloat ? LazyPolygonClipping.difference(targetMultiPolygon, unionMultiPolygon) : roundMultiPolygon(LazyPolygonClipping.difference(targetMultiPolygon, unionMultiPolygon));

  // TargetPolygon 이 AbovePolygon 안에 전부 내포 되어있는 경우 (ex) 도넛
  if (isEmpty(differenceClippingResult)) {
    return undefined;
  }

  if (differenceClippingResult.length > 1) {
    return differenceClippingResult.reduce((accPolygonList, currPolygonList) => [...accPolygonList, ...currPolygonList], []);
  } else {
    return [transformMultiPolygonToPolygonByConnection(differenceClippingResult)];
  }
}

export function checkIfPointIsOnLineBetweenTwoPoints(point1: Point, point2: Point, point: Point, lineThickness: number): boolean {
  const distance = (((point2.x - point1.x) * (point2.x - point1.x)) + ((point2.y - point1.y) * (point2.y - point1.y)));
  if (distance === 0) {
    return false;
  }
  const r = (((point.x - point1.x) * (point2.x - point1.x)) + ((point.y - point1.y) * (point2.y - point1.y))) / distance;

  if (r < 0) {
    return (Math.sqrt(((point1.x - point.x) * (point1.x - point.x)) + ((point1.y - point.y) * (point1.y - point.y))) <= lineThickness);
  } else if ((0 <= r) && (r <= 1)) {
    const s = (((point1.y - point.y) * (point2.x - point1.x)) - ((point1.x - point.x) * (point2.y - point1.y))) / distance;
    return (Math.abs(s) * Math.sqrt(distance) <= lineThickness);
  } else {
    return (Math.sqrt(((point2.x - point.x) * (point2.x - point.x)) + ((point2.y - point.y) * (point2.y - point.y))) <= lineThickness);
  }
}

export function convertAnyBase64ToBase64Png(dataUrl: string): Observable<string> {
  return new Observable<string>(observer => {
    const destroyedSource = new Subject<void>();
    const image = new Image();

    const loadHandler = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      ctx?.drawImage(image, 0, 0);
      observer.next(canvas.toDataURL('image/png'));
      observer.complete();
    };

    forkJoin([
      fromEvent(image, 'load').pipe(tap(loadHandler)),
      fromEvent(image, 'error').pipe(tap(err => observer.error(err)))
    ]).pipe(takeUntil(destroyedSource)).subscribe();

    image.src = dataUrl;

    return () => destroyedSource.next();
  });
}

export function getImageVolumeFromBase64(base64Url: string): number {
  const arr = base64Url.split(',');
  const bstr = atob(arr[1]);
  return bstr.length;
}

export function checkNeedToUpdateMeta(datasetType: DatasetType, meta: FileMetaData, base64Url: string): boolean {
  return datasetType === DatasetType.image && (isNil(meta?.height) || isNil(meta?.width)) && !isNil(base64Url);
}
