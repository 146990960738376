export enum NgForageName {
  gtaasEimmo = 'gtaas-eimmo',
  gtaasEimmoJp = 'gtaas-eimmo-jp',
  gtaasEimmoSaas = 'gtaas-eimmo-saas',
  gtaasEimmoAlpha = 'gtaas-eimmo-alpha',
  gtaasEimmoFeature = 'gtaas-eimmo-feature',
  gtaasEimmoDev = 'gtaas-eimmo-dev',
  gtaasEimmoDevSaaS = 'gtaas-eimmo-dev-saas',
  gtaasEimmoQaSaaS = 'gtaas-eimmo-qa-saas',
  eimmo = 'eimmo',
  eimmoDev = 'eimmo-dev',
}
