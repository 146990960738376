export enum DataType {
  Bbox = 'bbox',
  FlatCuboid = 'cuboidPlain',
  ObboxV2 = 'obbox_v2',
  Tbox = 'tbox',
  Polygon = 'polygon',
  Polygon3d = 'polygon_3d',
  SemanticPolygon = 'semanticPolygon',
  SegmentationMask = 'segmentation_mask',
  Lasso = 'lasso',
  Polyline = 'polyline',
  PolylinePoint = 'polyline_point',
  PolylinePoint3d = 'polyline_point_3d',
  Cuboid = 'cuboid',
  Group = 'group',
  Video = 'video',
  Frame = 'frame',
  Text = 'text',
  Keypoint = 'landmark', // @deprecated landmark
  KeypointOD = 'landmark_hd_od', // @deprecated landmark
  TextCollect = 'textCollect',
  FrameRange = 'frameRange',
  image = 'image',
  Point = 'point',
  Pair = 'Pair',
  Entity = 'entity',
  LotteQaRow = 'lotte_qa_row',
  classification = 'classification',
}

export enum SizeUnit {
  px = 'px',
  percent = 'percent',
}
