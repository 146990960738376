export type Coordinate = [x: number, y: number];

export interface EdgeInfo {
  keyPointIds: string[];
  color: string;
  opacity: number;
  strokeWidth: number;
}

export interface BoundingRect {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export interface BoundaryBaseInfo {
  baseTop: number;
  baseLeft: number;
  baseWidth: number;
  baseHeight: number;
}

export type BoundCoordinates = [[top: number, left: number], [bottom: number, right: number]];

export interface EdgeLabelInfo {
  edgeLabelKey: string;
  validationTargets: string[];
}

export interface ImageSize {
  width: number;
  height: number;
}

export enum BoxDirection {
  Unselected = -1,
  TopLeft = 0,
  TopRight = 1,
  BottomRight = 2,
  BottomLeft = 3,
  Top = 4,
  Right = 5,
  Bottom = 6,
  Left = 7,
  RotaterOrFirstExtended = 8,
  SecondExtended = 9,
}

export const EMPTY_PIXEL_DEFAULT_ALPHA = 1;

export enum BoundaryDirection {
  TopLeft,
  TopMiddle,
  BottomMiddle
}
