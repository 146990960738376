import type {
  BoundCoordinates,
  BoundingRect,
  CLASS_KEY,
  Coordinate,
  DataType,
  EdgeLabelInfo,
  ImageSize
} from '@aimmo/annotator-model';
import type { SourceType } from '@aimmo/utils';
import type {
  AnnotationAttribute,
  AnnotationAttributeAnswer,
  AttributeExtensionOptionRecordByAttributeKey,
  AttributeShortcutKey,
  ClassExtensionOptionRecordByDataType,
  MappedAnswer
} from '@bluewhale/ngx-annotator/components/attributes/model';
import type { ActionModes } from '@bluewhale/ngx-annotator/keyboard/model';
import type { FirstFrameBboxInfo } from '@bluewhale/ngx-annotator/tool/frame-range/model';
import { CustomSvgImageInfo } from '@bluewhale/ngx-annotator/tool/image';
import type {
  CuboidFaceOption,
  FlatCuboidFaceOption,
  GeometryInfo,
  InvalidMessage,
  ItemType,
  KeyPoint,
  KeypointODVersion,
  ModeType,
  PointLabel,
  PolygonGridOptions,
  Rect,
  ScaleCondition
} from '@bluewhale/ngx-annotator/tool/image/model';
import type { RenderOption } from '@bluewhale/ngx-annotator/tool/point-cloud/model';
import type { ShortcutDescription } from '@falcon/common/keyboardjs-helper/model';
import type { PhotoContext, StageContext } from 'app/shared/models/studio.model';
import { DistanceGuideInfo } from '../../tool/image/src/utils/interfaces/interfaces';
import { ChoiceByAnnotationType, ValidationOption } from './attributes-model';
import { BrushItem } from './brush-model';
import {
  AnnotatedItemEvent,
  AnnotatorEventType,
  AreaIncludeRelationOption,
  FrameSelectType,
  MainViewActionType,
  TemplateType,
  ViewerActionPhase,
  ViewerActionType
} from './enums';
import {
  BboxCustomVerticalDivider,
  BboxEllipseOption,
  BboxGridOptions,
  BboxInsidePointOption,
  ImageAnnotatedItem,
  ImageOBBoxV2Item,
  ImagePolylinePointItem,
  ImageTextBoxItem,
  ImageTokenizerItem,
  KeypointAnnotatedItem
} from './image-model';
import { LotteQaItem, LotteSentence } from './lotte-qa-model';
import type {
  PointCloudChannel,
  PointCloudFrame,
  PointCloudGeometry,
  PointCloudItem,
  PointCloudLinePointItem,
  PointCloudPosition,
  PointCloudRotation
} from './point-cloud-model';
import { TextItem } from './text-model';
import { TextV2Item } from './text-v2-model';
import { ResourceVideo, VideoItem } from './video-model';

export enum InvalidType {
  invalidPixel = 'invalidPixel'
}

export interface SizeCandidate {
  name: string;
  value: string;
  width: number;
  height: number;
  depth?: number;
  [CLASS_KEY]?: string;
}

export interface LabelInfo {
  color?: string;
  label?: string;
  value?: string;
  spec?: SizeCandidate;
}

export enum CanvasClassName {
  CanvasRenderer = 'canvas-renderer'
}

export interface MoveParams {
  actualX: number;
  actualY: number;
  actualStartingX?: number;
  actualStartingY?: number;
  byKeyboard?: boolean;
}

export interface ResourceDicom {
  url: string;
}

export interface ResourceImage {
  url: string;
}

export interface ResourcePointCloud {
  url: string;
}

export interface PointCloudMappingImage {
  type: CameraPositionType;
  url: string;
  name: string;
}

export interface UrlLoadError {
  error: Error;
  errorLoadLightingImprovedImage?: boolean;
}

export enum CameraPositionType {
  front = 'front',
  rear = 'rear',
  left = 'left',
  right = 'right',
  tele = 'tele',
  frontLeft = 'frontLeft',
  frontRight = 'frontRight',
}

// 프로젝트 생성 시 표기 순서대로 정리
export enum AnnotationType {
  bbox = 'bbox', // Bounding box
  keypoint = 'landmark', // server side 에서 아직 변경전 추후 변경 되면 keypoint로 변경만 하면됨
  keypointOD = 'landmark_hd_od',
  obboxV2 = 'obbox_v2', // Rotated B-box
  polySeg = 'poly_seg', // Segmentation
  polygon = 'polygon',
  polygon3d = 'polygon_3d',
  polyline = 'polyline',
  polylinePoint = 'polyline_point',
  polylinePoint3d = 'polyline_point_3d',
  point = 'point',
  point3d = 'point_3d',
  cuboid2d = 'cuboid_2d', // 3D Cuboid
  flatCuboid = 'cuboid_plain', // Flat Cuboid
  segmentationMask = 'segmentation_mask', // Single Image(pixel) Segmentation
  cuboid = 'cuboid', // PointCloud 3D Cuboid
  lasso = 'lasso', // PointCloud Segmentation
  frameRange = 'frame_range',
  entity = 'entity',
  lotteQaRow = 'lotte_qa_row',
  classification = 'resource', // server side 에서 아직 변경전 추후 변경 되면 classification로 변경만 하면됨
  multiClassification = 'multi_classification',
  // TODO rex 아래 사용되지 않는다고 함. 재확인필요. https://app.asana.com/0/1179494858925986/1203619649938210/f
  obbox = 'obbox', // @deprecated => 센트리 오류를 막기 위해 polygon 으로 변경
  bitmap = 'bitmap',
  pair = 'pair',
}

export enum PolySegmentationModel {
  semantic = 'semantic_segmentation',
  instance = 'instance_segmentation'
}

export enum BboxModel {
  object_Detection = 'object_detection'
}

export type AnnotationTypeModel = PolySegmentationModel | BboxModel;

export interface ClassFeature {
  name: string;
  color: string;
  spec?: Partial<ValidationOption>;
}

export interface ClassFeatureMap {
  [key: string]: ClassFeature;
}

export type ClassFeatureMapByType = {
  [type in DataType]?: ClassFeatureMap;
};

export type ClassFeatureMapByAnnotationType = {
  [type in AnnotationType]?: ClassFeatureMap;
};

export interface LoadFrameAnswerParams {
  start: number;
  totalCount: number;
  subCount: number;
  step: number;
}

// TODO any 제거 https://app.asana.com/0/1179494858925986/1200401931720685/f
export interface AnnotationFilter {
  classFilters?: { [key: string]: any; };
  commonFilters?: { [key: string]: any; };

  [key: string]: any;
}

export type AnnotationFilterByType = {
  [key in AnnotationType]: AnnotationFilter[];
} | {};

export type AnnotationAttributesByType = {
  [key in AnnotationType]: AnnotationAttribute[];
} | {};

export interface KeyboardShortcut {
  [key: string]: string[];
}

export interface FeatureEnabled {
  deletable: boolean;
  modifiable: boolean;
}

export interface AnnotatorOption<T extends TemplateOption> {
  isEmbedMode: boolean;
  visibleValidationInfo?: boolean;
  visibleControlArea?: boolean;
  visibleHeaderArea?: boolean;
  keyboardShortcut?: KeyboardShortcut;
  visibleBottomControlPanel?: boolean;

  annotationAttributes?: AnnotationAttribute[]; // @Deprecated. Use annotationAttributesByType instead
  annotationAttributesByType?: AnnotationAttributesByType;

  featureEnabled?: FeatureEnabled;
  template?: T;
}

export interface FrameOfTask {
  mappingImages: {
    type: string;
    name: string;
    url: string
  }[];
  point: {
    url: string
  };
}

export interface BackgroundROI {
  name: string;
  matcher: string;
  matcherSize?: ImageSize;
  backgroundItems: BackgroundItem[];
}

export interface Resource {
  start?: number;
  step?: number;
  count?: number;
  index?: number;

  video?: ResourceVideo;
  image?: ResourceImage;
  point?: ResourcePointCloud;
  dicom?: ResourceDicom;
  images?: ResourceImage[];
  frames?: FrameOfTask[] | PointCloudFrame[];

  // frameRange
  videoUrl?: string;
  frameRate?: number;
  frameCount?: number;

  // text
  text?: string;

  // lotteQA
  sentences?: LotteSentence[];

  // annotator.save.service
  fileId?: string;

  // sequence
  baseImageUrl?: string;
  imagePadding?: number;
  urlPadding?: number;
  minimumTrackId?: number;

  // point cloud
  basePointUrl?: string;
}

export interface AttributeColorInfo {
  targetKey: string;
  colorMap: {
    [value: string]: string;
  };
}

export interface ImageBaseTemplateOption extends TemplateOption {
  checkFullSegmentation: boolean;
  checkFullSegmentationAlphaValue: number;
  useMagicCrop: boolean;
  useBbox: boolean;
  useExtremeBbox: boolean;
  usePolygon: boolean;
  useSemanticPolygon: boolean;
  useKeypoint: boolean;
  usePan: boolean;
  useMinimap: boolean;
  usePolyline: boolean;
  usePolylinePoint: boolean;
  useObboxV2: boolean;
  defaultMode: ModeType;
  useBrightness: boolean;
  useContrast: boolean;
  useSaturate: boolean;
  latex: LatexTemplateOption;
  showCrossLine: boolean;
  showOriginDistanceGuide: ShowOriginDistanceGuideTemplateOption;
  useKeypointOD?: boolean;
  useCuboid?: boolean;
  useCuboidPlain?: boolean;
  reIndexPolygonPoints?: boolean;
  showKeypointBox?: boolean;
  ellipseMode?: boolean;
  useItemBoundary?: boolean;
  boundaryBuffer?: BoundingRect;
  invisibleKeypointColor?: string;
  polygonShowFirstLine?: boolean;
  polygonGridOptions?: PolygonGridOptions;
  bboxShowBottomCenter?: boolean;
  bboxGridOptions?: BboxGridOptions;
  bboxInsidePoint?: BboxInsidePointOption;
  bboxEllipse?: BboxEllipseOption;
  bboxCustomVerticalDivider?: BboxCustomVerticalDivider;
  isShowAllBboxCustomVerticalDivider?: boolean;
  useDifferentInstanceColorForKeypoint?: boolean;
  guideImageWidthForKeypoint?: number;
  areaIncludeRelation?: AreaIncludeRelation;
  useParentingGroup?: boolean;
  minGroupBySelectCount: number;
  useUniqueGroupId?: boolean;
  usePixelated?: boolean;
  keypointODVersion?: KeypointODVersion;
  instanceDeletable: boolean;
  useToggleBackgroundROIS: boolean;
  edgeLabelInfo?: EdgeLabelInfo;
  polylineStrokeWidth: number;
  polylineShowFirstLine?: boolean;
  firstPointStyle: FirstPointStyle;
  usePointSize: boolean;
  displayPointLabel?: boolean;
  defaultPointSize: number;
  flatCuboidFaceOption?: FlatCuboidFaceOption;
  cuboidFaceOption?: CuboidFaceOption;
  useCornerAndExtremeBbox?: boolean;
  flatCuboidRotateMode?: boolean;
  flatCuboidFreePointMode?: boolean;
  flatCuboidFreePointToDefault?: boolean;
  selectBoxAllPointInsideMode?: boolean;
  useLightingImprovedImage?: boolean;
  useFloat?: boolean;
}

export interface FirstPointStyle {
  use: boolean;
  color: string;
  opacity: number;
  radius: number;
}

export interface LatexTemplateOption {
  useLatex: boolean;
  expressionAttributeKey?: string;
  expressionClassValue?: string;
  tokenClassValue?: string;
  tokenOrderAttributeKey?: string;
  tokenValueAttributeKey?: string;
  latexFullTextTokenizerCharacterInfos: string;
}

export type ShowOriginDistanceGuideTemplateOption = DistanceGuideInfo;

export interface CropParamTemplateOption extends Partial<ImageBaseTemplateOption> {
  isShowAllBboxCustomVerticalDivider?: boolean;
}

export interface AreaIncludeRelation {
  [AreaIncludeRelationOption.parentClass]: string;
  [AreaIncludeRelationOption.childClass]: string;
}

export interface AreaIncludeRelationAnnotatedItem {
  parent: AnnotatedItem;
  children: AnnotatedItem[];
}

export interface DisplayLabelOption {
  displayLabel: string;
  font?: string;
  color?: string;
  size?: number;
  newLine?: boolean;
}

export interface LabelCustomOption {
  key: string;
  font?: string;
  color?: string;
  classKey?: string;
  newLine?: boolean;
}

export interface LabelOptionForAnnotationType {
  annotationType: AnnotationType;
  labels: LabelCustomOption[];
}

export interface PhotoContextMappingInfo {
  fileName: string;
  photoContexts: PhotoContext[];
}

export interface ExternalValidationApi {
  url: string;
  params?: Record<string, string | number>;
  stageNames?: string[];
}

export interface TemplateOption {
  labelKeys?: string[];
  displayLabels?: boolean;
  labelFontSize?: number;
  labelCustomOptions?: LabelOptionForAnnotationType[];
  forcedColor?: boolean;
  isShowSideArea?: boolean; // side area 활성화 여부
  useAnnotationDragdropOrdering?: boolean; // side 영역의 annotatedItem 드래그드랍 가능 여부

  code?: TemplateType;
  specialCharacters?: string[];
  useAnnotationFilter?: boolean; // annotation filter 사용 여부
  keyboardShortcut?: {
    [key: string]: string[];
  };
  typeByClassFeature?: ClassFeatureMapByType;
  attributeColorInfo?: AttributeColorInfo;
  itemCountForVirtualScrollActivating?: number;
  defaultClass?: boolean;
  globalShortcuts?: ShortcutDescription[];
  instanceCreatable?: boolean;
  instanceCoordinateChangable?: boolean;
  isEmptyOrMaxOneGeneratorGroupId?: boolean;
  requireGroupIdToInstancesAll?: boolean;
  classExtensionOptionRecordByDataType?: Partial<ClassExtensionOptionRecordByDataType>;
  attributeExtensionOptionRecordByKey?: AttributeExtensionOptionRecordByAttributeKey;
  useInstanceCountRestriction?: boolean;
  maxInstanceCount?: number;
  attributeShortcutKeys?: AttributeShortcutKey[];
  visualizeCoordinateAttributes?: boolean;
}

// new
export interface AnnotatedUINeedItem {
  selected: boolean;
  visible: boolean;
  ultimatelyHide: boolean;
  tempHide?: boolean;
  locked?: boolean;
  index?: number;
  selectable?: boolean;
}

export interface GroundTruthItem extends Partial<ImageAnnotatedItem>, Partial<ImageTextBoxItem> {
}

export interface SelectOption {
  eventFromOutsideAnnotator?: boolean;
  isMulti?: boolean;
  isFocus?: boolean;
  modPressed?: boolean;
  data?: any;
}

export interface AnnotatedItemEventResponse {
  type: AnnotatedItemEvent;
  data?: AnnotationEventData;
  option?: { isFocus?: boolean; isMulti?: boolean; modPressed?: boolean; data?: any; };
  labelInfo?: LabelInfo;
}

export interface AnnotationEventData {
  targetItem: AnnotatedItem;
  beforeData?: AnnotatedItem;
  updateData?: AnnotatedItem;
}

// TODO 제네릭을 적용하면 어떨지?
export interface AnnotatorEventParam {
  type: AnnotatorEventType;
  data?: AnnotatorEventParamData;
}

export interface AnnotatorEventParamData {
  answer?: AnnotationAttributeAnswer;
  beforeAnswer?: AnnotationAttributeAnswer;
  id?: string;
  ids?: string[];
  annotatedItem?: AnnotatedItem;
  annotations?: AnnotatedItem[];
  frameIndex?: number;
  type?: DataType;
  requestParam?: LoadFrameAnswerParams;
  resource?: Resource;
  error?: Error;
  imageSize?: ImageSize;
  text?: string;
}

export interface AnnotationSelectEventParam {
  annotationItem: AnnotatedItem;
  isSelected: boolean;
  isMulti?: boolean;
}

export interface AnnotationLabelEventParam {
  type: AnnotationLabelEventType;
  visibility: boolean;
  annotationItem: AnnotatedItem;
}

export enum AnnotationLabelEventType {
  class = 'class',
  attribute = 'attribute',
  keyPointName = 'keyPointName',
  keyPointOrder = 'keyPointOrder'
}

export enum InvalidPixelValidationType {
  maskedImage = 'maskedImage',
  external = 'external',
}

export interface InvalidPixels {
  indices: number[];
  color?: string;
  borderColor?: string;
}

export interface ValidationByMaskedImage {
  type: InvalidPixelValidationType.maskedImage;
  maskedImage: Blob;
}

export interface ValidationByExternal {
  type: InvalidPixelValidationType.external;
  emptyPixels: InvalidPixels;
  overlapPixels?: InvalidPixels;
  error?: any;
}

export type InvalidPixelValidation = ValidationByMaskedImage | ValidationByExternal;

export interface AnnotatorStore {
  groundTruthItems?: GroundTruthItem[];
  backgroundItems?: BackgroundItem[];
  emptyAlphaValue?: number;
  invalidPixelValidation?: InvalidPixelValidation;

  fileName?: string;
  defaultChoiceByType?: ChoiceByAnnotationType;
  minZoom?: number;
  maxZoom?: number;
  instanceOpacity?: number;
  disableTab?: boolean;
  visibleBackgroundItems?: boolean;
  isInstanceColorTypeByGroup?: boolean;
  stageContext?: StageContext;
  maxContrast?: boolean;
}

export interface ItemVisibleStatus {
  visibleBackgroundItems?: boolean;
  visibleGroundTruthItems?: boolean;
}

export type ActionModeEnableConfigs = {
  [key in ActionModes]: boolean;
};

export interface AnnotatedBaseItem extends AnnotatedUINeedItem {
  id: string;
  type: DataType;
  label: string; // deprecated
  attributes?: AnnotationAttributeAnswer;
  geometryInfo?: GeometryInfo;

  trackId?: string; // 이거 사용
  color?: string;
  groupId?: string;
  updated?: boolean;
  opacity?: number;
}

export type AnnotatedAnswerItem = AnnotatedBaseItem;

export interface AnnotatedItem extends Partial<ImageAnnotatedItem>,
  Partial<BrushItem>,
  Partial<ImageTextBoxItem>,
  Partial<ImageOBBoxV2Item>,
  Partial<ImageTokenizerItem>,
  Partial<KeypointAnnotatedItem>,
  Partial<VideoItem>,
  Partial<TextItem>,
  Partial<PointCloudItem>,
  Partial<PointCloudLinePointItem>,
  Partial<TextV2Item>,
  Partial<LotteQaItem>,
  Partial<ImagePolylinePointItem> {
  groupId?: string;
  pairIds?: string[];
  triggerOpenEdit?: boolean;
  reworkRequested?: boolean;
  isAiAssisted?: boolean;

  displayLabel?: string;
  displayLabelOptions?: DisplayLabelOption[];
  fontSize?: number;

  score?: number;

  pointLabels?: PointLabel[];
  colorById?: string;
  isTemp?: boolean;
  isApproximateProjection?: boolean; // 센서퓨전된 큐보이드 강제 투영 여부
}

export interface PointCloudTemplateOption extends TemplateOption {
  renderOption: RenderOption;
  sizeCandidates: SizeCandidate[];
  hasAIAssist: boolean;
  useCuboid3d?: boolean;
  usePolygon3d?: boolean;
  usePolyline3d?: boolean;
  useLasso?: boolean;
  usePointsCheck?: boolean;
  useIntensityColorRange?: boolean;
  defaultMode: ModeType;
  enableConvexHul: boolean;
  pointCloudChannel: PointCloudChannel;
  useParentingGroup?: boolean;
  minGroupBySelectCount: number;
  useUniqueGroupId?: boolean;
}

export interface VideoFrameRangeTemplateOption extends TemplateOption {
  firstFrameBboxInfo?: FirstFrameBboxInfo;
}

export interface SequenceTemplateOption extends ImageBaseTemplateOption {
  useExtremeBbox: boolean;
  scaleCondition: ScaleCondition;
  useCroppedTaskImage: boolean;
  useAutoInherit: boolean;
  useDotNumberText: boolean;
  useObjectTrackingAIAssist: boolean;
  imageCacheSize: number;
  thumbnailCacheSize: number;
  preloadSize: number;
  usePair?: boolean;
  hideReset?: boolean;
}

export interface BackgroundItem {
  color: string;
  points: Coordinate[];
  opacity: number;
  strokeWidth: number;
  type: DataType;
  strokeOpacity?: number;
  strokeColor?: string;
}

export interface BackgroundItemInState {
  color: string;
  points: Coordinate[];
  opacity: number;
  strokeWidth: number;
  type: ItemType;
  strokeOpacity?: number;
  strokeColor?: string;
  selected?: boolean;
  displayLabelOptions?: DisplayLabelOption[];
  keyPoints?: KeyPoint[];
  pointLabels?: PointLabel[];
  trackId?: string;
}

export interface VisualizedItemInState {
  type: ItemType;
  visible: boolean;
  points: Coordinate[];
}

export interface MainViewActionParam {
  viewerActionType?: ViewerActionType;
  phase?: ViewerActionPhase;
}

export interface MainViewAction {
  type: MainViewActionType;
  data: MainViewActionParam;
}

export interface ViewerFileDetail {
  url: string;
  annotations: StudioAnnotation[];
  attributes?: AnnotationAttributeAnswer;
  id?: string;
  photoContexts?: PhotoContext[];
}

export interface PolygonAddRemoveParams {
  targetInstanceId: string;
  boolOpInstanceId: string;
  points: Coordinate[];
  customSvgImageInfo: CustomSvgImageInfo;
}

export interface InstanceMergeParam {
  mergeInstanceId: string;
  mergedInstanceId: string;
  points: Coordinate[];
}

export interface UpdatedItemsParam {
  items: Partial<StudioAnnotation>[];
  onlySync?: boolean;
  attributesUpdate?: boolean;
}

export interface SelectedItemParam {
  selectedIds: string[];
  multi: boolean;
}

export interface SelectedPointParam {
  id: string;
  selectedPointId?: string | null;
}

export interface ContextMenuCenterTextParam {
  id: string;
  index: number;
  value: string;
  valueType: SourceType;
}

export interface InstanceSplitParam {
  id: string;
  firstCoordinates: Coordinate[];
  secondCoordinates: Coordinate[];
}

export type PointCountParam = Pick<StudioAnnotation, 'id' | 'pointCount'>;

export type InstanceLabelState = {
  [key in AnnotationLabelEventType]: boolean;
};

export interface BaseAnnotation {
  attributes: AnnotationAttributeAnswer;
  groupId: string;
  id: string;
  trackId: string;
  type: AnnotationType;

  isAiAssisted?: boolean;
  score?: number;
}

export interface CuboidAnnotation extends BaseAnnotation {
  geometry: PointCloudGeometry;
  position: PointCloudPosition;
  rotation: PointCloudRotation;
  pointCount: number;
}

export interface Cuboid2DAnnotation extends BaseAnnotation {
  points: Coordinate[];
  linePoints: Coordinate[][];
}

export interface Cuboid2DAnnotationForSensorFusion extends Cuboid2DAnnotation {
  frontOrder: boolean;
}

export interface LassoAnnotation extends BaseAnnotation {
  indices: string;
  numIndices: number[];
  uniqPointsCount: number;
  originIndices: string;
}

export interface PolygonAnnotation extends BaseAnnotation {
  points: Coordinate[] | PointCloudPosition[];
}

export interface MultiClassificationAnnotation extends BaseAnnotation {
  multiAttributes: AnnotationAttributeAnswer[];
}

export interface PolylineAnnotation extends BaseAnnotation {
  points: Coordinate[] | PointCloudPosition[];
}

export interface PairAnnotation extends BaseAnnotation {
  pairIds: string[];
}

type LotteQARowAnnotation = BaseAnnotation;
type PolygonSegmentationAnnotation = PolygonAnnotation;
type BboxAnnotation = PolygonAnnotation;

export interface ObboxAnnotation extends BaseAnnotation {
  points: Coordinate[];
  degree: number;
}

export interface ObboxV2Annotation extends BaseAnnotation {
  rect: Rect;
  degree: number;
}

export interface BitmapAnnotation extends BaseAnnotation {
  dataUrl: string;
  originPoint: number[];
}

export interface SegmentationMaskAnnotation extends BaseAnnotation {
  mask: string;
  bound: BoundCoordinates;
}

export interface FrameRangeAnnotation extends BaseAnnotation {
  startFrame: number;
  endFrame: number;
}

export interface ImageAnnotation extends BaseAnnotation {
  points: Coordinate[];
}

export interface KeypointAnnotation extends BaseAnnotation {
  keypoints: KeyPoint;
  invisibleKeys: string[];
}

export interface EntityAnnotation extends BaseAnnotation {
  end: number;
  start: number;
  text: string;
}

export interface PolylinePointAnnotation extends BaseAnnotation {
  pointAnnotations?: PointAnnotation[];
}

export interface PointAnnotation extends StudioAnnotationForUI {
  id: string;
  point: Coordinate;
  attributes?: AnnotationAttributeAnswer;
}

export interface PolylinePoint3dAnnotation extends BaseAnnotation {
  point3dAnnotations?: Point3dAnnotation[];
  geometry?: PointCloudGeometry;
  selectedPointIds?: string[];
}

export interface Polygon3dAnnotation extends BaseAnnotation {
  point3dAnnotations?: Point3dAnnotation[];
  geometry?: PointCloudGeometry;
}

export interface Point3dAnnotation extends StudioAnnotationForUI {
  id: string;
  position: PointCloudPosition;
  attributes?: AnnotationAttributeAnswer;
}

export type StudioAnnotation = BboxAnnotation
  & ObboxAnnotation
  & ObboxV2Annotation
  & PolygonAnnotation
  & PolygonSegmentationAnnotation
  & PolylineAnnotation
  & PolylinePointAnnotation
  & PolylinePoint3dAnnotation
  & Polygon3dAnnotation
  & KeypointAnnotation
  & CuboidAnnotation
  & Cuboid2DAnnotationForSensorFusion
  & BitmapAnnotation
  & PairAnnotation
  & FrameRangeAnnotation
  & EntityAnnotation
  & LotteQARowAnnotation
  & BaseAnnotation
  & ImageAnnotation
  & SegmentationMaskAnnotation
  & LassoAnnotation
  & MultiClassificationAnnotation
  & StudioAnnotationForUI;

interface StudioAnnotationForUI {
  selected: boolean;
  locked: boolean;
  visible: boolean;
  color: string;
  index: number;

  label?: string;
  displayLabel?: string;
  displayLabelOptions: DisplayLabelOption[];
  fontSize?: number;
  invalidMessages?: InvalidMessage[];
  externalInvalidMessages?: InvalidMessage[];
  warningMessages?: InvalidMessage[];
  tempHide?: boolean;
  triggerOpenEdit?: boolean;
  src?: string;
  geometryInfo?: GeometryInfo;
  pointCount?: number;
  distance?: number;

  interpolated?: boolean; // 보쉬 스펙 interpolation 으로 생성되었는지 여부.

  opacity?: number;
  strokeWidth?: number;

  inherited?: boolean;
  offset?: PointCloudPosition;
  positions?: PointCloudPosition[];
  beforeFrameNumber?: number;

  selectable?: boolean;
  pointLabels?: PointLabel[];
  colorById?: string;
  isTemp?: boolean;

  // 우선 포인트클라우드툴에서만 사용 추후 이미지툴 확장
  creatable?: boolean;
  movable?: boolean;
  resizable?: boolean;
  removable?: boolean;

  isApproximateProjection?: boolean; // 센서퓨전된 큐보이드 강제 투영 여부
}

export interface InstanceVisibleEmitParams {
  id: string;
  visible: boolean;
}

export interface InstanceLockEmitParams {
  id: string;
  locked: boolean;
}

export interface InstanceEditedEmitParams {
  id: string;
  isMulti: boolean;
}

export interface InstanceRemovedEmitParams {
  ids: string[];
  frameSelectType?: FrameSelectType;
}

export interface InstanceTrackIdEditEmitParams {
  id: string;
  frameSelectType: FrameSelectType;
}

export interface InstanceAttributeEditEmitParams {
  id: string;
  frameSelectType: FrameSelectType;
  attribute: MappedAnswer;
}

export interface InstanceChangeOrderEmitParams {
  id: string;
  targetEl: HTMLElement;
}

export type TrackIdClassMap = Map<number, string>;
