import { LotteQaEmotion, LotteQaEntitiesColumnField, LotteQaQA, LotteQaWorkable } from './lotte-qa-enum';

export const INTENTS_DATASET = {
  ['AS']: {
    ['시간']: ['질문', '요청', '비교', '확인'],
    ['비용']: ['질문', '요청', '비교', '확인'],
    ['방법']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인']
  },
  ['결제']: {
    ['방식']: ['질문', '요청', '비교', '확인'],
    ['수단']: ['질문', '요청', '비교', '확인'],
    ['시기']: ['질문', '요청', '비교', '확인'],
    ['영수증']: ['질문', '요청', '비교', '확인'],
    ['오류']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인'],
    ['재결제']: ['질문', '요청', '비교', '확인'],
    ['추가']: ['질문', '요청', '비교', '확인'],
    ['취소']: ['질문', '요청', '비교', '확인'],
    ['할인']: ['질문', '요청', '비교', '확인']
  },
  ['교환/반품/환불']: {
    ['비용']: ['질문', '요청', '비교', '확인'],
    ['시간']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인'],
    ['방법']: ['질문', '요청', '비교', '확인']
  },
  ['멤버십']: {
    ['가입']: ['질문', '요청', '비교', '확인'],
    ['사용']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인'],
    ['적립']: ['질문', '요청', '비교', '확인'],
    ['오류']: ['질문', '요청', '비교', '확인']
  },
  ['배송']: {
    ['방법']: ['질문', '요청', '비교', '확인'],
    ['비용']: ['질문', '요청', '비교', '확인'],
    ['날짜']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인'],
    ['지역']: ['질문', '요청', '비교', '확인'],
    ['택배사']: ['질문', '요청', '비교', '확인'],
    ['오류']: ['질문', '요청', '비교', '확인']
  },
  ['제품']: {
    ['가격']: ['질문', '요청', '비교', '확인'],
    ['구성']: ['질문', '요청', '비교', '확인'],
    ['재고']: ['질문', '요청', '비교', '확인'],
    ['방법']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인'],
    ['입고']: ['질문', '요청', '비교', '확인'],
    ['정보']: ['질문', '요청', '비교', '확인'],
    ['소재']: ['질문', '요청', '비교', '확인'],
    ['추천']: ['질문', '요청', '비교', '확인'],
    ['원산지']: ['질문', '요청', '비교', '확인'],
    ['품질']: ['질문', '요청', '비교', '확인'],
    ['호환']: ['질문', '요청', '비교', '확인'],
    ['날짜']: ['질문', '요청', '비교', '확인'],
    ['불량']: ['질문', '요청', '비교', '확인'],
    ['용도']: ['질문', '요청', '비교', '확인']
  },
  ['포장']: {
    ['방식']: ['질문', '요청', '비교', '확인'],
    ['비용']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인']
  },
  ['행사']: {
    ['기간']: ['질문', '요청', '비교', '확인'],
    ['유형']: ['질문', '요청', '비교', '확인'],
    ['일반']: ['질문', '요청', '비교', '확인']
  },
  ['웹사이트']: {
    ['가입']: ['질문', '요청', '비교', '확인'],
    ['사용']: ['질문', '요청', '비교', '확인'],
    ['오류']: ['질문', '요청', '비교', '확인']
  }
};

export const DEFAULT_QA_NUMBER = 1;

export const WORKABLE_CANDIDATES = [LotteQaWorkable.yes, LotteQaWorkable.no];
export const QA_CANDIDATES = [LotteQaQA.question, LotteQaQA.answer];
export const EMOTION_CANDIDATES = [LotteQaEmotion.moderate, LotteQaEmotion.negative, LotteQaEmotion.positive];

export const ENTITIES_COLUMNS = [
  LotteQaEntitiesColumnField.prices,
  LotteQaEntitiesColumnField.quantities,
  LotteQaEntitiesColumnField.sizes,
  LotteQaEntitiesColumnField.times,
  LotteQaEntitiesColumnField.dates,
  LotteQaEntitiesColumnField.goods,
  LotteQaEntitiesColumnField.places,
  LotteQaEntitiesColumnField.organizations,
  LotteQaEntitiesColumnField.persons
];

export const WORKABLE_SHORTCUTS = {
  [LotteQaWorkable.yes]: ['1', 'y', 'ㅛ'],
  [LotteQaWorkable.no]: ['2', 'n', 'ㅜ']
};
export const QA_SHORTCUTS = {
  [LotteQaQA.question]: ['1', 'q', 'ㅂ'],
  [LotteQaQA.answer]: ['2', 'a', 'ㅁ']
};
export const EMOTION_SHORTCUTS = {
  [LotteQaEmotion.moderate]: ['1', 'm', 'ㅡ'],
  [LotteQaEmotion.negative]: ['2', 'n', 'ㅜ'],
  [LotteQaEmotion.positive]: ['3', 'p', 'ㅔ']
};

export const agGridStyleBundleName = 'ag-grid-style.css';

export const HYPHEN_MINUS = '\u002d';
export const NUMPAD_MINUS = '\u2212';
